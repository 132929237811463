@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/colors.scss';
@import 'patientOnboarding/theme/responsive.scss';

.brief {
  @include screen('tablet-md'){
    margin-left: $spacing-09;
   }

   @include screen('tablet-lg'){
    margin-left: 0px;
   }

   @include screen('desktop-sm'){
    margin-left: 0px;
   }

   @include screen('desktop-lg'){
    margin-left: 0px;
   }

  .distance {
    display: none;
  }

  .arrowLeftLine {
    display: none;
  }
}

.resultDetails {
  display: flex;
  width: 100%;
  flex-shrink: 0;

  @include screen('tablet-md') {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.blueArrowRight {
  display: none;

  @include screen('tablet-sm') {
    display: inline-block;
    top: $spacing-01;
    position: relative;
    cursor: pointer;
    margin-left: calc(#{$spacing-01} + #{$spacing-03});
  }
}

.arrowRight {
  cursor: pointer;
  margin-left: $spacing-03;

  @include screen('tablet-sm') {
    display: none;
  }
}

.resultThumbnail {
  border-radius: $spacing-03;
  margin-right: $spacing-03;
  width: calc(#{$spacing-10} + #{$spacing-08});
  height: calc(#{$spacing-10} + #{$spacing-08});
  object-fit: cover;

  @include screen('tablet-sm') {
    min-width: calc(#{$spacing-13} - #{$spacing-05});
    height: calc(#{$spacing-13} - #{$spacing-05});
  }

  @include screen('tablet-md') {
    min-width: 189px;
    height: 189px;
    margin-right: $spacing-07;
  }
}

.thumbnailAdmin {
  width: calc(#{$spacing-11} + #{$spacing-06});
  height: calc(#{$spacing-11} + #{$spacing-06});

  @include screen('tablet-md') {
    min-width: calc(#{$spacing-13} + #{$spacing-06} + #{$spacing-01});
    height: calc(#{$spacing-13} + #{$spacing-06} + #{$spacing-01});
    margin-right: $spacing-05;
  }
}

.resultInfo {
  width: 100%;
  justify-content: space-between;

  a {
    text-decoration: none;
  }

  @include screen ('tablet-sm') {
    display: flex;
  }
}

.obieResultBasic {
  @include screen ('tablet-lg') {
    width: 60%;
  }
  
  @include screen ('desktop-sm') {
    width: 70%;
  }
}

.abieResultBasic {  
  @include screen ('desktop-sm') {
    width: 70%;
  }
  
  @include screen ('desktop-lg') {
    width: unset;
  }
}

.resultTitle {
  @include body-s-medium;
  color: $black;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include screen('tablet-sm') {
    @include title-l-semibold;
    margin-bottom: $spacing-03;
    display: block;
  }
}

.resultTitleAdmin {
  margin-bottom: $spacing-04;
}

.mobileDistanceLabel {
  @include body-xs-regular;
  margin: $spacing-03 0;
  color: $gray-500;

  @include screen('tablet-lg') {
    display: none;
  }
}

.expertisesWrapper {
  display: flex;
  flex-flow: row wrap;
  max-width: 750px;
  padding-bottom: $spacing-06; // Relationship with noMarginWrapper for no focus area selected
}

.expertisesWrapperAdmin {
  margin-bottom: calc(#{$spacing-02} + #{$spacing-01});
  padding-bottom: 0px; //ABIE does not need this extra padding
}

.noMarginWrapper {
  padding-bottom: 0px; // This is related to .expertisesWrapper padding property
}

.noMarginWrapperAdmin {
  // When no expertises are showing the extra padding is needed.
  margin-bottom: $spacing-05;
}

.pillboxContainer {
  display: none;
  margin-bottom: $spacing-03;
  margin-right: $spacing-03;
  @include screen('tablet-sm') {
    display: block;
  }
}

.expertisesWrapperMobile {
  display: flex;
  flex-flow: row wrap;
  max-width: $spacing-22;
  margin-bottom: $spacing-06;
  margin-top: $spacing-06;
  @include screen('tablet-sm') {
    display: none;
  }
}

.pillboxContainerMobile {
  margin-bottom: $spacing-03;
  margin-right: $spacing-03;
  @include screen('tablet-sm') {
    display: none;
  }
}

.resultDescription {
  @include body-s-regular;
  color: $black;

  @include screen('tablet-sm') {
    margin-bottom: $spacing-06;
  }
}

.truncateOverflow {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.resultAvailability {
  display: none;
  margin-top: 0px;
  width: 90%;

  @include screen('tablet-lg') {
    display: block;
    @include body-s-medium;
    color: $black;
    margin-bottom: $spacing-03;
    margin-top: 54px;
  }
}

.resultAvaibilityExpertise {
  @include screen('tablet-lg'){
    margin-top: 0px;
  }
}

.noAvailability {
  background: #f7f7f7;
  border-radius: $spacing-02;
  width: 100%;
  text-align: center;
  padding: $spacing-03;
  color: #333;
  @include body-xs-regular;
  margin-top: $spacing-03;
  min-height: $spacing-09;
  box-sizing: border-box;

  &.desktop {
    display: none;
    @include screen('tablet-md') {
      display: block;
    }
  }

  @include screen('tablet-sm') {
    width: calc(#{$spacing-06} + #{$spacing-22});
  }
}

.resultExtra {
  display: none;

  a {
    text-decoration: none;
  }

  @include screen('tablet-lg') {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.resultExtraAdmin {
  padding: 0px;
  display: block;

  @include screen('tablet-md') {
    position: relative;
  }

  @include screen('desktop-sm') {
    display: flex;
    justify-content: normal;
    padding-right: $spacing-03;
  }
}

.officeDetailsAdmin {
  display: flex;
  padding-bottom: $spacing-03;
}

.resultAddress {
  display: none;

  @include screen('tablet-md') {
    display: block;
    @include body-s-regular;
    color: $gray-400;
    width: calc((2 * #{$spacing-12}) + #{$spacing-02});
    text-align: right;
    box-sizing: border-box;
    height: calc(#{$spacing-06 * 5.5});
  }
}

.resultAddressAdmin {
  display: none;

  @include screen('desktop-sm') {
    display: block;
  }
}

.resultLink {
  @include body-s-medium;
  color: $azure-700;
  display: none;
  cursor: pointer;

  @include screen('tablet-lg') {
    display: grid;
  }

  .arrowLeftLine {
    display: none;

    &:hover{
      fill:$azure-900;
    }

    path {
      fill: $azure-700;
    }
    @include screen('desktop-sm') {
      display: inline-block;
      vertical-align: middle;
      margin-left: $spacing-06;
    }
  }
}

.resultLinkMobile {
  @include body-s-medium;
  color: $azure-700;
  margin-top: $spacing-05;
  visibility: hidden;
  display: none;
  cursor: pointer;
  
  @include screen('tablet-md') {
    display: block;
    visibility: visible;
  }
  
  @include screen('tablet-lg') {
    display: none;
    visibility: hidden;
  }
}

.tag {
  @include body-xxs-bold;
  color: $regal-800;
  display: inline-block;
  margin-bottom: $spacing-02;
  width: fit-content;
}

.tagAdmin {
  color: $gray-500;
  margin-bottom: $spacing-03;
}

.resultAvaibilityButtons {
  display: flex;

  @include screen('tablet-md'){
  margin-top: $spacing-06;
  }
  @include screen('tablet-lg'){
    margin-top: 0px;
    flex-wrap: wrap;
  }

  .buttons {
    display: none;
  
    @include screen('tablet-md') {
      display: flex;
      flex-direction: row;
      gap: $spacing-03;
    }
  
    @include screen('tablet-lg') {
      div:last-child {
        display: none;
      }
    }
  
    @include screen('desktop-sm') {
      div:last-child {
        display: block;
      }
    }
  }
}

.resultAvailabilityButtonsAdmin {
  display: none;
  
  @include screen('tablet-md') {  
    display: flex;
  }

}

  .buttons {
    display: none;
  
    @include screen('tablet-md') {
      display: flex;
      flex-direction: row;
      gap: $spacing-03;
      
      div:last-child {
        display: none;
      }
    }
  
    @include screen('tablet-lg') {
      div:last-child {
        display: none;
      }
    }
  
    @include screen('desktop-sm') {
      div:last-child {
        display: block;
      }
    }
  }


.buttonsAdmin {
  display: none;
  
  @include screen('tablet-md') {
    display: flex;
    flex-direction: row;
    gap: $spacing-03;

    // & > div {
    //   width: calc(#{$spacing-14} - #{$spacing-02});
    // }

    & > div:last-of-type {
      display: none;
    }
  }

  @include screen('desktop-sm') {
    & > div:last-of-type {
      display: block;
    }
  }
}

.availabilityMobile {
  display: flex;
  grid-gap: $spacing-03;
  gap: $spacing-04;
  flex-direction: column;

  div:nth-child(3) {
    display: none;
  }

  @include screen('tablet-sm') {
    display: flex;
    grid-gap: $spacing-03;
    gap: $spacing-04;
    margin-top: $spacing-06;
    flex-direction: row;

    div:last-child {
      display: none;
    }
  }

  @include screen('tablet-md') {
    display: none;
  }
}

.availabilityMobileAdmin {
  @include screen('tablet-sm') {
    display: flex;
    grid-gap: $spacing-03;
    gap: $spacing-04;
    margin-top: unset;
  }
  @include screen('tablet-md') {
    display: none;
  }
}

.clickableInfo {
  cursor: pointer;
}

.multipleOfficesPill {
  display: inline-block;
  margin-top: $spacing-04;
}

.multipleOfficesPillAdmin {
  display: none;

  @include screen('desktop-sm') {
    display: inline-flex;
    margin-right: $spacing-03;
  }
}

.topSearch {
  margin-bottom: 0px
}
