@import "lifestance-ui/theme/typography.scss";
@import "lifestance-ui/theme/spacing.scss";
@import "lifestance-ui/theme/colors.scss";

.button {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: $spacing-05 $spacing-06 $spacing-03;
  border: 1px solid $gray-200;
  margin: $spacing-03 0 $spacing-01;
  border-radius: $spacing-03;
  cursor: pointer;
}

.selected {
  border: 2px solid $azure-700;
  margin-bottom: 0;
}

label {
  @include font-styrene;
  margin-left: $spacing-04;
  margin-bottom: $spacing-04;
  margin-top: $spacing-01;
}

.icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: 1px solid $gray-300;
  background-color: #fff;
}

.icon-selected {
  color: 1px solid $azure-700;
  background-color: $azure-700;
}
