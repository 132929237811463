@import 'lifestance-ui/theme/typography.scss';
@import 'lifestance-ui/theme/spacing.scss';
@import 'lifestance-ui/theme/colors.scss';
@import 'lifestance-ui/theme/responsive.scss';


.slideCardContainer {
	box-sizing: border-box;
	@include body-s-regular;
	border-bottom: 1px solid $gray-200;
	

	.header {
		display: flex;
		padding: $spacing-06 0px;
		align-items: center;
		cursor: pointer;
		transition: padding 0.3s ease-in-out;

		.title {
			@include body-s-medium;
			padding-right: $spacing-04;
			color: $gray-800;
			width: calc(100% - #{$spacing-08} - #{$spacing-01});
		}

		svg {
			margin-right: $spacing-03;
		}

		// &:hover {
		// 	padding-left: $spacing-03;
		// }

		&.active {
			padding-bottom: $spacing-04;
		}
	}

	.content {
		overflow: hidden;
		box-sizing: border-box;
		transition: height 0.5s ease;
		-moz-transition: height 0.5s ease;
		-ms-transition: height 0.5s ease;
		-o-transition: height 0.5s ease;
		-webkit-transition: height 0.5s ease;
	}

	&:last-of-type {
		border-bottom: none;
	}
}
