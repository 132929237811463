@import "patientOnboarding/theme/typography.scss";
@import "patientOnboarding/theme/colors.scss";

.phone {
	@include body-s-medium;
	color: $azure-700;
}

.contactNumber {
	@include body-s-medium;
	color: $gray-800;
}