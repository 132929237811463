@import 'intakeOptimization/theme/spacing.scss';
@import 'intakeOptimization/theme/typography.scss';
@import 'intakeOptimization/theme/colors.scss';
@import 'intakeOptimization/theme/responsive.scss';

.licenseKey {
	@include body-xxs-bold;
	display: inline-flex;
	padding: $spacing-02 $spacing-04;
	justify-content: center;
	background-color: $regal-900;
	border-radius: 100px;
	align-items: center;
	color: $white;
	box-sizing: border-box;
	min-width: calc(#{$spacing-11} + #{$spacing-05});
	position: absolute;
	top: 0px;
	right: 0px;

	@include screen('tablet-md') {
		position: absolute;
		top: 8px;
        right: 150px;
	}

	@include screen('tablet-lg') {
		position: relative;
		top: 0px;
		right: 0px;
	}
}
