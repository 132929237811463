@import 'lifestance-ui/theme/typography.scss';
@import 'lifestance-ui/theme/spacing.scss';
@import 'lifestance-ui/theme/colors.scss';

.overlay{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: $modal-overlay;
    z-index: 12;
    display: flex;
    justify-content: center;
    align-items: center;
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;

    .modalContainer{
        position: fixed;
        background: #FFFFFF;
        box-shadow: 0px $spacing-02 $spacing-04 rgb(0 0 0 / 10%);
        box-sizing: border-box;
        padding-bottom: $spacing-11;
        bottom: 0px;
        left: 0px;
        width: 100%;
        border-radius: $spacing-06 $spacing-06 0 0;
        transition: bottom 0.2s ease;
        z-index: 9;
        touch-action: none;
        -webkit-overflow-scrolling: none;
        overflow: hidden;

        @include screen('tablet-md'){
            transition: none;
            position: relative;
            // 550px fixed width
            width: calc(#{$spacing-06}*27.5);
            background-color: $white;
            border-radius: $spacing-03;
        }

        .topContainer{
			border-radius: $spacing-06 $spacing-06 0 0;
            padding: $spacing-07;
            display: flex;
            box-sizing: border-box;
            align-items: center;
			border-bottom: 1px solid $gray-200;
			height: calc((#{$spacing-07} * 3) + #{$spacing-01});

            .header{
                @include body-s-regular;
                text-align: center;
				width: 100%;
				margin-right: $spacing-07;
            }

            .cursorPointer {
                cursor: pointer;
            }

			@include screen('tablet-md') {
				border-bottom: none;
				justify-content: flex-end;
				padding-bottom: $spacing-05;

				.header {
					display: none;
				}
			}
        }

		.modalContent{
			margin-top: $spacing-08;
			padding-left: $spacing-07;
			padding-right: $spacing-07;
	
			@include screen('tablet-md'){
				margin-top: 0;
				padding: 0 $spacing-11;
			}
		}
    }
}
