@import 'lifestance-ui/theme/typography';
@import 'lifestance-ui/theme/spacing';
@import 'lifestance-ui/theme/colors.scss';

.rectangleBtn {
    background: #FFFFFF !important;
    border: 1px solid $gray-300 !important;
    box-sizing: border-box !important;
    border-radius: $spacing-03 !important;
    color: $black !important;
    justify-content: flex-start !important;
    cursor: pointer !important;
    margin: $spacing-04 auto $spacing-04 auto !important;
    padding-left: $spacing-01 !important;
    padding-right: 6px !important;
    height: 52px !important;
    @include body-s-regular;
    width: $spacing-21;


    &:hover {
        transform: none !important;
    }

    &:focus-visible {
        border: 1px solid $gray-500 !important;
        outline: $spacing-01 solid $gray-800;
        outline-offset: $spacing-01;
    }

    @include screen('tablet-sm') {
        border-radius: $spacing-03;
        padding: $spacing-01;
        width: $spacing-21 !important;
    }

    @include screen('tablet-md'){
        border-radius: $spacing-03;
        padding: $spacing-01;
        width: 643px !important;
    }

    @include screen('tablet-lg'){
        border-radius: $spacing-03;
        padding: $spacing-01;
        width: 540px !important;
    }

    @include screen('desktop-sm'){
        border-radius: $spacing-03;
        padding: $spacing-01;
        width: 487px !important;
    }

    @include screen('desktop-lg'){
        border-radius: $spacing-03;
        padding: $spacing-01;
        width: 346px !important;
    }

    &.error {
        border-color: $sienna-500;
    }

    &:disabled {
        cursor: default;
        background-color: #eeeeee !important;
        color: $gray-300 !important;
        border-color: rgba(118, 118, 118, 0.3);

        div: {
            color: #acacac !important;
        }
    }

    &::selection {
        background-color: #F6FCFF !important;
    }

}

.active {
    border: $spacing-01 solid $azure-700 !important;
    background-color: $regal-0 !important;

    &:focus-visible {
        border: $spacing-01 solid $azure-700 !important;
    }

    .icon {
        color: white;
        background-color: $azure-700 !important;
        border: 1px solid $azure-700 !important;
    }
}

.dRowFlex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.label {
    top: calc(50% - $spacing-07/2 - 1368px);
    @include body-s-regular;
    color: $gray-700;
    padding: $spacing-04;
}

.icon {
    width: $spacing-08;
    height: $spacing-08;
    left: 410px;
    top: 283px;
    @include body-regular-bold;
    color: $gray-800;
    background: transparent;
    border: 1.4px solid $gray-300;
    box-sizing: border-box;
    border-radius: $spacing-02;
    margin: $spacing-03 0 $spacing-03 $spacing-03;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconDisabled {
    background: $gray-200;
    border: 1.4px solid rgba(118, 118, 118, 0.3);
    color: $gray-400;
}

.emptyIcon {
    width: 29px;
    height: $spacing-08;
    left: 410px;
    top: 283px;

    margin: $spacing-02;
    display: flex;
    justify-content: center;
    align-items: center;
}

span.error {
    @include body-xs-regular;
    color: $sienna-500;
    margin-top: $spacing-03;
    display: block;
}
