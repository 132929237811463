@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/colors.scss';
@import 'patientOnboarding/theme/responsive.scss';

.faqContainer {

	.phone {
		color: $azure-700;
		text-decoration: none;
		@include body-s-medium;

		&:hover {
			color: $azure-900;
		}
	}

	.insuranceList {
		padding-bottom: $spacing-06;
		padding-top: $spacing-06;

		.area {
			margin-bottom: $spacing-02;
			display: flex;
			align-items: flex-start;
			width: 100%;

			.vector {
				display: inline-block;
				margin-left: 0px;
				/* 5px */
				padding-top: calc(#{$spacing-02} + 0.063rem);
			}
	
			div {
				display: inline-block;
				@include body-s-regular;
				margin-left: $spacing-05;
			}
		}
	}

	.languageList {
		padding-top: $spacing-06;

		.area {
			margin-bottom: $spacing-02;
			display: flex;
			align-items: flex-start;
			width: 100%;

			.vector {
				display: inline-block;
				margin-left: 0px;
				/* 5px */
				padding-top: calc(#{$spacing-02} + 0.063rem);
			}
	
			div {
				display: inline-block;
				@include body-s-regular;
				margin-left: $spacing-05;
			}
		}
	}

	.directions {
		margin-top: $spacing-03;
	
		.link{
			color: $azure-700;
			@include body-s-medium;
			text-decoration: none;
	
			svg{
				width: $spacing-04;
				height: $spacing-04;
				vertical-align: middle;
				margin-left: $spacing-02;
				cursor: pointer;
				transform: rotate(135deg);
			}
		}
	}

}