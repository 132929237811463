@import 'lifestance-ui/theme/spacing.scss';
@import 'lifestance-ui/theme/colors.scss';
@import 'lifestance-ui/theme/responsive.scss';

.notLoading {
  display: flex;
  position: relative;
  align-items: center;
  width: 350px;

  .puzzleLeft {
    position: absolute;
    left: 20px;
    transition: all 1s ease-in;
    transform: rotate(-0.9deg);
  }

  .puzzleRight {
    position: absolute;
    left: 128px;
    transition: all 1s ease-in;
  }
}

.loading {
  display: flex;
  position: relative;
  align-items: center;
  width: 350px;

  .puzzleLeft {
    position: absolute;
    left: 10px;
    transform: rotate(-2deg);
    transition: all 1s ease-out;
  }

  .puzzleRight {
    position: absolute;
    left: 138px;
    transform: rotate(4.62deg);
    transition: all 1s ease-out;
  }
}