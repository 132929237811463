@import 'intakeOptimization/theme/spacing.scss';
@import 'intakeOptimization/theme/typography.scss';
@import 'intakeOptimization/theme/colors.scss';


.select {
  @include body-s-regular;
  width: calc(#{$spacing-02} + #{$spacing-08});
  height: calc(#{$spacing-02} + #{$spacing-08});
  background-color: $white;
  color: $gray-700;
  appearance: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  border-radius: calc(#{$spacing-02} + #{$spacing-12});
  border: $spacing-01 solid $gray-300;
  padding: $spacing-03;
  box-sizing: border-box;

  &.selectedOptions {
    background-color: $regal-100;
    border: $spacing-01 solid $azure-700;
  }

  &:after {
    content: '';
    border-right: 1px solid $gray-200;
    height: calc(#{$spacing-02} + #{$spacing-08});
    position: absolute;
    right: -$spacing-03;

    @include screen('tablet-sm') {
      right: -$spacing-04;
    }
  }

  @include screen('tablet-sm') {
    justify-content: space-between;
    width: auto;
    padding: $spacing-03 $spacing-05;
  }

  @include screen('tablet-md') {
    &::after {
      border-right: none;
      right: 0px;
    }
  }

  @include screen('tablet-lg') {
    display: none;
  }
}

.adminSelect {
  @include body-s-regular;
  width: $spacing-12;
  height: calc(#{$spacing-02} + #{$spacing-08});
  background-color: $white;
  color: $gray-700;
  appearance: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  border-radius: calc(#{$spacing-02} + #{$spacing-12});
  border: 1px solid $gray-300;
  padding: $spacing-03;
  box-sizing: border-box;

    .circle {
      @include body-xxs-regular;
      width: $spacing-06;
      border-radius: $spacing-12;
      background-color: $azure-700;
      box-sizing: border-box;
      padding: $spacing-01;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: $spacing-02;
    }

    svg {
      margin-left: $spacing-02;
    }

  @media (min-width: 1260px) {
    display: none;
  }

}

.labelTabletFilters {
  display: none;
  @include screen('tablet-sm') {
    display: block;
    margin-left: $spacing-03;
  }
  @include screen('desktop-sm') {
    display: none;
  }
}

.adminLableTabletFilters {
  display: block;

  @include screen('tablet-sm') {
    display: block;
  }
  @include screen('desktop-lg') {
    display: none;
  }
}

.handleClose {
  margin-top: calc(#{$spacing-01} + #{$spacing-02});
  position: absolute;
  left: $spacing-07;
  cursor: pointer;
}

.clearAll {
  color: $azure-700;
  @include body-s-medium;
  position: absolute;
  right: $spacing-07;
  cursor: pointer;
}

.filtersTitle {
  @include body-s-regular;
  color: $black;
}
.underLayer {
  display: none;
  &.open {
    display: block;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    background-color: #2626266e;
    height: 100vh;
    z-index: 9;
  }
}

.mobileFilters {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 130vh;
  background-color: $white;
  overflow-y: auto;
  transition: 0.3s ease all;
  height: 100vh;
  border-radius: $spacing-06 $spacing-06 0px 0px;

  &.open {
    top: $spacing-09;
    z-index: 10;
    height: 100%;

    .footer {
      position: fixed;
    }
  }
}

.topFilters {
  box-sizing: border-box;
  padding: $spacing-06 $spacing-08;
  height: calc(#{$spacing-02} + #{$spacing-11});
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $gray-200;
  z-index: 11;
  border-radius: $spacing-06 $spacing-06 0px 0px;
  background-color: $white;
  position: fixed;
  width: 100%;
  left: 0;
  top: 130vh;
  transition: 0.3s ease all;

  &.open {
    top: $spacing-09;
  }
}

.filters {
  padding-bottom: $spacing-13;
  padding-top: calc(#{$spacing-02} + #{$spacing-11});
  overflow: initial;
  min-height: 100vh;

  @include screen('tablet-lg') {
    height: auto;
    overflow: initial;
  }
}

.bottomFilters {
  position: fixed;
  bottom: 0px;
  height: calc(#{$spacing-02} + #{$spacing-12});
  box-sizing: border-box;
}

.footer {
  display: flex;
  padding: $spacing-06;
  margin-top: calc(#{$spacing-01} + #{$spacing-03});
  border-top: 1px solid $gray-300;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: $white;

  button {
    svg {
      circle {
        stroke: white;
      }
    }
  }
}
