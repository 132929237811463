@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/colors.scss';

.container {
  margin-bottom: $spacing-11;

  .header {
    @include title-xl-regular;
    color: $gray-800;
    text-align: center;

    @include screen('tablet-lg') {
      margin-top: 0;
    }
  }

  .subHeader {
    @include body-s-regular;
    text-align: center;
    display: block;
    color: $gray-800;
    margin-top: $spacing-04;
  }

  .content {
    margin-top: $spacing-04;

    .card {
      box-sizing: border-box;
      padding-top: $spacing-06;
      padding-bottom: $spacing-06;
      border-bottom: 1px solid $gray-100;
      color: $gray-800;
      @include body-s-regular;

      &:nth-child(2) {
        border-bottom: 0px solid !important;
        margin-bottom: calc(#{$spacing-07} + #{$spacing-02});
      }

      .field {
        @include body-s-medium;
        margin-right: $spacing-03;
      }

      svg {
        display: block;
        margin-left: auto;
      }

      .ageValid {
        @include body-xs-regular;
        margin-top: $spacing-02;
      }

      .validAgeForToc {
        @include body-xs-regular;
        margin-top: $spacing-02;
      }
    }
  }
}

.alert {
  path {
    fill: $gold-500;
  }
}

.noMatch {
  path {
    fill: $sienna-600;
  }
}

.seeMoreProvidersButton {
  margin-top: $spacing-05;
}

.footer {
    position: fixed;
    padding: 1.5rem;
    bottom: 0;
    background: #ffffff;
    width: 100%;
    box-sizing: border-box;
    transition: 0.3s ease position;
    transition-delay: 2s;
    left: 0;
    right: 0;

    @include screen('tablet-lg') {
      position: relative;
      padding: 0;
    }
}
