@import 'lifestance-ui/theme/typography';
@import 'lifestance-ui/theme/spacing';

.label {
  @include body-s-medium;
  font-style: normal;
  font-weight: 500;
  font-size: $spacing-05;
  line-height: $spacing-07;
  margin-bottom: $spacing-03;

  &.fullWidth {
    width: 100%;
  }
}
