@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/colors.scss';

.stateToastModal {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $white;
    padding-bottom: $spacing-09;
  
    @include screen('tablet-sm') {
      border-radius: $spacing-03;
    }
  
    .icon {
      width: calc($spacing-13 + $spacing-08 - $spacing-03 - $spacing-01);
      svg {
        width: calc($spacing-13 + $spacing-08 - $spacing-03 - $spacing-01);
      }

      @include screen('tablet-sm') {
          width: calc($spacing-14 + $spacing-08 + $spacing-03);

          svg {
              width: calc($spacing-14 + $spacing-08 + $spacing-03);
          }
      }
      @include screen('tablet-sm') {
        margin-bottom: $spacing-06;
      }
    }
  
    .title {
      @include body-m-medium;
      margin-top: -$spacing-04;
      margin-bottom: $spacing-03;
    }
  
    .description {
      @include body-s-regular;
      margin-top: $spacing-02;
      margin-bottom: $spacing-07;
      max-width: 400px;
      text-align: center;
      padding-left: $spacing-06;
      padding-right: $spacing-06;
      color: $gray-600;
    }
  
    .button {
      width: calc(100% - $spacing-07 * 2);
      margin-bottom: $spacing-07;
      padding-left: $spacing-07;
      padding-right: $spacing-07;
      color: $gray-800;
      cursor: pointer;
    }
  }
}