@import 'intakeOptimization/theme/typography.scss';
@import 'intakeOptimization/theme/spacing.scss';
@import 'intakeOptimization/theme/colors.scss';

.container {
  box-sizing: border-box;
  margin: $spacing-08 0;
  width: 100%;
  min-height: 113px;
  border-radius: $spacing-03;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $spacing-06;
  
  @include screen('tablet-md') {
    margin: $spacing-08 0;
    padding: 0px;
  }
  @include screen('desktop-sm') {
    margin: $spacing-08 0;
    padding: 0px;
  }
}

.alternative {
  margin: $spacing-07 0;

  h2 {
    @include title-xl-regular;
    font-weight: normal;
  }
  h3 {
    @include body-s-regular;
    color: $black;
  }
}
