@import 'lifestance-ui/theme/spacing.scss';
@import 'lifestance-ui/theme/typography.scss';
@import 'lifestance-ui/theme/colors.scss';

.appointmentSelector {
  @include screen("tablet-md") {
    margin-top: 6px;
  }

  .chosenDate {
    @include body-s-medium;
    margin: $spacing-09 0 $spacing-05;
    @include screen("tablet-md") {
      margin-top: 0;
    }
  }

  .appointments {
    overflow-y: none;
    background-color: white;
    padding: 0px;
    border: none;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
      width: 0px;
    }

    &:focus-visible {
      outline: $spacing-01 solid $black;
      border: 1px solid $gray-500;
      outline-offset: $spacing-02;
      border-radius: $spacing-02;
    }

    @include screen('desktop-sm') {
      padding-bottom: $spacing-05;
      height: $spacing-17;
      overflow-y: scroll;
      border-radius: $spacing-03;
      background-color: $gray-0;
      padding: $spacing-05;
      border: 1px solid $gray-100;
    }

    .wordWrap {
      display: block;
      width: 236px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  @include screen("tablet-md") {
    .appointments {
      padding-bottom: $spacing-05;
      height: 236px;
      overflow-y: scroll;
      border-radius: $spacing-03;
      background-color: $gray-0;
      padding: $spacing-05;
      border: 1px solid $gray-100;
    }
  }

  .appointment {
    background-color: $white;
    border: 1px solid $gray-300;
    border-radius: $spacing-03;
    padding: calc(#{$spacing-01} + #{$spacing-04}) $spacing-05;
    margin-bottom: $spacing-03;
    @include body-s-regular;
    cursor: pointer;

    &.selected {
      border: 2px solid $azure-700;
      background-color: $regal-0;
    }

    &:hover {
      border: 2px solid $azure-700;
      background-color: $regal-0;
    }

    &.selectedAdmin {
      border: 1px solid #d1d1d1;
      background-color: $white;

      cursor: auto;

      &:hover {
        border: 1px solid #d1d1d1;
        background-color: #fff;
      }
    }
  }

  .modalityIcon {
    display: inline-block;
    margin-right: $spacing-05;
    vertical-align: middle;
  }
}
