@import "intakeOptimization/theme/typography.scss";
@import "intakeOptimization/theme/responsive.scss";
@import "intakeOptimization/theme/spacing.scss";
@import "intakeOptimization/theme/colors.scss";

.backContainer {
  display: none;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;
  align-items: center;
  
  @include screen('tablet-md') {
    display: flex;
  }
  span {
    display: block;
  }
  .back {
    background-color: transparent;
    border: none;
    display: flex;
    position: absolute;
    top: calc(#{$spacing-14} + #{$spacing-01});
    left: 14.3%;
    svg {
      margin: 0 $spacing-03 0 0;
      path {
        fill: $gray-700 !important;
      }
    }
  }
}
