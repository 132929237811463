@import "lifestance-ui/theme/typography.scss";
@import "lifestance-ui/theme/spacing.scss";
@import "lifestance-ui/theme/colors.scss";

.option {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: $spacing-07 $spacing-08;
  border: 1px solid $gray-200;
  margin: $spacing-03 0 1px;
  border-radius: $spacing-03;
  cursor: pointer;
}

.selected {
  border: $spacing-01 solid $azure-700;
  margin-bottom: 0;
}

label {
  @include font-styrene;
  margin-left: $spacing-04;
  display: flex;
}

input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: $gray-300;
  width: $spacing-06;
  height: $spacing-06;
  border: 1px solid $gray-300;
  border-radius: 50%;
  display: grid;
  place-content: center;
}

input[type="radio"]:checked {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  font: inherit;
  color: $gray-300;
  width: $spacing-06;
  height: $spacing-06;
  border: 1px solid $azure-700;
  border-radius: 50%;
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: $spacing-04;
  height: $spacing-04;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em $azure-700;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

// .radio {
//   width: $spacing-06;
//   height: $spacing-06;
//   border-radius: 50%;
//   color: 1px solid $gray-300;
//   background-color: #fff;
// }

// .radioSelected {
//   color: 1px solid $azure-700;
//   background-color: $azure-700;
// }
