@import 'intakeOptimization/theme/reset.scss';
@import 'intakeOptimization/theme/colors.scss';
@import 'intakeOptimization/theme/responsive.scss';
@import 'intakeOptimization/theme/spacing.scss';
@import 'intakeOptimization/theme/typography.scss';

.detail {
	@include body-xxs-regular;
	background-color: $gray-200;
	padding: $spacing-02 $spacing-03;
	border-radius: $spacing-02;
	text-transform: uppercase;
	color: $gray-600;
	text-align: center;
	margin-right: $spacing-03;

	&:last-child {
		margin-right: 0px;
	}
}