@import 'lifestance-ui/theme/spacing.scss';
@import 'lifestance-ui/theme/typography.scss';
@import 'lifestance-ui/theme/colors.scss';

@keyframes linearBlock {
	0% {
		left: -40%;
	}
	50% {
		left: 20%;
		width: 80%;
	}
	100% {
		left: 100%;
		width: 100%;
	}
}

.container {
	display: flex;

	.loaderLine {
        width: $spacing-16;
        height: $spacing-07;
        position: relative;
        overflow: hidden;
        background-color: $gray-50;
        border-radius: calc(#{$spacing-12} + #{$spacing-02});
        -moz-border-radius: calc(#{$spacing-12} + #{$spacing-02});
        -webkit-border-radius: calc(#{$spacing-12} + #{$spacing-02});
		
		&:before {
			content: "";
			position: absolute;
			left: -50%;
			height: $spacing-07;
			width: 50%;
			background-image: linear-gradient(to right, $gray-50, $gray-100);
			-webkit-animation: linearBlock 1s linear infinite;
			-moz-animation: linearBlock 1s linear infinite;
			animation: linearBlock 1s linear infinite;
			border-radius: $spacing-05;
			-webkit-border-radius: $spacing-05;
			-moz-border-radius: $spacing-05;
		}
    }

	.content {
		display: inherit;
		width: 100%;
	}

	.hide {
		display: none;
	}

}