@import "intakeOptimization/theme/typography.scss";
@import "intakeOptimization/theme/responsive.scss";
@import "intakeOptimization/theme/spacing.scss";
@import "intakeOptimization/theme/colors.scss";

.container {
  padding: $spacing-07 0;

  @include screen("tablet-md") {
    margin: 0 auto;
    width: $spacing-22;
  }

  .title {
    @include title-xl-regular;
    color: #333;
    text-align: center;
    @include screen("phone") {
      line-height: calc(#{$spacing-02} + #{$spacing-07});
    }
  }

  .description {
    margin: $spacing-03 0 $spacing-09;
    @include body-s-regular;
    text-align: center;
    color: $gray-700;
  }

  .fieldset {
    margin: $spacing-08 0;
    position: relative;

    label {
      display: inline-block;
      margin-bottom: $spacing-03;
    }

    button {
      margin-bottom: $spacing-03;
    }

    &.inputError input {
      border: 1px solid $sienna-500;
    }
  }

  .checkboxContainer {
    display: flex;

    label {
      display: flex;
      align-items: center;
      cursor: pointer;
      user-select: none;

      span {
        @include body-xs-regular;
      }
    }
  }

  .labelContainer {
    display: flex;
    justify-content: space-between;

    .optional {
      @include body-xs-regular;
      color: $gray-400;
    }
  }

  .error {
    @include body-xs-regular;
    color: $sienna-600;
    margin-top: $spacing-03;
  }

  .checkbox {
    width: $spacing-06;
    height: $spacing-06;
    border: 1px solid #c3c3c3;
    border-radius: $spacing-02;
    cursor: pointer;
    position: relative;
    margin-right: $spacing-05;

    &.checked {
      background: $azure-700;

      &:after {
        opacity: 1;
      }
    }

    &:after {
      content: "";
      width: $spacing-03;
      height: $spacing-02;
      position: absolute;
      top: $spacing-02;
      left: $spacing-02;
      border: 3px solid #fff;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
    }

    &:hover::after {
      opacity: 0.5;
    }
  }

  input[type="checkbox"] {
    visibility: hidden;
    margin-left: -13px;
    transform: scale(0);
    position: absolute;
  }

  .footer {
    @include body-s-regular;
    text-align: center;

    a {
      @include body-s-medium;
      color: $azure-700;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .bottomFieldset {
    margin-top: $spacing-11;
    margin-bottom: $spacing-04;
  }
}

.input {
  @include body-s-medium;
  padding: $spacing-05;
  width: 100%;
  height: calc(#{$spacing-02} + #{$spacing-10});
  background: $white;
  border: 1px solid $gray-300;
  box-sizing: border-box;
  border-radius: $spacing-03;
  color: $gray-800;
  font-style: normal;
  font-weight: normal;
  font-size: $spacing-05;
  line-height: $spacing-07;

  &::placeholder {
    color: $gray-400;
  }

  &:focus {
    outline: none;
    border: 1px solid $gray-400;
  }

  &.error {
    border-color: $sienna-500;
  }
}
.childrenContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: $spacing-11;
}

.backContainer {
  display: none;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;
  align-items: center;
  padding-top: $spacing-06;
  @include screen("tablet-md") {
    display: flex;
  }
  span {
    display: block;
  }
  .back {
    background-color: transparent;
    border: none;
    display: flex;
    position: absolute;
    top: $spacing-15;
    left: 14.3%;
    svg {
      margin: 0 $spacing-03 0 0;
      path {
        fill: $gray-700 !important;
      }
    }
  }
}
