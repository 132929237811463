@import 'lifestance-ui/theme/typography.scss';
@import 'lifestance-ui/theme/spacing.scss';
@import 'lifestance-ui/theme/colors.scss';

.container {
	display: flex;
	border-radius: $spacing-03;
    padding: $spacing-07 $spacing-06;
	align-items: flex-start;
	gap: $spacing-04;

	.icon {
		display: flex;
		justify-content: center;
		align-items: center;

		svg {
			width: $spacing-07;
			height: $spacing-07;
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: $spacing-02;
		flex: 1 0 0;

		.label {
			@include body-s-medium;
			color: $black;
		}

		.description {
			@include body-s-regular;
			color: $black;

			.phone {
				color: $azure-700;
			}
		}

		.link {
			color: $azure-700;

		}
	}
}

.information {
	background-color: $regal-0;
}

.warning {
	background-color: $gold-0;
}

.critical {
	background-color: $sienna-0;
}

.success {
	background-color: $casal-0;
}
