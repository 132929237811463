@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/colors.scss';
@import "patientOnboarding/theme/responsive.scss";

.newPatientContainer {
  margin: 0px;
  margin-bottom: $spacing-08;

  @include screen('tablet-lg') {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .box {
    border: 1px solid $gray-200;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: $spacing-03;
    cursor: pointer;

    @include screen('tablet-lg') {
      cursor: initial;
      width: $spacing-22;

      &.mobile {
        display: none;
      }
    }

    .button {
      margin: $spacing-08 0;
    }
  }

  .book {
    @include screen('tablet-lg') {
      display: block;
      width: 100%;
      margin: auto;
      margin-top: $spacing-04;
    }
  }

  .title {
    @include title-l-semibold;
  }

  .description {
    @include body-s-regular;
    margin-top: $spacing-02;
  }

  .reasonLabel {
    @include body-s-medium;
    margin-top: $spacing-07;
    margin-bottom: $spacing-03;
  }
}

.titleBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-06 $spacing-07;


  @include screen('tablet-lg') {
    display: block;
    padding: $spacing-08 $spacing-07 0;

  }

  .formInputs {
    .initialTitle {
      @include body-s-regular;
      margin-bottom: $spacing-08;
    }

    margin-bottom: $spacing-08;

    .inputUnit {
      max-width: 417px;
      input {
        margin-bottom: $spacing-04;
        padding-left: $spacing-11;
      }
      svg {
        position: absolute;
        padding: $spacing-05;
        color: rgb(49, 0, 128);
        width: $spacing-07;
        height: $spacing-07;
        padding: $spacing-04 $spacing-05 $spacing-05 $spacing-06;
        text-align: left;
      }
    }
    .link {
      text-decoration: none;
      color: $azure-700;
      @include body-s-medium;
      display: block;
      cursor: pointer;
      text-align: center;
      margin-top: $spacing-07;
      @include screen('tablet-lg') {
        text-align: center;
      }
      span {
        vertical-align: top;
        margin-left: $spacing-02;
      }
    }
  }

  .formInputLoading {
    border-top: none;
    padding-top: unset;
  }

  .appointment {
    background-color: #fff;
    border: 1px solid #d1d1d1;
    border-radius: $spacing-03;
    padding: $spacing-04 $spacing-06;
    margin-bottom: $spacing-04;
    @include body-s-regular;
    cursor: pointer;
    align-items: center;
    display: flex;

    .wordWrap {
      width: 352px;
      overflow:hidden;
      white-space:nowrap;
      text-overflow: ellipsis;
    }

    &.selected {
      border: 2px solid $azure-700;
      background: $regal-0;
    }

    &.selectedAdmin {
      border: 1px solid #d1d1d1;
      background-color: #fff;
      cursor:auto;

      &:hover {
        border: 1px solid #d1d1d1;
        background-color: #fff;
      }
    }

    svg {
      margin-right: 18px;
      transform: scale(1.25);
    }

    &:hover {
      border: 2px solid $azure-700;
      background-color: $regal-0;
    }
  }
}

.toast {
  margin-top: 0;
  position: relative;
  visibility: hidden;
  top: -$spacing-07;
  left: 0px;
  opacity: 0;
  transform: translateY(0);
  height: 0px;
  padding: 0px $spacing-06;
  transition: all 0.3s ease;
}

.showToast {
  margin-top: $spacing-07;
  visibility: visible;
  opacity: 1;
  height: auto;
  padding: $spacing-07 $spacing-06;
  transform: translateY($spacing-07);
  margin-bottom: $spacing-07
}

