@import 'intakeOptimization/theme/typography.scss';
@import 'intakeOptimization/theme/spacing.scss';
@import 'intakeOptimization/theme/colors.scss';
@import 'intakeOptimization/theme/responsive.scss';

.previews {
  display: none;

  @include screen('tablet-lg') {
    border: 1px solid $gray-200;
    padding: $spacing-03;
    display: flex;
    border-radius: $spacing-02;
    background-color: $white;
  }

  .miniature {
    max-width: calc(#{$spacing-11} + #{$spacing-04} + #{$spacing-01});
    min-width: calc(#{$spacing-11} + #{$spacing-04} + #{$spacing-01});
    height: $spacing-10;
  }

  .cardContainer {
    display: flex;
    flex-wrap: wrap;
    margin-left: $spacing-03;
    width: 260px;

    .cardDetails {
      @include body-xs-regular;
      color: $gray-400;
      display: block;
      width: 100%;
    }

    .cardHeader {
      @include body-xs-medium;
      color: $gray-800;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .closeButton {
    display: flex;
    align-items: center;
    // Related to padding on previews class
    padding-right: calc(#{$spacing-04} - #{$spacing-01});
  }
}

.errorBorder {
  border: 1px dashed $sienna-600;
}

.error {
  display: inline-block;
  margin-top: $spacing-03;
  @include body-xs-regular;
  color: $sienna-600;
}
