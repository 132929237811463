@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/colors.scss';

.icon {
	background-color: $regal-50;
	display: flex;
	// Square 64px
    height: $spacing-11;
    width: $spacing-11;
	// Circle 64px border radius ratio 0.5 square side
	border-radius: calc(#{$spacing-11}/2);
    margin: auto;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
}

.title {
	@include body-s-medium;
	color: $gray-800;
	margin-top: $spacing-07;
	margin-bottom: $spacing-04;
	text-align: center;

	@include screen('tablet-md') {
		@include title-xl-regular;
		margin-top: $spacing-07;
		margin-bottom: $spacing-03;
	}
}

.description {
	@include body-s-regular;
	color: $gray-800;
	text-align: center;
    display: inline-block;

	.phoneNumber {
        @include body-s-medium;
		color: $azure-700;
        text-decoration: none;
        display: inline-block;
	}

	.phone {
		@include body-s-medium;
		color: $gray-800;
	}
}