@import "lifestance-ui/theme/spacing.scss";
@import "lifestance-ui/theme/responsive.scss";
@import "lifestance-ui/theme/typography.scss";
@import "lifestance-ui/theme/colors.scss";

.scrim {
  background: rgba(51, 51, 51, 0.43);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  @include screen(tablet-md) {
    display: none;
  }
}

.wrapper {
  display: block;
  position: fixed;
  width: 100%;
  background-color: $white;
  max-height: 95vh;
  z-index: 10;
  bottom: -100vh;
  left: 0;
  transition: all 0.3s ease;
  overflow-y: hidden;
  border-radius: $spacing-06 $spacing-06 0 0;

  &.open {
    bottom: 0;
    box-shadow: 0px $spacing-03 $spacing-07 rgb(0 0 0 / 12%);
    -webkit-overflow-scrolling: touch;
    touch-action: none;
    overflow: scroll;

    &.openiOs {
      bottom: -11vh;
    }

    &.fullHeight {
      top:$spacing-09;
    }

    @include screen("tablet-lg") {
      top: 0px;
    }

    &.sideWays {
      bottom: 0;
      animation: slidein-from-left2 500ms;
      transition: none;
      @keyframes slidein-from-left2 {
        0% {
          transform: translateY(100vh);
        }
        100% {
          transform: translateY(0px);
        }
      }
      &.openiOs {
        max-height: 88vh;
        .options {
          max-height: calc(88vh - (#{$spacing-11} + #{$spacing-03}) - (#{$spacing-12}));
        }
      }
    }

    .stickyButton {
      position: fixed;
      padding: $spacing-06 $spacing-07;
      bottom: 0%;
      z-index: 1;
      background-color: $white;
      width: 100%;
      box-sizing: border-box;
      border-top: 1px solid $gray-200;
      -webkit-overflow-scrolling: touch;
    }

    .stickyButtoniOS {
      padding: $spacing-06 $spacing-07 $spacing-06 !important;
    }
  }

  .top {
    @include body-s-regular;
    padding: $spacing-07 $spacing-08;
    color: $gray-800;
    border-bottom: 1px solid $gray-200;
    position: fixed;
    width: 100%;
    z-index: 99;
    display: block;
    box-sizing: border-box;
    background: white;
    height: calc(#{$spacing-03} + #{$spacing-11});
    border-radius: $spacing-06 $spacing-06 0 0;

    .handleClose {
      position: absolute;
      top: calc(#{$spacing-07} + #{$spacing-01});
    }

    .title {
      display: block;
      text-align: center;
    }
  }

  .options {
    margin-bottom: 7rem;
    overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: calc(95dvh - (#{$spacing-11} + #{$spacing-03}) - (#{$spacing-12}));
    margin-top: 4.5rem;

    &.openiOs {
      min-height: 35vh;
    }
  }

  input[type="radio"] {
    margin-right: $spacing-05;
  }

  .clean {
    & > div {
      border: none;
      display: flex;
      @include body-s-regular;
      align-items: center;
      padding: $spacing-06;
      margin-top: 0px;
      border-bottom: 1px solid $gray-200;

      &:first-child {
        margin-top: $spacing-02;
      }
      &:last-of-type {
        border-bottom: none;
      }
    }
    & > div > label {
      display: flex;
      margin-left: $spacing-05;
    }
  }

  .optionsDescription {
    max-height: none;
  }

  .new {
    margin-right: $spacing-01;
  }

  .item {
    @include body-s-regular;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $black;
    background: $white;
    padding: $spacing-08 0;
    border-bottom: 1px solid $gray-200;
    margin: 0 $spacing-07;
    max-height: calc(#{$spacing-06} + #{$spacing-11});
    box-sizing: border-box;

    &:last-child {
      border-bottom: 0;

      .description {
        &:after {
          content: none;
        }
      }
    }

    .description {
      padding-bottom: $spacing-06;
      position: relative;
      @include body-s-regular;
      color: $gray-800;
      width: 100%;
      display: block;

      b {
        font-weight: 500;
      }
      

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        border-bottom: 1px solid $gray-200;
      }
    }
  }

  .disabledItem {
    color: $gray-400;
  }

  .itemDescription {
    max-height: none;
    padding: $spacing-06 $spacing-07 0 ;
    margin: 0;
    border-bottom: 0;
    padding-bottom: 0px;


    .title {
      @include body-s-medium;
      color: $gray-800;
    }
  }

  .selectionDescription {
    background-color: $regal-0;
  }

  .selection {
    justify-content: space-between;
    display: flex;
    @include body-s-medium;
  }

  .unsure {
    @include body-s-medium;
    color: $azure-700;
    justify-content: space-between;
    margin: $spacing-06 $spacing-07;
    display: flex;
    text-decoration: none;
  }

  @include screen("tablet-lg") {
    display: none;
  }
}
