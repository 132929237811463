@import "patientOnboarding/theme/spacing.scss";
@import "patientOnboarding/theme/colors.scss";
@import "patientOnboarding/theme/typography.scss";
@import "patientOnboarding/theme/responsive.scss";

.container {
  padding: $spacing-07;
  padding-top: $spacing-10;
  @include screen("desktop-sm") {
    padding: 0;
  }
  .description {
    @include body-s-regular;
    margin-bottom: $spacing-06;
    color: $gray-700;

    @include screen("desktop-sm") {
      margin-bottom: $spacing-10;
    }
  }

  .input {
    max-width: 100%;
    margin-bottom: $spacing-08;

    &:last-of-type {
      margin-bottom: $spacing-11;
    }

    label {
      @include body-s-medium;
      display: block;
      margin-bottom: $spacing-03;
    }

    .emailInput {
      width: 100%;
      width: -webkit-fill-available;
      max-width: 100%;
      @include body-s-regular;
      padding: $spacing-04;
      border: 1px solid $gray-300;
      border-radius: 8.19px;
      @include screen("desktop-sm") {
      }
      &::placeholder {
        color: $gray-400;
      }
    }

    .inputNoMatch {
      border: 1px solid $sienna-600;
    }
  }

  .emailNoMatch {
    @include body-xs-regular;
    color: $sienna-600;
    margin-top: $spacing-03;
  }
}
