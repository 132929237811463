@import 'patientOnboarding/theme/colors.scss';
@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/typography.scss';

.insuranceContainer {
    @include body-s-regular;

	.header {
		display: flex;
		margin-bottom: $spacing-04;
		.title {
			@include body-s-medium;
			margin-left: calc(#{$spacing-07} + #{$spacing-01});
		}
	}

	.insuranceList {
		padding-left: $spacing-10;
		margin-bottom: $spacing-03;

		.area {
			margin-bottom: $spacing-02;
			display: flex;

            &:nth-child(n + 5) {
                display: none;
            }

            &.expanded {
                &:nth-child(n + 5) {
                    display: flex;
                }
            }

            .vector {
                display: inline-block;
                margin-left: 0px;
                /* 5px */
                padding-top: calc(#{$spacing-02} + 0.063rem);
				flex-shrink: 0;
            }

            div {
                display: inline;
                @include body-s-regular;
                margin-left: $spacing-05;
			}
		}
	}

	.seeMore {
		padding-left: $spacing-10;
		@include body-s-medium;
		color: $azure-700;
		text-decoration: none;
		cursor: pointer;
		display: block;
		
		.arrowIcon {
			margin-left: $spacing-04;
		}
	}
}
.customAlert {
	gap: $spacing-02;
}

.header {
	display: flex;
	
	.title {
		@include body-s-medium;
		margin-left: $spacing-05;
	}
}

.content {
	padding-left: calc(#{$spacing-08} + #{$spacing-02 + $spacing-02});

	.phoneNumber {
		@include body-s-medium;
		color: $gray-800;
		white-space: nowrap;
	}
}
