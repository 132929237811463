@import 'lifestance-ui/theme/responsive';
@import 'lifestance-ui/theme/spacing';

.container {
  margin: 0 $spacing-07;

  @include screen('tablet-sm') {
    margin: 0 $spacing-08;
  }

  @include screen('tablet-lg') {
    margin: 0 $spacing-11;
  }

  @include screen('desktop-sm') {
    max-width: 1152px;
    margin: auto;
  }

  @include screen('desktop-lg') {
    max-width: 1248px;

    &.small {
      padding-left: calc(#{$spacing-02} + #{$spacing-12});
    }
  }
  &.fullWidth {
    margin: 0;
    max-width: none;
    width: 100%;
  }
}
