@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/colors.scss';


.select {
  @include body-s-regular;
  width: calc(#{$spacing-02} + #{$spacing-08});
  height: calc(#{$spacing-02} + #{$spacing-08});
  background-color: $white;
  border-bottom: 1px solid #d1d1d1;
  color: $gray-700;
  appearance: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  border-radius: calc(#{$spacing-02} + #{$spacing-12});
  border: 1px solid $gray-300;
  padding: $spacing-03;
  box-sizing: border-box;

  .circle {
    @include body-xxs-regular;
    width: $spacing-06;
    border-radius: $spacing-12;
    background-color: $azure-700;
    box-sizing: border-box;
    padding: $spacing-01;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobileCircle {
    @include body-xxs-medium;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $spacing-03;
    min-width: $spacing-05;
    height: $spacing-05;
    border-radius: calc(#{$spacing-12} + #{$spacing-02});
    background-color: $azure-700;
    box-sizing: border-box;
    color: $white;
    text-align: center;
    border: 1px solid $white;
    position: relative;
    bottom: calc(#{$spacing-04} + #{$spacing-01});
    right: $spacing-02;
  }

  .mobileCirclePadding {
    padding: 0px $spacing-03;
  }

  &:hover {
    border: 1px solid $gray-400;
  }

  &.selectedOptions {
    background-color: $regal-0;
    border: 2px solid $azure-700;
    padding: calc(#{$spacing-01} + #{$spacing-02});
  }

  @include screen('tablet-sm') {
    justify-content: space-between;
    width: auto;
    padding: $spacing-03 $spacing-05;
  }
  
  @include screen('tablet-md') {
    &::after {
      border-right: none;
      right: 0px;
    }
  }

  @include screen('tablet-lg') {
    display: none;
  }
}

.adminSelect {
  @include screen('tablet-lg') {
    display: flex;
  }
  @include screen('desktop-sm') {
    display: none;
  }
}

.labelTabletFilters {
  display: none;
  @include screen('tablet-sm') {
    display: block;
    margin-right: $spacing-03;
    margin-left: $spacing-03;
  }
  @include screen('desktop-sm') {
    display: none;
  }
}

.filtersIcon {
  display: block;
  width: calc(#{$spacing-05} + #{$spacing-01});
  height: calc(#{$spacing-05} + #{$spacing-01});
}

.adminLableTabletFilters {
  display: none;
  @include screen('tablet-sm') {
    display: block;
    margin-left: $spacing-03;
  }
  @include screen('desktop-lg') {
    display: none;
  }
}

.handleClose {
  margin-top: calc(#{$spacing-01} + #{$spacing-02});
  position: absolute;
  left: $spacing-07;
  cursor: pointer;
}

.clearAll {
  color: $azure-700;
  @include body-s-medium;
  position: absolute;
  right: $spacing-07;
  cursor: pointer;
}

.filtersTitle {
  @include body-s-regular;
  color: $black;
}
.underLayer {
  display: none;
  &.open {
    display: block;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    background-color: #2626266e;
    height: 100vh;
    z-index: 9;
  }
}

.mobileFilters {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 130vh;
  background-color: $white;
  overflow-y: auto;
  transition: 0.3s ease all;
  height: 100vh;
  border-radius: $spacing-06 $spacing-06 0px 0px;

  &.open {
    top: $spacing-09;
    z-index: 10;
    height: 100%;

    .footer {
      position: fixed;
    }
  }
}

.topFilters {
  box-sizing: border-box;
  padding: $spacing-06 $spacing-08;
  height: calc(#{$spacing-02} + #{$spacing-11});
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $gray-200;
  z-index: 11;
  border-radius: $spacing-06 $spacing-06 0px 0px;
  background-color: $white;
  position: fixed;
  width: 100%;
  left: 0;
  top: 130vh;
  transition: 0.3s ease all;

  &.open {
    top: $spacing-09;
  }
}

.filters {
  padding-bottom: $spacing-13;
  padding-top: calc(#{$spacing-02} + #{$spacing-11});
  overflow: initial;
  min-height: 100vh;

  @include screen('tablet-lg') {
    height: auto;
    overflow: initial;
  }
}

.bottomFilters {
  position: fixed;
  bottom: 0px;
  height: calc(#{$spacing-02} + #{$spacing-12});
  box-sizing: border-box;
}

.footer {
  display: flex;
  padding: $spacing-06;
  margin-top: calc(#{$spacing-01} + #{$spacing-03});
  border-top: 1px solid $gray-300;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: $white;

  button {
    svg {
      circle {
        stroke: white;
      }
    }
  }
}
