@import 'lifestance-ui/theme/typography.scss';
@import 'lifestance-ui/theme/spacing.scss';
@import 'lifestance-ui/theme/colors.scss';
@import 'lifestance-ui/theme/responsive';

.tooltip {
    position: absolute;
    top: 0px;
    right: 0px;
    .legend {
      display: none;
      position: absolute;
      bottom: $spacing-08;
      left: 50%;
      transform: translate(-50%, 0);
      background-color: $regal-900;
      color: #fff;
      @include body-xs-regular;
      border-radius: $spacing-03;
      padding: $spacing-05;
      width: 262px; /* change this for max-content or maybe a props param */
    }
    &:hover .legend {
      display: block;
    }
}

.adminTooltip {
  .legend {
    display: none;
    position: absolute;
    bottom: 30%;
    background-color: $regal-900;
    color: white;
    @include body-xs-regular;
    border-radius: $spacing-03;
    padding: $spacing-05;
    width: 262px; /* change this for max-content or maybe a props param */
  }
  &:hover .legend {
    display:none;

    @include screen('tablet-sm'){
      display: block;
      bottom: 110.5%;
      left: 18.5%;
    }

    @include screen('tablet-md'){
      transform: translate(45%, -25%);
      bottom: 39.5%;
      left: 34%;
    }

    @include screen('tablet-lg'){
      left: 25.5%;
      transform: translate(35%, -25%);  
      bottom: 32%;
    }

    @include screen('desktop-sm'){
      left: 72%;
      bottom: -74%;
      transform: translate(50%, 50%);
    }

    @include screen('desktop-lg'){
      left: 62%;
      bottom: -72%;
      transform: translate(50%, 50%);
    }
  }
  .dependentLegend {
    display: none;
    position: absolute;
    bottom: 30%;
    background-color: $regal-900;
    color: white;
    @include body-xs-regular;
    border-radius: $spacing-03;
    padding: $spacing-05;
    width: 262px; /* change this for max-content or maybe a props param */
  }
  &:hover .dependentLegend {
    display:none;

    @include screen('tablet-sm'){
      display: block;
      bottom: 76%;
      left: 43.5%;
      transform: translate(-30.5%, -10%);
    }

    @include screen('tablet-md'){
      transform: translate(45%, -30%);
      bottom: 8%;
      left: 33.5%;
    }

    @include screen('tablet-lg'){
      left: 25%;
      transform: translate(35%, -20%);  
      bottom: 55%;
    }

    @include screen('desktop-sm'){
      bottom: -10%;
      left: 81%;
      transform: translate(35%, 70%);
    }

    @include screen('desktop-lg'){
      left: 70.5%;
      bottom: -63%;
      transform: translate(38%, 55%);
    }
  }
}