@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/colors.scss';
@import 'patientOnboarding/theme/responsive.scss';

.mainContainer{
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    @include screen('tablet-md') {
        padding: 0;
    }

    .iconContainer{
        height: $spacing-11;
        width: $spacing-11;
        background-color: $sienna-0;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: $spacing-07;
        svg {
            path {
                fill: $sienna-600;
            }
        }
    }

    .title{
        @include body-s-medium;
        margin-bottom: $spacing-04;
        @include screen('desktop-sm'){
            @include title-xl-regular;
            margin-bottom: $spacing-04;
        }
    }

    .text{
        @include body-s-regular;
        @include screen('desktop-sm'){
            margin-bottom: $spacing-09;
        }
        max-width: 400px;
    }

    .numberMobile {
        @include screen('desktop-sm'){
            display: none;
        }
    }

    .number {
        display: none;
        font-weight: 500;
        @include screen('desktop-sm'){
            display: inline-block;
        }
    }
}

.portalButton {
    max-width: 342px;
    margin-left: auto;
    margin-right: auto;
    padding-left: $spacing-07;
    padding-right: $spacing-07;
}
