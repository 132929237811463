@import "patientOnboarding/theme/typography.scss";
@import "patientOnboarding/theme/spacing.scss";
@import "patientOnboarding/theme/colors.scss";
@import "patientOnboarding/theme/responsive.scss";

.container {
  .header {
    height: 145px;
    background: $regal-800;
    position: relative;

    .customHeader {
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      bottom: $spacing-10;
      z-index: 100;

      svg {
        margin-top: $spacing-02;
      }

      span {
        color: $azure-700;
        @include body-s-regular;
      }

      @include screen("tablet-lg") {
        display: none;
      }
    }

    @include screen("desktop-sm") {
      height: 177px;
    }

    .profileImage {
      top: 50%;
      position: relative;
      text-align: center;

      @include screen("tablet-lg") {
        position: absolute;
        bottom: -81px;
      }

      img {
        border-radius: 100px;
        margin-top: 70px;
        object-fit: cover;

        @include screen("tablet-md") {
          margin-top: 0;
        }
      }
    }

    .isAdminImage {
      top: 50%;
      position: relative;
      text-align: center;

      @include screen('tablet-md'){
        position: absolute;
      bottom: -31px;
      left: 40%;
    }

      @include screen('tablet-lg'){
        left: 6%;
      }
    }

    .adminLink {
      @include body-s-regular;
      color: #ffffff;
      display: flex;
      width: fit-content;
      position: relative;
      top: $spacing-07;
      align-items: center;
      padding-left: $spacing-01;
      padding-right: $spacing-01;

      svg {
        vertical-align: middle;
      }

      span {
        display: none;
        @include screen("tablet-md") {
          display: inline-block;
          vertical-align: top;
          margin-left: $spacing-02;
        }
      }

      &:focus-visible {
        outline: $spacing-01 solid $white;
        outline-offset: $spacing-01;
        border-radius: $spacing-02;
      }
    }
    
    .link {
      display: none;
      position: relative;
      z-index: auto;

      @include screen("tablet-md") {
        display: block;
        text-decoration: none;
        color: #ffffff;
        @include body-s-regular;
        padding: $spacing-07 0;
        display: block;
      }

      svg {
        vertical-align: middle;
      }

      span {
        display: none;
        @include screen("tablet-md") {
          display: inline-block;
          vertical-align: top;
          margin-left: $spacing-02;
        }
      }
    }
  }

  .externalServices {
    display: inline-flex;
    margin-top: $spacing-06;

    span {
      margin-top: $spacing-02;
      margin-right: $spacing-05;
    }

    .pillbox {
      display: inline-flex;
      @include body-s-regular;
      color: $gray-800;
      border: 1px solid $gray-200;
      padding: $spacing-02 $spacing-04;
      border-radius: calc(#{$spacing-02} + #{$spacing-12});
      text-align: center;
      margin-right: $spacing-03;
      margin-bottom: $spacing-03;

      a, a:visited {
        color: inherit;
      }

      &:hover {
        border: 1px solid $gray-400;
      }
    }
  }

  .maincontainer {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;

    .clinicianinfo {
      margin-top: 114px;

      @include screen("tablet-md") {
        flex: 40%;
        display: inline-block;
        margin-right: 27px;
      }

      @include screen('tablet-lg') {
        padding-right: $spacing-08;
      }

      @include screen("desktop-sm") {
        margin-right: $spacing-13;
      }

      @include screen("desktop-lg") {
        margin-right: $spacing-13;
      }

      .clinicianType {
        @include body-s-regular;
        display: block;
        text-align: center;
        font-style: normal;
        color: $gray-800;

        @include screen("tablet-md") {
          display: block;
        }
        @include screen("tablet-lg") {
          display: inline-block;
          margin-bottom: 8px;
        }
      }

      .clinicianName {
        text-align: center;
        color: $gray-800;
        @include title-xl-regular;
        font-weight: 400;
        border-bottom: 4px solid $casal-400;
        margin-bottom: $spacing-08;

        @include screen("tablet-sm") {
          @include title-xxl-regular;
        }

        @include screen("tablet-lg") {
          text-align: left;
          padding-bottom: $spacing-05;
        }

        .apptMobile {
          @include body-xs-regular;
          display: block;
          margin-top: $spacing-03;

          .link {
            display: inline;
            text-decoration: none;
            color: $azure-700;
            @include body-xs-medium;
            padding: 0px;
            margin-top: $spacing-02;
          }

          @include screen('tablet-lg') {
            display: none;
          }
        }
      }

      .covid {
        margin-bottom: $spacing-10;

        .heading {
          @include body-xs-bold;
          padding-top: $spacing-07;
          padding-bottom: $spacing-03;
        }

        .description {
          @include body-xs-regular;
          color: #484848;
          padding-bottom: $spacing-09;
        }
      }

      .background {
        margin-bottom: $spacing-10;

        .heading {
          padding-bottom: $spacing-07;
          @include S-semibold;
        }

        .customAlert {
          background-color: $lilac;
          padding: $spacing-04 $spacing-07;

          .supervisedDisclaimer {
            display: flex;
  
            svg {
              width: $spacing-05;
              height: $spacing-05;
            }
            
            .title {
              @include body-xs-medium;
              margin-left: $spacing-04;
            }
          }
        }

      }

      .localInfo {
        padding-bottom: $spacing-10;

        .heading {
          @include S-semibold;
          margin-bottom: $spacing-07;
        }

        .cancellationPolicy {
          svg {
            display: inline-block;
            vertical-align: top;
          }

          .heading {
            @include body-s-medium;
            margin-left: $spacing-07;
            display: inline-block;
            vertical-align: middle;
            margin-bottom: $spacing-04;
          }

          .body {
            padding-left: $spacing-10;
            @include body-s-regular;
          }
        }
      }

      .frequentlyAsked {
        padding-bottom: $spacing-10;

        .heading {
          @include S-semibold;
          margin-bottom: $spacing-02;
        }
      }
    }

    .tabletAvailability {
      display: block;

      @include screen("tablet-lg") {
        display: none;
      }
    }

    .availabilityContainer {
      width: 100%;

      @include screen("tablet-lg") {
        display: block;
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .share {
        color: #fff;
        position: absolute;
        top: 100px;
        right: $spacing-08;

        @include screen("tablet-lg") {
          position: inherit;
          text-align: right;
          margin-bottom: $spacing-07;
          color: #8b8b8b;
        }

        svg {
          display: inline-block;
          vertical-align: text-top;
          @include screen("tablet-lg") {
            filter: contrast(0.3);
          }
        }

        .heading {
          @include body-s-regular;
          margin-left: $spacing-03;
          display: inline-block;
        }
      }

      .availabilityMainContainer {
        display: none;
        @include screen("tablet-lg") {
          display: block;
        }
      }

      .scheduled {
        display: none;
        @include screen("tablet-lg") {
          border: 1px solid #e3e3e3;
          box-sizing: border-box;
          border-radius: $spacing-03;
          padding: $spacing-07;
          display: flex;
          align-items: center;
        }

        .appointmentContainer {
          display: inline-block;
          margin-left: $spacing-05;

          .heading {
            @include body-s-regular;
          }

          .link {
            text-decoration: none;
            color: $azure-700;
            @include body-s-medium;
            display: block;
            padding: 0px;
            margin-top: $spacing-02;

            span {
              vertical-align: top;
              margin-left: $spacing-02;
            }
          }
        }

        .calendarcheckline {
          display: inline-block;
        }
      }
    }
  }

  .viewAllAvailabilityButton {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e3e3e3;
    background-color: white;
    z-index: 1;
    box-sizing: border-box;
    left: 0;
    padding: $spacing-06;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);

    @include screen('tablet-lg') {
      display: none;
    }
  }

  .noAvailability {
    @include body-s-regular;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.viewAvailability {
  position: fixed;
  bottom: 0%;
  width: 100%;
  background: #fff;
  padding: $spacing-07 0;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);

  @include screen("tablet-lg") {
    display: none;
  }

  button {
    margin: auto;
    width: 80%;
  }
}

.existingContainer {
  display: none;

  @include screen("tablet-lg") {
    display: block;
  }
}

.about {
  border-top: 1px solid $gray-200;
  padding-top: $spacing-10;
  margin-bottom: 30px;
  margin-top: 48px;

  .heading {
    @include S-semibold;
    margin-bottom: $spacing-07;
  }

  .description {
    @include body-s-regular;
    margin-bottom: $spacing-08;
    white-space: pre-line;
  }
}

.employedBy {
  @include font-styrene;
  margin-bottom: $spacing-10;
  font-style: italic;
  color: $gray-400;
  font-weight: 300;
  line-height: $spacing-07;
}

.mobile {
  margin-top: $spacing-06;

  @include screen("tablet-lg") {
    display: none;
  }
}

.hideTablet {
  display: none;
  @include screen("tablet-lg") {
    display: block;
  }
}
