@import "intakeOptimization/theme/typography.scss";
@import "intakeOptimization/theme/spacing.scss";
@import "intakeOptimization/theme/colors.scss";
@import "intakeOptimization/theme/responsive.scss";

.container {
  max-width: $spacing-22;
  padding: 0 $spacing-07;

  .troubleContainer{

    margin-bottom: $spacing-07;
    border-radius: $spacing-03;
    background-color: $regal-50;
    padding: $spacing-07;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include screen("phone") {
      flex-direction: row;
      align-items: flex-start;
    }
    svg {
      padding-top: $spacing-02;
      padding-right: $spacing-05;
    }

    .troubleTitle{
      @include body-s-medium;
      word-break: break-word;
    }

    .troubleCTA{
      @include body-s-regular;
      word-break: break-word;
    }

    .troublePhone{
      @include body-s-medium;
      color: $azure-700;
      text-decoration: none;
      word-break: break-word;
      @include screen("tablet-md") {
        @include body-s-regular;
        color: $black;
      }
    }

  }

  .content {
    background-color: $gray-50;
    padding: $spacing-07;
    border-radius: $spacing-03;

    .bold {
      margin: 0;
      @include body-s-medium;
      word-break: break-word;
    }
    .text {
      margin: 0;
      @include body-s-regular;
    }
    .content_top {
      margin: 0;
    }
    .content_middle {
      margin-top: $spacing-08;
      margin-bottom: $spacing-07;
      .bold {
        margin-bottom: $spacing-03;
      }
      .list {
        list-style-type: disc;
        list-style-position: outside;
        margin: $spacing-04 $spacing-07 $spacing-07 $spacing-07;
        li {
          @include body-s-regular;
        }
      }
    }
    .resend_button {
      @include body-s-medium;
      border: none;
      color: $azure-700;
      background-color: transparent;
      margin-bottom: $spacing-03;
      padding: 0;
      cursor: pointer;
      @include screen("tablet-md") {
        margin-bottom: $spacing-07;
      }
    }
  }
  .whiteSpace {
    height: $spacing-08;
    width: 100%;
  }

  .hidden{
    display: none;
  }
}
