@import "patientOnboarding/theme/typography.scss";
@import "patientOnboarding/theme/spacing.scss";
@import "patientOnboarding/theme/colors.scss";

.modalBody {
	max-height: calc(100vh - #{$spacing-09}) !important;
	border-radius: $spacing-06 $spacing-06 0px 0px;

	.container {
		border-radius: $spacing-03;
		background-color: #fff;
		padding: calc(#{$spacing-13} - #{$spacing-06}) $spacing-07 calc(#{$spacing-12} + #{$spacing-02}) $spacing-07;
		height: calc(100vh - #{$spacing-09});
		height: calc(calc(var(--vh, 1vh) * 100) - #{$spacing-09});
		box-sizing: border-box;
		overflow-y: auto;
		width: 100vw;
	
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;     

        &::-webkit-scrollbar { /* Sarari and Chrome */
            display: none;
          width: 0px;
        }

		@include screen('tablet-sm') {
			padding-left: 20vw;
			padding-right: 20vw;
		}
    }
}

.noButton {
    display:none;
}