@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/colors.scss';
@import 'patientOnboarding/theme/responsive.scss';

.expertiseAreas{
    margin-bottom: $spacing-10;

    .tabs {
        overflow: hidden;
        border-bottom: 1px solid $gray-200;
        margin-bottom: $spacing-07;

        button {
            @media (max-width: map-get($breakpoints, 'phone')) {
                font-size: 14px;
                padding-bottom: $spacing-02;
            }
            @include screen('phone') {
                font-size: 14px;
                padding-bottom: $spacing-02;
                &:last-of-type {
                    padding-right: 0px;
                    padding-left: $spacing-01;
                }

                &:first-of-type {
                    padding-left: 0px;
                }
            }

            border: 0px;
            color: $gray-400;
            @include body-s-medium;
            background-color: white;
            float: left;
            cursor: pointer;

            @include screen('tablet-sm') {
                padding: $spacing-03 $spacing-04;
                font-size:16px;

                &:hover {
                    color: $gray-600 !important;
                }

                &:first-of-type {
                    padding-left: 0px;
                }

                &:last-of-type {
                    padding-left: $spacing-02;
                    padding-right: $spacing-02;
                }
            }
        }

        .active {
            color: $regal-800;
            border-bottom: solid 3px $regal-800;

        }
    }

    .nodata {
        margin-bottom: 0;
    }

    .show {
        display: block;
    }

    .hide {
        display: none;
    }

    .seeMore {
        @include body-s-medium;
        color: $azure-700;
        text-decoration: none;
        margin-bottom: $spacing-04;
        cursor: pointer;
        margin-top: $spacing-07;
        display: block;
        .arrowIcon{
            margin-left: $spacing-03;
        }
    }

    .heading{
        margin-bottom: $spacing-07;
        @include S-semibold;
    }

    .areas{
        display: block;

        @include screen('tablet-sm') {
            flex-wrap: wrap;
            display: flex;
            column-count: 2;
        }

        .heading {
            margin-bottom: $spacing-07;
            @include S-semibold;

            @include screen('tablet-sm') {
                column-span: all;
            }
        }


        .area {
            margin-bottom: $spacing-03;
            display: flex;
            align-items: flex-start;
            width: 100%;

            &:nth-child(n + 5) {
                display: none;
            }

            &.expanded {
                &:nth-child(n + 5) {
                    display: inherit;
                }
            }

            .vector {
                display: inline-block;
                margin-left: 0px;
                /* 5px */
                padding-top: calc(#{$spacing-02} + 0.063rem);
            }

            div {
                display: inline-block;
                @include body-s-regular;
                margin-left: $spacing-05;
            }

            @include screen('tablet-sm') {
                margin-bottom: $spacing-03;
                display: flex;
                width: 50%;

                &:nth-child(n + 5) {
                    display: flex;
                }

                &:nth-child(n + 9) {
                    display: none;
                }

                &.expanded {
                    &:nth-child(n + 9) {
                        display: flex;
                    }
                }

                .vector {
                    display: inline-block;
                    min-width: 18px;
                }

                div {
                    display: inline-block;
                    @include body-s-regular;
                    margin-left: $spacing-05;
                }
            }
        }
        .oneRow {

            @include screen('tablet-sm') {
                width: 100%;
            }
        }
    }
    .fewItems {

        @include screen('tablet-sm') {
            column-count: 1;
        }
    }
}

