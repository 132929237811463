@import 'patientOnboarding/theme/typography';
@import 'patientOnboarding/theme/spacing';
@import 'patientOnboarding/theme/colors.scss';

.container {
	margin-top: $spacing-07;
	margin-bottom: $spacing-08;
	@include body-s-regular;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: $spacing-07 $spacing-06;
	gap: $spacing-03;
	background-color: $gray-50;
	border-radius: $spacing-03;
}