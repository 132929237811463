@import "intakeOptimization/theme/typography.scss";
@import "intakeOptimization/theme/responsive.scss";
@import "intakeOptimization/theme/spacing.scss";
@import "intakeOptimization/theme/colors.scss";

.card {
	background: $white;
	box-shadow: 0px $spacing-02 $spacing-03 $spacing-02 rgba(0, 0, 0, 0.04);
	border-radius: $spacing-02;
	padding: $spacing-07 $spacing-08;
	@include body-s-regular;
	margin: 0 0 $spacing-07;
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	border: 0;
	width: 100%;
  
	.link {
		@include body-s-medium;
		color: $azure-700;
	}
  }