@import 'lifestance-ui/theme/typography.scss';
@import 'lifestance-ui/theme/colors.scss';
@import 'lifestance-ui/theme/spacing.scss';

.backdrop {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: $modal-overlay;
}
.legend {
  z-index: 100;
  position: fixed;
  bottom: -200px;
  left: 0;
  right: 0;
  width: 100%;
  background-color: $regal-900;
  color: #fff;
  border-radius: $spacing-06 $spacing-06 0 0;
  width: 100%;
  transition: bottom 0.1s ease;

  &.open {
    bottom: 0;
  }

  .closeIcon {
    position: absolute;
    top: $spacing-08;
    left: $spacing-08;
  }
  .header {
    text-align: center;
    padding: $spacing-07;
    border-bottom: 1px solid rgba(227, 227, 227, 0.4);
    @include body-xs-regular;
  }
  .message {
    padding: $spacing-07 $spacing-07 $spacing-10;
    @include body-xs-regular;
  }
}
