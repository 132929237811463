@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/colors.scss';

.scrollableContainer {
  @include title-l-semibold;
  width: calc(100% - $spacing-10);
  @include screen('tablet-sm'){
    width: calc(100% - $spacing-12);
  }
  background: $white;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-bottom: $spacing-09;

  @media (max-width: 1024px) {
    height: 800px;
    overflow-y: scroll !important;
  }

  .subHeader {
    @include body-s-medium;
    color: $gray-800;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-bottom: $spacing-07;
  }

  .link {
    color: $azure-700;
    font-weight: 500;
  }

  .header {
    margin-bottom: $spacing-03;
    margin-top: calc($spacing-11 - 32px);
    text-align: center;

    @include screen('tablet-sm'){
      margin-top: $spacing-08;
    }

    @include screen('tablet-lg'){
      margin-top: $spacing-11;
    }
  }

  .title {
    @include body-s-medium;
    margin-bottom: $spacing-03;
  }

  .questions {
    display: flex;
    justify-content: space-between;
    margin-top: $spacing-06;
  }

  .toast {
    margin-top: 0;
    position: relative;
    visibility: hidden;
    top: -$spacing-07;
    left: 0px;
    opacity: 0;
    transform: translateY(0);
    height: 0px;
    padding: 0px $spacing-06;
    transition: all 0.3s ease;
  }

  .showToast {
    margin-top: $spacing-07;
    visibility: visible;
    opacity: 1;
    height: auto;
    padding: $spacing-07 $spacing-06;
    transform: translateY($spacing-07);
    margin-bottom: $spacing-07
  }

  .option {
    @include body-xs-regular;
    color: $gray-400;
  }

  .existing {
    @include body-s-regular;
    background-color: $beige;
    border-radius: $spacing-03;
    padding: $spacing-07 calc(#{$spacing-02} + #{$spacing-07});
    width: 88%;
    margin-top: $spacing-05;
    display: flex;
    justify-content: center;
    margin-bottom: calc(#{$spacing-02} + #{$spacing-09});
  }

  .unsure {
    @include body-s-medium;
    color: $azure-700;
    margin-left: $spacing-02;
    text-decoration: none;

    display: none;

    @include screen('tablet-sm'){
      display: flex;
    }
  }

  .optional {
    @include body-xxs-bold;
    display: flex;
    color: $gray-300;
    text-align: center;
    margin-top: $spacing-06;
    margin-bottom: $spacing-06;
  }

  .button {
    margin-top: $spacing-08;
  }

  .divider {
    width: 100%;

    svg {
      width: 80vw;
    }
  }

  .or {
    background-color: #FFF;
    position: relative;
    top: 3px;
    padding: 0 $spacing-06;
    left: calc(-50% - 30px);
  }

  .DOBInput {
    @include body-s-medium;
    padding: $spacing-05;
    width: 100%;
    height: calc(#{$spacing-02} + #{$spacing-10});
    background: $white;
    border: 1px solid $gray-300;
    box-sizing: border-box;
    border-radius: $spacing-03;
    color: $gray-800;
    font-style: normal;
    font-weight: normal;
    font-size: $spacing-05;
    line-height: $spacing-07;

    &::placeholder {
      color: $gray-400;
    }

    &:focus {
      outline: none;
      border: 1px solid $gray-400;
    }

    &.error {
      border-color: $sienna-600;
    }
  }

  .errorText {
    @include body-xs-regular;
    color: $sienna-600;
    margin-top: $spacing-03;
  }

  .previous {
    @include body-s-medium;
    color: $azure-700;
    margin-left: $spacing-02;
    text-decoration: none;
    cursor: pointer;
  }
}

.questionsContainer {
  height: fit-content !important;
}

.content {
  background: $regal-50;
  margin-top: $spacing-07;
  padding-right: $spacing-06;
  padding-top: $spacing-06;
  padding-bottom: $spacing-07;
  padding-left: $spacing-06;
  border-radius: $spacing-03;

  @include screen('tablet-md'){
    justify-content: flex-start;
  }

  .icon {
    width: 24px;
    height: 24px;
    float: left;
  }

  .contentTitle {
    @include body-xs-medium;
    font-size: 16px;
    margin-left: calc($spacing-04 + $spacing-06);
    margin-top: 4px;
  }

  .contentText{
    margin-left: $spacing-08;
    @include body-xs-medium;
    font-weight: 400;
    font-size: 16px;
    margin-top: $spacing-02;
  }

  .phone {
    @include body-xs-medium;
    font-weight: 500;
    color: $gray-800;
  }

}
