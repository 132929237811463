@import 'patientOnboarding/theme/typography';
@import 'patientOnboarding/theme/colors.scss';
@import 'patientOnboarding/theme/spacing';

.footer{
    padding: $spacing-07 $spacing-07;
    margin: 0 1.5rem;
    text-align: center;
    border-top: 1px solid $gray-300;

    @include screen('tablet-lg') {
        display: flex;
        justify-content: space-between;
        padding: $spacing-09 0;
        text-align: left;
    }

    @include screen('desktop-sm'){
        margin: 0 $spacing-12;
    }

    .linksContainer{
        display: flex;
        align-items: center;

        .verticalDivider{
            width: 1px;
            height: 18px;
            background-color: $gray-300;
            margin: 0 $spacing-06;
        }

        justify-content: center;

        @include screen('tablet-lg') {
            justify-content: flex-start;
        }

    }
    .copyright{
        @include body-xs-regular;
        color: $gray-500;
        margin-top: $spacing-04;

        @include screen('tablet-sm') {
            margin-top: 0;
        }
    }

    .legend {
        font-style: italic;
        @include body-xs-regular;
        color: $gray-500;
        margin-bottom: $spacing-03;
    }

    .link{
        @include body-xs-regular;
        color: $black;
        text-decoration: none;
        cursor: pointer;

        &:hover{
            color: $gray-900;
        }
    }

}
.admin {
    border-top: none;
    @include screen('tablet-sm') {
        margin: 0 $spacing-08;
    }
    @include screen('tablet-lg') {
        margin: 0 $spacing-11;
    }
    @include screen('desktop-sm'){
        margin: 0 auto;
        max-width: 1152px;
    }
    @include screen('desktop-lg') {
        max-width: 1248px;
    }
}

.divider {
    margin: 0 $spacing-06;
}
