@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/colors.scss';

.about {
  margin-top: $spacing-08;

  .heading {
    @include S-semibold;
    margin-bottom: $spacing-07;
  }

  .description {
    @include body-s-regular;
    margin-bottom: $spacing-08;
  }

  .aboutmore {
    @include body-s-regular;
    margin-bottom: $spacing-09;
  }

  .aboutLink {
    @include body-s-medium;
    color: $azure-700;
    cursor: pointer;
    margin-left: $spacing-02;
  }
  
  .aboutLinkAdmin {
    @include body-s-medium;
    color: $azure-700;
    cursor: pointer;
    margin-left: $spacing-02;
    padding-right: $spacing-02;
    padding-left: $spacing-02;

    // No offset is needed here as it will affect text on the right. Agreed with design team for spacing on top level to not clip text.
    &:focus-visible {
      outline: $spacing-01 solid $black;
      border-radius: $spacing-02;
    }
  }

  .services {
    display: flex;
    flex-wrap: wrap;

    .service[data-available="1"] {
      text-decoration-line: none;
      color: $gray-800;
    }

    .service[data-available="0"] {
      text-decoration-line: line-through;
      color: #ACACAC;
    }

    .service {
      margin-bottom: $spacing-06;
      width: 100%;
      display: inline-flex;

      &:last-child {
        margin-bottom: 0;
      }

      svg {
        flex-shrink: 0;
        width: $spacing-06;
        height: $spacing-06;
      }

      div {
        align-content: flex-start;
        @include body-s-regular;
        margin-left: $spacing-05;
      }
    }

    .service[data-id="gender"] {
      svg {
        margin-left: -2px !important;
        margin-right: -1px !important;
      }
    }

    .service[data-id="languages"] {
      svg {
        padding-top: 3px;
      }
    }

    .service[data-id="insurance"] {
      svg {
        padding-top: 2px;
      }
    }

    .service[data-id="ages"] {
      svg {
        padding-top: 2px;
      }
    }

    .service[data-id="app_type"] {
      svg {
        padding-top: 2px;
      }
    }

    .biggerIcon {
      svg {
        flex-shrink: 0;
        width: 23px !important;
        height: 23px !important;
        margin-right: -3px;
      }
    }
  }

  .pillbox {
    @include body-s-regular;
    color: $gray-800;
    border: 1px solid $gray-200;
    padding: 4px 12px;
    border-radius: 100px;
    width: 138px;
    text-align: center;

    a:visited {
      color: inherit;
    }
  }
}
