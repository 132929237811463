@import 'intakeOptimization/theme/typography.scss';
@import 'intakeOptimization/theme/spacing.scss';
@import 'intakeOptimization/theme/colors.scss';
@import 'intakeOptimization/theme/responsive.scss';

.card {
    display:none;

    @include screen('tablet-lg') {
      border: 1px dashed $gray-400;
      background-color: $gray-0;
      margin-bottom: $spacing-07;
      display: flex;
      box-sizing: border-box;
      border-radius: $spacing-03;

      .cloud{
          margin-top: $spacing-09;
          margin-right: $spacing-06;
          margin-left: $spacing-07;
      }
    }

    .content{
        .cardTitle{
          @include body-s-medium;
          color: $gray-800;
          padding-top: $spacing-07;
          padding-bottom: $spacing-02;
        }

      .instructions{
        @include body-xs-regular;
        padding-bottom: $spacing-07;
        }
    }

    .link{
      @include body-xs-medium;
      color: $azure-700;
    }

    .link:hover {
      cursor: pointer;
    }
}

.dragOver {
  background-color: $regal-50;
  border: 1px dashed $azure-700;
}

.cardContainerMobile{
  width: 47%;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  cursor: pointer;

  @include screen('tablet-lg') {
    display: none;
  }

  .cardMobile{
    display: flex;
    height: calc(47vw - $spacing-07);
    width: 100%;
    justify-content: center;
    align-items: center;
    border: 1px dashed $gray-400;
    background-color: $gray-0;
    box-sizing: border-box;
    border-radius: $spacing-03;

    @include screen('tablet-sm') {
      height: 134px;
    }

    .camera {
      width: 100%;
      text-align: center;

      .miniature {
        max-width: 100%;
        max-height: calc(#{$spacing-12} - #{$spacing-01});
        min-height: calc(#{$spacing-12} - #{$spacing-01});
        border-radius: $spacing-03;
        vertical-align: middle;

        @include screen('tablet-sm'){
          min-height: 134px;
          height: 134px;
        }
      }
    }
  }

  .cardTitleMobile{
    @include body-xs-medium;
    text-align: center;
    padding-top: $spacing-04;
  }
}

.cardContainerMobile ~ .cardContainerMobile {
  margin-left: 6%;
}

:global(input.cr-slider[type="range"]::-webkit-slider-runnable-track) {
    background: #ffffff !important;
}
