@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/colors.scss';
@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/responsive.scss';

.mainContainer {
  position: sticky;
  right: 0;
  bottom: 0;
  @include screen('desktop-sm') {
  }

  .bubble {
    margin: 0 $spacing-07 $spacing-07 auto;
    height: calc(#{$spacing-04} + #{$spacing-10});
    width: calc(#{$spacing-04} + #{$spacing-10});
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: calc(#{$spacing-02} + #{$spacing-12});
    background-color: $gray-800;
    box-shadow: 0px $spacing-02 $spacing-02 rgba(0, 0, 0, 0.25);
    transition: 0.2s;

    @include screen('tablet-lg') {
      height: calc(#{$spacing-05} + #{$spacing-11});
      width: calc(#{$spacing-05} + #{$spacing-11});
      margin-top: auto;

      svg {
        height: 26.67px;
        width: 26.67px;
      }

      .closeChatIcon {
        height: $spacing-05;
        width: $spacing-05;
      }
    }

    &:active {
      transform: scale(0.95);
    }
  }
}

.withFooter {
  @include screen('tablet-lg') {
    bottom: calc(#{$spacing-02} + #{$spacing-12});
    right: 0;
  }
}
