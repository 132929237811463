@import "lifestance-ui/theme/spacing.scss";
@import "lifestance-ui/theme/responsive.scss";
@import "lifestance-ui/theme/typography.scss";
@import "lifestance-ui/theme/colors.scss";

.scrim {
  background: rgba(51, 51, 51, 0.43);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  @include screen(tablet-md) {
    display: none;
  }
}


.checkbox {
  min-width: $spacing-06;
  width: $spacing-06;
  height: $spacing-06;
  border: 1px solid $gray-300;
  border-radius: $spacing-02;
  margin-right: $spacing-05;
  cursor: pointer;
  position: relative;

  &:after {
    content: "";
    width: $spacing-03;
    height: $spacing-02;
    position: absolute;
    top: $spacing-02;
    left: $spacing-02;
    border: 3px solid $white;
    border-top: none;
    border-right: none;
    background: transparent;
    opacity: 0;
    transform: rotate(-45deg);
  }
  &:hover::after {
    opacity: 0.5;
  }
}

.checked {
  background: $azure-700;

  &:after {
    opacity: 1;
  }
}

.wrapper {
  display: block;
  position: fixed;
  width: 100%;
  background-color: $white;
  z-index: 10;
  top: 120vh;
  left: 0;
  transition: all 0.3s ease;
  overflow: scroll;
  border-radius: $spacing-06 $spacing-06 0 0;

  &.open {
    top: $spacing-09;
    bottom: 0;
    box-shadow: 0px $spacing-03 $spacing-07 rgb(0 0 0 / 12%);
    -webkit-overflow-scrolling: touch;

    @include screen("tablet-lg") {
      top: 0px;
    }

    &.sideWays {
      top: $spacing-09;
      animation: slidein-from-left2 500ms;
      transition: none;
      @keyframes slidein-from-left2 {
        0% {
          transform: translateY(100vh);
        }
        100% {
          transform: translateY(0px);
        }
      }
    }

    .stickyButton {
      position: fixed;
      padding: $spacing-06 $spacing-07;
      bottom: 0%;
      z-index: 1;
      background-color: $white;
      width: 100%;
      box-sizing: border-box;
      border-top: 1px solid $gray-200;
      -webkit-overflow-scrolling: touch;
    }

    .stickyButtoniOS {
      padding: $spacing-06 $spacing-07 $spacing-06 !important;
    }
  }

  .top {
    @include body-s-regular;
    padding: $spacing-07 $spacing-08;
    color: $gray-800;
    border-bottom: 1px solid $gray-200;

    .handleClose {
      position: absolute;
      top: $spacing-07;
    }

    .title {
      display: block;
      text-align: center;
    }
  }

  .options {
    margin-bottom: 20rem;

    .label {
      @include body-s-medium;
      margin-left: $spacing-07;
      margin-top: $spacing-08;
    }
  }

  .optionsiOS {
    margin-bottom: 30rem;
  }

  .new {
    margin-right: $spacing-01;
  }

  .item {
    @include body-s-regular;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $black;
    background: $white;
    padding: $spacing-06 0;
    border-bottom: 1px solid $gray-200;
    margin: 0 $spacing-07;
    max-height: calc(#{$spacing-06} + #{$spacing-11});
    box-sizing: border-box;

    &:first-child {
      margin-top: $spacing-03;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  .lastItem {
    border-bottom: 0;
  }

  .selection {
    justify-content: space-between;
    display: flex;
    @include body-s-medium;
  }

  .unsure {
    @include body-s-medium;
    color: $azure-700;
    justify-content: space-between;
    margin: $spacing-06 $spacing-07;
    display: flex;
  }

  @include screen("tablet-lg") {
    display: none;
  }
}
