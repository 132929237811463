@import 'intakeOptimization/theme/typography.scss';
@import 'intakeOptimization/theme/spacing.scss';
@import 'intakeOptimization/theme/colors.scss';


.container {

  label {
    display: flex !important;
    align-items: center;
    margin: 0 !important;
    cursor: pointer;
  }

  input[type="radio" i] {
    width: $spacing-06;
    margin-right: $spacing-05;
    height: $spacing-06;
    cursor: pointer;

    &:active {
      color: $azure-700;
    }
  }

  .optionsContainer {
    z-index: 99;
    background: $white;
    box-shadow: 0px $spacing-03 $spacing-07 rgb(0 0 0 / 9%);
    border-radius: $spacing-03;
    display: none;
    position: absolute;
    padding: $spacing-07 0;
    flex-direction: column;
    margin-top: $spacing-03;
    box-sizing: border-box;
    width: 291px;

    @include screen('tablet-md') {
      display: flex;
    }


    .content {
      overflow-y: auto;
    }

    .footer {
      display: flex;
      padding-top: $spacing-06;
      padding-left: $spacing-07;
      padding-right: $spacing-07;
      margin-top: calc(#{$spacing-01} + #{$spacing-03});
      border-top: 1px solid $gray-300;
      justify-content: flex-end;
    }

    .option {
      margin: 0;
      padding: $spacing-03 $spacing-07 !important;
      border-radius: 0 !important;
      border: none;
      @include body-s-regular;
      margin: 0;
      
      &:hover {
        background-color: $regal-100;
      }
    }
  }
}

.large {
  span {
    @include font-utopia;
    font-size: 28px;
  }
}

.distanceFilter {
  outline: none;
  color: $azure-700;
  cursor: pointer;

  & .text{
    box-shadow: 0px $spacing-01 $gray-800
  }

  .mobileText {
    @include body-xs-medium;
  }
}

.arrowButton {
  @include title-m-regular;
  color: $azure-700;

  @include screen('tablet-sm'){
    cursor: pointer !important;
    @include title-m-regular;
    margin-top: $spacing-02;

    svg {
      display: inline-block;
      vertical-align: middle;
      margin-left: $spacing-02;
    }

    @include screen('tablet-sm') {

      &:hover {
        color: $azure-900 !important;

        svg {
          path {
            fill: $azure-900 !important;
          }
        }
      }

      svg {
        display: inline-block;
        vertical-align: middle;
        margin-left: $spacing-02;
      }
    }
  }
}


.arrowButtonUp {
  @include title-m-regular;
  color: $azure-700;
  cursor: pointer !important;
  svg {
    display: inline-block;
    vertical-align: middle;
    margin-left: $spacing-02;
    transform: rotate(180deg);
  }

  @include screen('tablet-sm'){
    @include title-m-regular;
  }
}

.mobilefilter {
  &>div>div>div:nth-child(2) {
    padding: 0 $spacing-07;
    margin-bottom: 5rem;

    &>div {
      border-radius: 0;
      padding-left: 0;
    }
  }
  &>div>div>div:nth-child(3) {
    border-top: none !important;
  }
}
