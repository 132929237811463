@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/colors.scss';

.container {
	width: 100%;
	background: $white;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;

	height: 75vh;

	display: flow-root;

	@include screen('tablet-lg'){
		width: 80%;
		padding: 0;
		height: fit-content;
		overflow: inherit;
	}
}

.scrollable {
	overflow-y: scroll;
	overflow-x: hidden;

	@include screen('tablet-md') {
		overflow-y: visible;
		overflow-x: visible;
	}
}
