@import "patientOnboarding/theme/typography.scss";
@import "patientOnboarding/theme/spacing.scss";
@import "patientOnboarding/theme/colors.scss";

.header {

  .head {
    @include title-l-semibold;
    color: $gray-800;
    margin-bottom: $spacing-02;
  }

  .subhead {
    @include body-s-regular;
    color: $gray-800;
  }
}

.options {
    @include body-s-regular;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    color: $black;
    background: $white;
    padding: calc($spacing-07 + $spacing-02) 0;
    border-bottom: 1px solid $gray-200;
    margin: 0;
    box-sizing: border-box;

    &:first-child {
      margin-top: $spacing-06;
    }

    &:last-child {
      border-bottom: 0;
    }

  &.disabledItem {
    color: $gray-400;
  }
}

.selection {
  justify-content: space-between;
  display: flex;
  @include body-s-medium;
}

.noInsurance {
  color: $azure-700;
  @include body-s-medium;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: $spacing-08 0;
  margin: 0;
  max-height: calc(#{$spacing-06} + #{$spacing-11});
  box-sizing: border-box;

}

.modalContainer {
  padding-left: $spacing-07;
  padding-right: $spacing-07;
  padding-bottom: $spacing-11;

  .modalTitle {
    @include title-s-semibold;
    text-align: center;
    padding-bottom: $spacing-04;
    padding-top: $spacing-08;
    top: 50px;

    @include screen('tablet-sm') {
      @include title-xl-regular;
      text-align: center;
      padding-bottom: $spacing-05;
      padding-top: 0px;
    }
  }

  .modalContent {
    @include body-s-regular;
    padding-bottom: $spacing-08;
    text-align: center;
    width: 100%;
  }
  .phone {
    @include body-xs-medium;
    text-decoration: none;
    color: $azure-700;
  }

  .information {
    margin-top: $spacing-07;
    @include body-xs-regular;
    color: $gray-500;
  }
}

.mainContainer {
  width: auto !important;

  @include screen('tablet-sm') {
    width: auto !important;
    margin-top: $spacing-09 !important;
  }
}

.toast {
  margin-top: $spacing-07;
  position: relative;
  visibility: hidden;
  top: -$spacing-07;
  left: 0px;
  opacity: 0;
  transform: translateY(0);
  height: 0px;
  padding: 0px $spacing-06;
  transition: all 0.3s ease;
}

.showToast {
  visibility: visible;
  opacity: 1;
  height: auto;
  padding: $spacing-07 $spacing-06;
  transform: translateY($spacing-07);
}
