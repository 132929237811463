@import "intakeOptimization/theme/spacing.scss";
@import "intakeOptimization/theme/responsive.scss";
@import "intakeOptimization/theme/typography.scss";
@import "intakeOptimization/theme/colors.scss";

.overlayRef {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 3;

  .overlay {
    z-index: 4;
    position: absolute;
    opacity: 0;
    min-height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
  }
}

.optionsContainer {
  // Relates to DropdownTypeAhead/Input
  z-index: 4;

  .options {
    h3 {
      color: $black;
      @include body-s-medium;
      margin-bottom: $spacing-02;
      padding-left: $spacing-07;
      margin-top: $spacing-05;
    }

    .option {
      @include body-s-regular;
      padding: $spacing-03 $spacing-06;
      display: flex;
      align-items: flex-start;
    
      .label {
        cursor: pointer;
        user-select: none;
        color: $black;
      }

      &:hover {
        cursor: pointer;
        background-color: $regal-100;
      }
    
      &.focused,
      &:focus {
        background-color: $regal-100;
        outline: none;
      }
    }

    .selected {
      background-color: $regal-100;
      outline: none;
    }
  }

  @include screen("tablet-md") {
    background: $white;
    box-shadow: 0px $spacing-03 $spacing-07 rgb(0 0 0 / 12%);
    border-radius: $spacing-03;
    display: flex;
    position: absolute;
    padding: 0;
    flex-direction: column;
    margin-top: $spacing-03;
    width: 100%;
    box-sizing: border-box;
    max-height: calc(#{$spacing-06} + #{$spacing-22});
    overflow-y: scroll;
    visibility: visible;
    transform: translateY(0);
    border: 1px solid #e3e3e3;
  }

  @include screen("tablet-lg") {
    box-shadow: 0px $spacing-03 $spacing-07 rgb(0 0 0 / 9%);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: $spacing-03;
    padding-bottom: $spacing-03;
  }
}

.noResults {
  display: flex;
  justify-content: center;
  align-items: center;
  @include body-s-regular;
  color: $gray-500;
  padding: $spacing-05 0 $spacing-05;
}
