@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/colors.scss';

.container {
    align-items: center;
    margin-top: $spacing-12;
    text-align: center;
    color: $gray-800;
    display: flex;
    flex-direction: column;
    align-items: center;

    .errorLabel {
        @include body-xxs-bold;
    }

    .padded {
        margin-top: $spacing-03;
        margin-bottom: $spacing-03;
    }

    .rounded {
        background: #FCCA48;
        width: calc(#{$spacing-17} - #{$spacing-06});
        height: calc(#{$spacing-17} - #{$spacing-06});
        border-radius: 20rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: $spacing-08;
    }

    .textContainer {
        max-width:  calc(#{$spacing-22} + #{$spacing-05});
    }

    .phone {
        color: $azure-700;
        @include body-s-medium;
        text-decoration: none;
        display: inline-block;

        @include screen('tablet-lg') {
            color: $gray-800;
        }
    }

    .showPhone {
        opacity: 1;
        transition: 1s;
    }

    .hidePhone {
        opacity: 0;
    }

    .newLine {
        display: block;
    }

    .button {
        margin-top: $spacing-10;

        @include screen('tablet-lg') {
            margin-top: $spacing-11;
        }
    }


    h2 {
        @include title-xl-regular;
    }
    p {
        @include body-s-regular;
    }


}
