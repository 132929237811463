$spacing-01: 0.125rem;  // 2px
$spacing-02: 0.25rem;   // 4px
$spacing-03: 0.5rem;    // 8px
$spacing-04: 0.75rem;   // 12px
$spacing-05: 1rem;      // 16px
$spacing-06: 1.25rem;   // 20px
$spacing-07: 1.5rem;    // 24px
$spacing-08: 2rem;      // 32px
$spacing-09: 2.5rem;    // 40px
$spacing-10: 3rem;      // 48px
$spacing-11: 4rem;      // 64px
$spacing-12: 6rem;      // 96px
$spacing-13: 8rem;      // 128px
$spacing-14: 10rem;      // 160px
$spacing-15: 12.25rem;  //196px
$spacing-16: 14rem;     //224px
$spacing-17: 15rem;     //240px
$spacing-18: 16rem;   //256px
$spacing-19: 17.5rem;   //280px
$spacing-20: 20rem;     //320px
$spacing-21: 22.5rem;   //360px
$spacing-22: 25rem;     //400px

