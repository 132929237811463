@import "patientOnboarding/theme/spacing.scss";
@import "patientOnboarding/theme/responsive.scss";
@import "patientOnboarding/theme/typography.scss";
@import "patientOnboarding/theme/colors.scss";

.container {
	background-color: $white;
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 1px solid $gray-200;
	border-radius: $spacing-03;

	// REF-1 Adding an extra 16px paddin in the bottom for consistency.
	padding-bottom: $spacing-05;

	@include screen('tablet-md') {
		border-radius: unset;
		border: none;
	}

	.icon {
		margin-top: $spacing-10;
		margin-bottom: $spacing-08;

		@include screen('tablet-md') {
			margin-top: $spacing-10;
			margin-bottom: $spacing-09;
		}
	}

	.titleContainer {
		width: 100%;
		padding: 0px $spacing-07;
		box-sizing: border-box;

		.title {
			@include title-m-medium;
			text-align: center;
		}
	}


	.description {
		@include body-s-regular;
		margin-top: $spacing-02;
		// For adjusting to always 40 pixels we are leaving this as 24px and in container adding a 16px padding in the bottom. Refer to REF-1
		margin-bottom: $spacing-07;
		padding-left: $spacing-07;
		padding-right: $spacing-07;
		max-width: 439px;
		text-align: center;
		
		.contactNumber {
			@include body-s-medium;
			color: $gray-800;
		}
			
		@include screen('tablet-md') {
			padding-left: unset;
			padding-right: unset;
		}
	}

	.button {
		// For adjusting to always 40 pixels we are leaving this as 24px and in container adding a 16px padding in the bottom. Refer to REF-1
		margin-bottom: $spacing-07;
		color: $gray-800;
		cursor: pointer;
	}
}

.noResults {
	margin-top: $spacing-09;

	@include screen('tablet-md') {
		margin-top: $spacing-05;
	}
}
