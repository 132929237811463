@import 'lifestance-ui/theme/typography.scss';
@import 'lifestance-ui/theme/spacing.scss';
@import 'lifestance-ui/theme/colors.scss';

.container {
	border-radius: $spacing-03;
	background-color: $gray-50;
	
	.title {
		@include body-s-medium;
		display: flex;
		padding: calc(#{$spacing-01} + #{$spacing-04}) $spacing-07;
		justify-content: space-between;
		align-items: center;
		border-radius: $spacing-03;
		background-color: $gray-50;
		cursor: pointer;
	}
	.content {
		@include body-s-regular;
		padding: 0px $spacing-07 $spacing-07 $spacing-07;
	}
}