@import 'intakeOptimization/theme/typography.scss';
@import 'intakeOptimization/theme/spacing.scss';
@import 'intakeOptimization/theme/colors.scss';
@import 'intakeOptimization/theme/responsive.scss';

.expertiseAreas{
    margin-bottom: $spacing-10;

    .nodata {
        margin-bottom: 0;
    }

    .heading{
        margin-bottom: $spacing-07;
        @include S-semibold;
    }

    .card {
        border: 1px solid $gray-200;
        border-radius: $spacing-04;
        overflow: hidden;
        margin-bottom: $spacing-10;

        .cardHeader {
            @include body-s-medium;
            background: $gray-0;
            padding: $spacing-06 $spacing-08 $spacing-04;
        }
    }

    .areas{
        display: block;
        padding: $spacing-07 $spacing-08;

        @include screen('tablet-sm') {
            flex-wrap: wrap;
            display: flex;
            column-count: 2;
        }




        .area {
            margin-bottom: $spacing-03;
            display: flex;
            align-items: flex-start;
            width: 100%;

            .vector {
                display: inline-block;
                margin-left: 0px;
                /* 5px */
                padding-top: calc(#{$spacing-02} + 0.063rem);
            }

            div {
                display: inline-block;
                @include body-s-regular;
                margin-left: $spacing-05;
            }

            @include screen('tablet-sm') {
                margin-bottom: $spacing-03;
                display: flex;
                width: 50%;

                .vector {
                    display: inline-block;
                    min-width: 18px;
                }

                div {
                    display: inline-block;
                    @include body-s-regular;
                    margin-left: $spacing-05;
                }
            }
        }
        .oneRow {

            @include screen('tablet-sm') {
                width: 100%;
            }
        }
    }
    .fewItems {

        @include screen('tablet-sm') {
            column-count: 1;
        }
    }
}

