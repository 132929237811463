@import 'lifestance-ui/theme/spacing.scss';
@import 'lifestance-ui/theme/colors.scss';
@import 'lifestance-ui/theme/typography.scss';

.area {
  @include body-s-medium;
  width: 100%;
  height: calc(#{$spacing-05} + #{$spacing-13});
  background: $white;
  border: 1px solid #d1d1d1;
  box-sizing: border-box;
  border-radius: $spacing-03;
  font-style: normal;
  font-weight: normal;
  font-size: $spacing-05;
  outline: none;
  padding-left: $spacing-05;
  padding-top: calc(#{$spacing-01} + #{$spacing-04});
  resize: none;

  &::value {
    color: $gray-800;
  }

  &::placeholder {
    color: $gray-400;
  }
}
