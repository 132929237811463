@import 'lifestance-ui/theme/typography.scss';
@import 'lifestance-ui/theme/spacing.scss';
@import 'lifestance-ui/theme/colors.scss';

.tooltipWrapper {
  display: inline-block;
  position: relative;

  &:hover .legend, &:hover .withTriangle {
    visibility: visible;
    opacity: 1;
    // transition: opacity 200ms ease-in, visibility 0ms ease-in 200ms;
    transition-delay: 0.15s
  }
}

.tooltip {
  position: absolute;
  top: 0px;
  right: 0px;

  .legend, .withTriangle  {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: opacity 200ms ease-in, visibility 0ms ease-in 0ms;
    margin-top: calc(#{$spacing-01} + #{$spacing-03});
    left: 50%;
    transform: translate(-50%, 0);
    background-color: $regal-900;
    color: #fff;
    @include body-xs-regular;
    border-radius: $spacing-03;
    padding: $spacing-05;
    width: max-content; /* change this for max-content or maybe a props param */
  }

  .withTriangle::before {
    content: " ";
    border: solid transparent;
    height: 0;
    width: 0;
    transform: rotate(45deg);
    background-color: $regal-900;
    position: absolute;
    pointer-events: none;
    left: -6px;
    border-width: $spacing-02;
    margin-left: 50%;
    top: -5px;
  }

  .legendUp {
    box-sizing: border-box;
    position: absolute;
    visibility: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: calc(#{$spacing-03} + #{$spacing-01});
    opacity: 0;
    transition: opacity 200ms ease-in, visibility 0ms ease-in 0ms;
    bottom: $spacing-03;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 0px;
    background-color: $regal-900;
    color: $white;
    @include body-xs-regular;
    border-radius: $spacing-03;
    padding: $spacing-05;
    width: calc(#{$spacing-18} + #{$spacing-02} + #{$spacing-01});
  }
}
