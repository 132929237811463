$azure-900: #0F2945;
$azure-800: #004fb1;
$azure-700: #0067ce;

$regal-900: #00427A;
$regal-800: #3B79B9;
$regal-700: #458ccb;
$regal-600: #4d99d8;
$regal-500: #60a7dd;
$regal-400: #78b7e2;
$regal-300: #9acbeb;
$regal-200: #c0dff3;
$regal-100: #e5f2fa;
$regal-50: #f0f9ff;
$regal-0: #f6fcff;

$casal-900: #17444a;
$casal-800: #215759;
$casal-700: #2e6865;
$casal-600: #50877c;
$casal-500: #569489;
$casal-400: #5fa49a;
$casal-300: #72b4ab;
$casal-200: #94cac3;
$casal-100: #bcdeda;
$casal-50: #e2efee;
$casal-0: #f1f8f8;

$sienna-900: #66050d;
$sienna-800: #9a180f;
$sienna-700: #b82c15;
$sienna-600: #d6451e;
$sienna-500: #df7153;
$sienna-400: #eb9780;
$sienna-300: #f4b8a8;
$sienna-200: #f9d3c8;
$sienna-100: #fce6e0;
$sienna-50: #f7ede9;
$sienna-0: #fff9f6;

$gold-900: #331806;
$gold-800: #703b0e;
$gold-700: #a05d15;
$gold-600: #c47f1a;
$gold-500: #dea01e;
$gold-400: #e5b751;
$gold-300: #eccf7d;
$gold-200: #f1e2a2;
$gold-100: #f6f0c3;
$gold-50: #fbf9e3;
$gold-0: #fffef1;

$pale-orange: #ffe6c6;
$beige: #fff2de;
$blush: #f7ede9;

$lilac: #f6f9ff;
$breeze: #f3f8f9;
$freesia: #e8e5eb;

$gray-900: #131313;
$gray-800: #333333;
$gray-700: #515151;
$gray-600: #646464;
$gray-500: #8b8b8b;
$gray-400: #acacac;
$gray-300: #d1d1d1;
$gray-200: #e3e3e3;
$gray-100: #eeeeee;
$gray-50: #f7f7f7;
$gray-0: #fbfbfb;

$white: #ffffff;
$black: #333333;

$modal-overlay: rgba(0, 0, 0, 0.5);
