@import 'lifestance-ui/theme/typography.scss';
@import 'lifestance-ui/theme/spacing.scss';
@import 'lifestance-ui/theme/colors.scss';

.tag {
  @include body-xs-regular;
  background: $regal-800;
  border-radius: $spacing-02;
  margin: $spacing-03 $spacing-03 $spacing-03 0;
  color: $white;
}
