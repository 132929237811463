@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/colors.scss';
@import 'patientOnboarding/theme/responsive';

.container {
  border-radius: $spacing-03;
  background-color: #fff;
  padding: calc(#{$spacing-13} - #{$spacing-05}) $spacing-07 $spacing-13 $spacing-07;
  min-height: 100vh;
  box-sizing: border-box;

  @include screen('tablet-sm') {
    width: calc(#{$spacing-06} + #{$spacing-20});
    margin: 0 auto;
  }

  @include screen('tablet-lg') {
    padding: $spacing-11;
    width: auto;
    min-height: initial;
  }

  .appointmentsContainer {
    .loading {
      display: none;
    }
  }

  @include screen('tablet-sm') { // Hidding everything for the new multiple locations Dropdown
    .headingDesktop,
    .title, 
    .description,
    .coldLanding {
      display: none;
    }
    
    .multipleLocationsHeader {
      display:block;
      .headerM {
        .titleM {
          @include body-xxs-bold;
          margin-bottom: $spacing-04;
        }

        .descriptionM {
          @include title-xl-regular;
        }
      }
    }

    .multipleLocationContainer {
      width: 348px;
      padding: $spacing-06 0px;
    }
  }

  .appointmentsContainer {
    .loading {
      display: block;
      position: absolute;
      right: 217px;
      bottom: 292px;
      transform: scale(2);
    }
  }

  .lowOpacity {
    opacity: 0.3;
  }
}

.coldLanding {
  .coldTitle {
    @include body-xxs-bold;
    margin-bottom: $spacing-04;
  }

  .coldDescription {
    @include title-l-semibold;
    margin-bottom: $spacing-05;
  }
}

.headingMobile {
    @include screen('tablet-lg') {
      display: none;
    }

    @include screen('tablet-sm') {
      text-align: center;
    }
}

.headingDesktop {
    display: none;
    @include screen('tablet-lg') {
        display: block;
    }
}

.title {
  @include title-xl-regular;
  margin-bottom: $spacing-02;
}

.description {
  @include body-s-regular;

  @include screen('tablet-sm') {
    text-align: center;
  }

  @include screen('tablet-lg') {
    text-align: left;
  }
}

.content {
  display: flex;
  margin-top: $spacing-05;
  flex-direction: column;

  @include screen('tablet-lg') {
      flex-direction: row;
  }

  .windowVisible {
      display: block !important;
  }
}

.appointmentType {
  .dropdownContainer {
    margin-bottom: $spacing-08;

    .reason {
      @include body-s-medium;
      color: $gray-800;
      margin-bottom: $spacing-03;
    }
  }
  .appointmentNote {
    position: relative;
    background-color: #F7FCFF;
    padding: $spacing-04;
  }
  @include screen('tablet-lg') {
    display: none;
  }
  .appointmentEdit {
    position: absolute;
    top: $spacing-04;
    right: $spacing-04;
    text-decoration: underline;
    @include body-s-regular;
    color: #ACACAC;
    cursor: pointer;
  }

  .appointmentTypeTitle {
    @include body-s-medium;
    margin-bottom: $spacing-02;
  }

  .appointmentTypeDesc {
    @include body-s-regular;
  }

  .appointmentCategories {
    margin: $spacing-07 0 $spacing-09;
  }

  .appointmentCategory {
    display: flex;
    justify-content: space-between;
    @include body-s-regular;
    margin-bottom: $spacing-07;
  }

  .option {
    @include body-s-regular;
    display: flex;
    align-items: center;

    &:nth-child(n + 7) {
      display: none;
    }

    label {
      display: flex;
      cursor: pointer;
      user-select: none;
    }

    span {
      color: #333;
    }

    .checkbox {
      width: $spacing-06;
      height: $spacing-06;
      border: 1px solid #c3c3c3;
      border-radius: $spacing-02;
      cursor: pointer;
      position: relative;
      &:after {
        content: '';
        width: 9px;
        height: 5px;
        position: absolute;
        top: $spacing-02;
        left: $spacing-02;
        border: 3px solid #fff;
        border-top: none;
        border-right: none;
        background: transparent;
        opacity: 0;
        transform: rotate(-45deg);
      }
      &:hover::after {
        opacity: 0.5;
      }
    }

    input[type='checkbox'] {
      visibility: hidden;
      margin-left: -13px;
      transform: scale(0);
      position: absolute;

      &:checked + label > div {
        background: $azure-700;
      }
      &:checked + label > div:after {
        opacity: 1;
      }
    }

    span {
      min-width: max-content;
    }
  }
}

.chosenDate {
    @include body-s-medium;
    margin: 0 0 $spacing-05;
}

.calendar {
  background-color: #fff;
  display: none;

   @include screen('tablet-lg') {
     margin-right: $spacing-08;
     min-width: calc(#{$spacing-05} + #{$spacing-20});
     position: relative;
  }

}

.appointmentsContainer {
  display: none;

  @include screen('tablet-lg') {
    display: block;
    min-width: calc(#{$spacing-06} + #{$spacing-19});
  }
}

.buttonsDesktop {
  display: none;
  margin-top: $spacing-10;
  justify-content: flex-end;

  @include screen('tablet-lg') {
    display: flex;
  }

  button {
    margin: 0 !important;
  }

  button:first-child{
    margin-right: $spacing-05 !important;
  }
}

.modalityIcon {
  display: inline-block;
  margin-right: 18px;
  vertical-align: middle;
}

.footer {
  display: none;

  @include screen('phone') {
    display: block;
  }

  @include screen('tablet-md') {
    display: none;
  }
}
