@import "patientOnboarding/theme/typography.scss";
@import "patientOnboarding/theme/spacing.scss";
@import "patientOnboarding/theme/colors.scss";
@import "patientOnboarding/theme/responsive.scss";

.divider {
  margin: $spacing-08 0;
  height: 1px;
  width: 100%;
  background-color: $gray-200;
}

.header {
  @include title-s-regular;
}

.formLabel {
  @include body-s-medium;
  margin-top: $spacing-08;
  margin-bottom: $spacing-03;
  display: block;
}

.input {
  @include body-s-medium;
  padding: $spacing-05;
  width: 100%;
  height: 52px;
  background: $white;
  border: 1px solid $gray-300;
  box-sizing: border-box;
  border-radius: $spacing-03;
  color: $gray-800;
  font-style: normal;
  font-weight: normal;
  font-size: $spacing-05;
  line-height: $spacing-07;

  &::placeholder {
    color: $gray-400;
  }

  &:focus {
    outline: none;
    border: 1px solid $gray-400;
  }
}

.checkbox {
  margin-top: $spacing-08;
}

.error {
  @include body-xs-regular;
  margin-top: $spacing-03;
  color: $sienna-600;
}
