@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/colors.scss';

.availability {
  width: 100%;
  margin-bottom: $spacing-09;

  @include screen('tablet-lg') {
    display: inline-block;
    max-width: $spacing-22;
    vertical-align: top;
    padding: $spacing-08 $spacing-07;
    background: #ffffff;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: $spacing-03;
  }

  .heading {
    @include S-semibold;
    margin-bottom: $spacing-02;
  }
  .headingmore {
    @include body-s-regular;
    margin-bottom: $spacing-05;
  }
  .appointmenttype {
    display: none;

    @include screen('tablet-lg') {
      display: block;
      background: #f7fcff;
      border-radius: $spacing-02;
      padding: $spacing-04;
      margin-bottom: $spacing-07;
      @include body-s-regular;
    }

    .appointmenttypeContainer {
      margin-bottom: $spacing-02;

      .aheading {
        @include screen('tablet-lg') {
          @include body-s-medium;
          display: inline-block;
          color: $gray-800;
        }
      }
      .aedit {
        @include screen('tablet-lg') {
          @include body-s-regular;
          display: inline-block;
          float: right;
          text-align: right;
          text-decoration-line: underline;
          color: #acacac;
        }
      }
    }
  }
  .meetingOptions {
    display: none;

    @include screen('tablet-lg') {
      display: block;
      margin-bottom: $spacing-07;
      border-bottom: 1px solid #e3e3e3;

      div {
        margin-bottom: $spacing-03;
      }

      span {
        @include body-s-regular;
        margin-left: $spacing-03;
        vertical-align: top;
      }
      input {
        float: right;
      }
    }
  }
  .formInputs {
    margin-bottom: $spacing-06;
    .inputUnit {
      max-width: 417px;
      input {
        margin-bottom: $spacing-04;
        padding-left: $spacing-11;
      }
      svg {
        position: absolute;
        padding: $spacing-05;
        color: rgb(49, 0, 128);
        width: $spacing-07;
        height: $spacing-07;
        padding: $spacing-04 $spacing-05 $spacing-05 $spacing-06;
        text-align: left;
      }
    }
  }
  .book {
   
    @include screen('tablet-lg') {
      display: block;
      width: 100%;
      margin: auto;
      margin-top: $spacing-04;
    }
  }
  .link {
    text-decoration: none;
    color: $azure-700;
    @include body-s-medium;
    display: block;
    cursor: pointer;
    text-align: center;
    margin-bottom: $spacing-06;
    @include screen('tablet-lg') {
      text-align: center;
    }

    span {
      vertical-align: top;
      margin-left: $spacing-02;
    }
  }

  .appointmentOption {
    display: flex;
    justify-content: space-between;
  }

  label {
    display: flex;
    cursor: pointer;
    user-select: none;
  }

  .checkbox {
    width: $spacing-06;
    height: $spacing-06;
    border: 1px solid #c3c3c3;
    border-radius: $spacing-02;
    cursor: pointer;
    position: relative;

    &.checked {
      background: $azure-700;

      &:after {
        opacity: 1;
      }
    }

    &:after {
      content: '';
      width: 9px;
      height: 5px;
      position: absolute;
      top: $spacing-02;
      left: $spacing-02;
      border: 3px solid #fff;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
    }

    &:hover::after {
      opacity: 0.5;
    }
  }

  input[type='checkbox'] {
    visibility: hidden;
    margin-left: -13px;
    transform: scale(0);
    position: absolute;
  }
}

.appointment {
  background-color: #fff;
  border: 1px solid #d1d1d1;
  border-radius: $spacing-03;
  padding: $spacing-04 $spacing-06;
  margin-bottom: $spacing-04;
  @include body-s-regular;
  cursor: pointer;
  align-items: center;
  display: flex;

  &.selected {
    border: 2px solid $azure-700;
  }

  &.selectedAdmin {
    border: 1px solid #d1d1d1;
    background-color: #fff;
    cursor:auto;

    &:hover {
      border: 1px solid #d1d1d1;
      background-color: #fff;
    }
  }

  svg {
    margin-right: 18px;
  }

  &:hover {
    border: 2px solid $azure-700;
    background-color: $regal-0;
  }
}
