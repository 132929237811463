@import 'patientOnboarding/theme/reset.scss';
@import 'patientOnboarding/theme/colors.scss';
@import 'patientOnboarding/theme/responsive.scss';
@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/typography.scss';

.container {
	margin-left: $spacing-04;
	box-sizing: border-box;
	height: calc(#{$spacing-02} + #{$spacing-08});
	width: calc(100% - 459px);

	@include screen("tablet-sm") {
		margin-left: $spacing-07;
		display: flex;
		justify-content: flex-end;
		width: auto !important;
	}

	@include screen("tablet-md") {
		@include body-s-regular;
		display: flex;
		justify-content: flex-end;
		width: calc(100% - 390px);
	}

	@include screen("tablet-lg") {
		margin-left: auto;
		display: flex;
		justify-content: flex-end;
		width: calc(100% - 915px) !important;
	}

	@include screen("desktop-sm") {
		margin-left: auto;
		display: flex;
		justify-content: flex-end;
		width: calc(100% - 918px) !important;
	}

	.dropdown {
		border: 1px solid $gray-300;
		border-radius: $spacing-09;
		align-items: center;
		width: calc(#{$spacing-02} + #{$spacing-08});
		height: calc(#{$spacing-02} + #{$spacing-08});
		display: flex;
		box-sizing: border-box;
		justify-content: center;
		background-color: $white;

		svg {
			width: $spacing-06;
			height: $spacing-06;
		}

		@include screen("tablet-md") {
			padding: 0px $spacing-05;
			background-color: $white;
			width: auto;
			justify-content: unset;
		}

		@include screen("tablet-lg") {
			padding: 0px $spacing-03;
			background-color: $white;
			box-sizing: border-box;
			margin-bottom: $spacing-01;
			width: calc(#{$spacing-02} + #{$spacing-08});
		}

		@include screen("desktop-sm") {
			padding: 0px $spacing-05;
			background-color: $white;
			box-sizing: border-box;
			margin-left: $spacing-08;
			margin-bottom: $spacing-01;
			width: auto;
		}

		&:hover {
			cursor: pointer;
		}
		.value {
			@include body-xs-regular;
			display: none;
			@include screen("desktop-sm") {
				display: block;
				margin-left: $spacing-03;
			}
		}

		.sortText {
			display: none;

			@include screen("tablet-md") {
				display: block;
				margin-left: $spacing-03;
			}
			@include screen("tablet-lg") {
				display: none;
			}
		}
	}

	.dropdownAdmin {
		border: 1px solid $gray-300;

		svg {
			width: $spacing-05;
			height: $spacing-05;
		}
		@include screen("tablet-lg") {
			padding: 0px calc(#{$spacing-03} + 1px);
			width: auto;
		}
		@include screen('desktop-sm') {
			.value {
				display: none;
			}
		}
		@include screen('desktop-lg') {
			.value {
				display: block;
			}
		}
	}

	.dropdownOpen {
		background-color: $regal-0;
		border: $spacing-01 solid $azure-700;
		margin-bottom: 0;
	}


	.optionsContainer {
		display: none;

		@include screen("tablet-md") {
			display: block;
			box-sizing: border-box;
			margin-top: $spacing-10;
			width: $spacing-16;
			background-color: $white;
			position: absolute;
			z-index: 10;
			padding: $spacing-06 $spacing-04;
			box-shadow: 0px $spacing-02 $spacing-04 #00000014;
			right: 0;

			li {
				list-style: none;
				box-sizing: border-box;
				padding: $spacing-03 $spacing-05;
				border-radius: $spacing-03;
				margin-bottom: $spacing-02;

				&:hover,
				&:active,
				&.focused,
				&:focus {
					background-color: $gray-100;
					cursor: pointer;
				}
			}

			li:last-child {
				margin-bottom: 0px;
			}
		}
	}
}

.containerAdmin {
	@include screen("tablet-sm") {
		margin-left: $spacing-10;
	}

	@include screen("tablet-lg") {
		margin-left: auto;
	}
}
