@import 'lifestance-ui/theme/typography';
@import 'lifestance-ui/theme/spacing';
@import 'lifestance-ui/theme/colors.scss';

.input {
  @include body-s-medium;
  padding: $spacing-05;
  width: 100%;
  height: calc(#{$spacing-02} + #{$spacing-10});
  background: $white;
  border: 1px solid $gray-300;
  box-sizing: border-box;
  border-radius: $spacing-03;
  color: $gray-800;
  font-style: normal;
  font-weight: normal;
  font-size: $spacing-05;
  line-height: $spacing-07;

  &::placeholder {
    color: $gray-400;
  }

  &:focus {
    outline: none;
    border: 1px solid $gray-400;
  }

  &.error {
    border-color: $sienna-500;
  }

  &.caution{
    border-color: $gold-500;
  }
}

span.error {
    @include body-xs-regular;
    color: $sienna-600;
    margin-top: $spacing-03;
    display: block;
}

span.caution {
  @include body-xs-regular;
  color: $gold-600;
  margin-top: $spacing-03;
  display: block;
}

.adminInput {
  @include body-s-medium;
  padding: $spacing-05;
  height: calc(#{$spacing-02} + #{$spacing-10});
  background: $white;
  border: 1px solid $gray-300;
  box-sizing: border-box;
  border-radius: $spacing-03;
  color: $gray-800;
  font-style: normal;
  font-weight: normal;
  font-size: $spacing-05;
  line-height: $spacing-07;
  width: $spacing-21 !important;

  &::placeholder {
    color: $gray-400;
  }

  &:focus {
    outline: none;
    border: 1px solid $gray-500;
  }

  &:hover {
	  border: 1px solid $gray-500;
	}

  &.tabbed {
	  outline: $spacing-01 solid $gray-800;
	  outline-offset: $spacing-01;
	  border-radius: $spacing-02;
	}

  &.error {
    border-color: $sienna-600;
  }

@include screen('tablet-md'){
    border-radius: $spacing-03;
    padding: $spacing-05;
    width: 643px !important;
}

@include screen('tablet-lg'){
    border-radius: $spacing-03;
    padding: $spacing-05;
    width: 540px !important;
}

@include screen('desktop-sm'){
    border-radius: $spacing-03;
    padding: $spacing-05;
    width: 487px !important;
}

@include screen('desktop-lg'){
    border-radius: $spacing-03;
    padding: $spacing-05;
    width: 346px !important;
}
}

.tabbed:focus {
  outline: $spacing-01 solid $gray-800;
  outline-offset: $spacing-01;
}