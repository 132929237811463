@import "intakeOptimization/theme/spacing.scss";
@import "intakeOptimization/theme/responsive.scss";
@import "intakeOptimization/theme/typography.scss";
@import "intakeOptimization/theme/colors.scss";

.optionsContainer {
  .options {
    h3 {
      color: $black;
      @include body-s-medium;
      margin-bottom: $spacing-02;
      padding-left: $spacing-07;
      margin-top: $spacing-05;
    }

    .option {
      @include body-s-regular;
      padding: $spacing-03 $spacing-07;;
      display: flex;
      align-items: flex-start;

      &:first-child{
        padding: $spacing-05 $spacing-05 $spacing-03;
      }

      &:hover {
        cursor: pointer;
        background-color: $regal-100;
      }

      .label {
        cursor: pointer;
        user-select: none;
        padding-left: 16px;
        color: $black;
      }

      .checkbox {
        min-width: $spacing-06 !important;
        height: $spacing-06 !important;
        border: 1px solid $gray-300;
        border-radius: $spacing-02;
        margin-top: 2px;
        cursor: pointer;
        position: relative;
        box-sizing: border-box;

        &:after {
          content: "";
          width: $spacing-03;
          height: $spacing-02;
          position: absolute;
          top: calc(#{$spacing-02} + 1px);
          left: calc(#{$spacing-02} + 1px);
          border: 3px solid $white;
          border-top: none;
          border-right: none;
          background: transparent;
          opacity: 0;
          transform: rotate(-45deg);
        }
        &:hover::after {
          opacity: 0.5;
        }
      }

      &.focused,
      &:focus-visible {
        background-color: $regal-100;
        outline: none;
      }

      .checked {
        background: $azure-700;
        border: unset;

        &:after {
          opacity: 1;
        }
      }


      span {
        overflow-wrap: break-word;
        min-width: max-content;
        color: $gray-800;
      }
    }
  }

  .simpleOptions {
    .option {
      @include body-s-regular;
      padding: $spacing-03 $spacing-07;;
      display: flex;
      align-items: flex-start;

      &:hover {
        cursor: pointer;
        background-color: $regal-100;
      }

      .label {
        cursor: pointer;
        user-select: none;
        padding-left: $spacing-05;
        color: $black;
      }

      .checkbox {
        min-width: $spacing-06 !important;
        height: $spacing-06 !important;
        border: 1px solid $gray-300;
        border-radius: $spacing-02;
        margin-top: 2px;
        cursor: pointer;
        position: relative;
        box-sizing: border-box;

        &:after {
          content: "";
          width: $spacing-03;
          height: $spacing-02;
          position: absolute;
          top: calc(#{$spacing-02} + 1px);
          left: calc(#{$spacing-02} + 1px);
          border: 3px solid $white;
          border-top: none;
          border-right: none;
          background: transparent;
          opacity: 0;
          transform: rotate(-45deg);
        }
        &:hover::after {
          opacity: 0.5;
        }
      }

      &.focused,
      &:focus {
        background-color: $regal-100;
        outline: none;
      }

      .checked {
        background: $azure-700;
        border: unset;

        &:after {
          opacity: 1;
        }
      }


      span {
        overflow-wrap: break-word;
        min-width: max-content;
        color: $gray-800;
      }
    }
  }

  .selectedList{
    margin-bottom: $spacing-04;

    &:after {
      content: '';
      margin-left: $spacing-07;
      margin-right: $spacing-07;
      margin-top: $spacing-04;
      border-bottom: 1px solid $gray-200;
      width: calc(100% - $spacing-07 - $spacing-07);
      display: block;
    }
  }

  .filterOptions {
    h3 {
      padding-left: $spacing-07;
    }

    .option {
      padding-left: $spacing-07;
      padding-right: $spacing-07;
    }
  }

  .filterSelectedList {
    h3 {
      padding-left: $spacing-07;
    }

    &:after {
      content: '';
      margin-left: $spacing-07;
      margin-top: $spacing-04;
      border-bottom: 1px solid $gray-200;
      width: calc(100% - #{$spacing-10});
      display: block;
    }

  }

  @include screen("tablet-md") {
    background: $white;
    box-shadow: 0px $spacing-03 $spacing-07 rgb(0 0 0 / 12%);
    border-radius: $spacing-03;
    display: inline-block;
    position: absolute;
    padding: 0;
    flex-direction: column;
    margin-top: $spacing-03;
    width: 100%;
    box-sizing: border-box;
    z-index: 10;
    max-height: calc(#{$spacing-06} + #{$spacing-22});
    overflow-y: scroll;
    visibility: visible;
    transform: translateY(0);
    border: 1px solid #e3e3e3;
  }

  @include screen("tablet-lg") {
    box-shadow: 0px $spacing-03 $spacing-07 rgb(0 0 0 / 9%);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: $spacing-03;
  }
}

.filterOptionsContainer {
    background: $white;
    box-shadow: none ;
    display: inline-block;
    position: relative;
    padding: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 10;
    max-height: $spacing-22;
    overflow-y: scroll;
    visibility: visible;
    transform: translateY(0);
    border: none;
    margin-top: $spacing-04;

    li {
      @include body-s-regular;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $gray-800;

      &:hover,
      &:active,
      &.focused,
      &:focus {
        background-color: $regal-100;
        outline: none;
      }
    }
}


.footer {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  display: flex;
  padding: $spacing-06 $spacing-07;
  margin-top: calc(#{$spacing-01} + #{$spacing-03});
  justify-content: flex-end;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    border-top: 1px solid $gray-300;
    width: calc(100% - $spacing-07 - $spacing-07);
    left: $spacing-07;
  }

  .clearButton {
    padding: 0px $spacing-03;
      border-radius: $spacing-03;
      margin-right: $spacing-06;

      &:focus-visible {
        outline: $spacing-01 solid $gray-800;
        outline-offset: -$spacing-01;
      }
  }
}

.noResults {
  display: flex;
  justify-content: center;
  align-items: center;
  @include body-s-regular;
  color: $gray-500;
  padding: $spacing-05 0 $spacing-05;
}