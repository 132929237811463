@import "patientOnboarding/theme/typography.scss";
@import "patientOnboarding/theme/spacing.scss";
@import "patientOnboarding/theme/colors.scss";

.header {
	margin-bottom: $spacing-07;

	.head {
		@include title-l-semibold;
		color: $gray-800;
		margin-bottom: $spacing-02;
	}

	.subhead {
		@include body-s-regular;
		color: $gray-800;
	}
}

.option {
	display: flex;
	box-sizing: border-box;
	width: 100%;
	padding: calc(#{$spacing-04} + #{$spacing-01}) $spacing-06;
	align-items: flex-start;
	flex-wrap: wrap;
	border: 1px solid $gray-200;
	border-radius: $spacing-03;
	margin-bottom: $spacing-05;

	.title {
		@include body-s-medium;
		display: block;
		width: 100%;
		color: $gray-800;
	}

	.description {
		@include body-s-regular;
		width: 100%;
		overflow: hidden;

		transition: height 0.5s ease;
		-moz-transition: height 0.5s ease;
		-ms-transition: height 0.5s ease;
		-o-transition: height 0.5s ease;
		-webkit-transition: height 0.5s ease;
		
		b {
			font-weight: 500;
		}

	}

	.show {
		margin-top: $spacing-02;
	}
}

.selected {
	border: $spacing-01 solid $azure-700;
	background-color: $regal-0;
}

