@import "lifestance-ui/theme/typography.scss";
@import "lifestance-ui/theme/spacing.scss";
@import "lifestance-ui/theme/colors.scss";
@import "lifestance-ui/theme/responsive.scss";


.infoContainer{
    box-sizing: border-box;
    border-radius: $spacing-03;
    padding-top: $spacing-04;
    @include screen("tablet-sm") {
        margin: 0;
  }
}

.bullet{
    margin-bottom: $spacing-06;
    width: 100%;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 24px auto;
    column-gap: $spacing-04;
    @include screen("tablet-sm") {
        column-gap: $spacing-06;
    }

    svg{
        align-self: flex-start;
        margin-top: $spacing-02;
    }
}


.header{
    @include body-s-medium;
}

.text{
    @include body-s-regular;
    margin-top: $spacing-02;
    grid-column-start: 2;
    grid-column-end: 3;

    a{
        font-weight: unset;
        text-decoration: unset;
        color: $azure-700;
        @include screen("tablet-sm") {
          color: $black;
    }
    }

    .bold{
        @include body-s-medium;
        cursor: pointer;
    }
}