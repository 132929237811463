@import "lifestance-ui/theme/spacing.scss";
@import "lifestance-ui/theme/responsive.scss";
@import "lifestance-ui/theme/typography.scss";
@import "lifestance-ui/theme/colors.scss";

.dropdown {
  border: 1px solid $gray-300;
  border-radius: $spacing-03;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $gray-400;
  user-select: none;
  justify-content: space-between;
  box-sizing: border-box;
  padding: calc(#{$spacing-01} + #{$spacing-04}) $spacing-06;
  height: calc(#{$spacing-02} + #{$spacing-10});
  box-sizing: border-box;
  @include body-s-regular;

  &:focus {
    outline: none !important;
  }

  &::placeholder {
    color: $gray-400;
  }

  svg {
    margin-left: $spacing-03;
  }

  .options {
    border-radius: $spacing-03;
    color: $gray-800;
  }

  &.disabled {
    background-color: $gray-100;
    cursor: not-allowed;
  }

  &.selected {
    color: $gray-800;
  }
  .value {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.defaultOption {
  color: $gray-400;
}

.container {
  width: 100%;
  position: relative;

  &:focus, &:active {
    outline: none !important;

    & > .dropdown {
      border-color: $gray-500;
    }
  }
}

.optionsContainer {
  display: none;

  @include screen("tablet-md") {
    background: $white;
    box-shadow: 0px $spacing-03 $spacing-07 rgb(0 0 0 / 12%);
    border-radius: $spacing-03;
    display: inline-block;
    position: absolute;
    padding: 0;
    flex-direction: column;
    margin-top: $spacing-04;
    width: 100%;
    box-sizing: border-box;
    z-index: 10;
    max-height: calc(#{$spacing-06} + #{$spacing-19});
    overflow-y: scroll;
    visibility: visible;
    transform: translateY(0);
    border: 1px solid #e3e3e3;

    li {
      @include body-s-regular;
      padding-right: $spacing-07;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $gray-800;
      padding: $spacing-03 $spacing-06;



      &:hover,
      &:active,
      &.focused,
      &:focus {
        background-color: $regal-0;
        outline: none;
      }
    }
  }

  @include screen("tablet-lg") {
    background: $white;
    box-shadow: 0px $spacing-03 $spacing-07 rgb(0 0 0 / 9%);
    border-radius: $spacing-03;
    display: inline-block;
    position: absolute;
    padding: 0;
    flex-direction: column;
    margin-top: $spacing-04;
    width: 100%;
    box-sizing: border-box;
    z-index: 10;
    max-height: calc(#{$spacing-06} + #{$spacing-19});
    visibility: visible;
    transform: translateY(0);
    overflow: scroll;
    overflow-x: hidden;
    padding-top: $spacing-06;
    padding-bottom: $spacing-08;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    li {
      @include body-s-regular;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $gray-800;
      padding: $spacing-03 $spacing-06;

      &:hover,
      &:active,
      &:focus {
        background-color: $lilac;
      }
    }
  }
}

.search {
  border: 1px solid $gray-300;
  border-radius: $spacing-03;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $gray-400;
  user-select: none;
  justify-content: space-between;
  box-sizing: border-box;
  padding: calc(#{$spacing-01} + #{$spacing-04}) $spacing-06;
  height: calc(#{$spacing-02} + #{$spacing-10});
  box-sizing: border-box;
  @include body-s-regular;

}