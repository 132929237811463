@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/responsive.scss';
@import 'patientOnboarding/theme/colors.scss';
@import 'patientOnboarding/theme/typography.scss';

.header {
  padding: $spacing-05 0;
  display: flex;
  justify-content: center;

  @include screen('tablet-sm') {
    padding: $spacing-06 0;
  }

  @include screen('tablet-md') {
    display: block;
  }

  .headerContainer {
    width: 100%;
    position: relative;

    @include screen('tablet-md') {
      width: auto;
    }
  }

  .adminHeaderContainer {
    margin-left: 24px;
    margin-right: 24px;
    max-width: none;
  }

  .adminHeaderLogo {
    display: flex;
    justify-content: space-between;
    width: 97%;

    @include screen('tablet-md') {
      justify-content: flex-end;
    }

    .mobileLogo {
      display: flex;
    
      @include screen('tablet-md') {
        display: none;
        visibility: hidden;
      }
    }
  }

  .headerLogo {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .content {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;

    @include screen('tablet-md') {
      justify-content: space-between;
    }
  }

  .trigger {
    border: none;
    border-radius: calc(#{$spacing-01} + #{$spacing-10});
    padding: $spacing-03 $spacing-04;
    @include body-s-regular;
    background: $white;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    position: relative;

    span {
      display: none;
    }

    &:focus-visible {
      outline: $spacing-01 solid $gray-800;
      outline-offset: $spacing-02;
    }

    @include screen('tablet-md') {
      border: 1px solid $gray-200;
      padding: $spacing-03 $spacing-05;
      min-width: calc(#{$spacing-15} - #{$spacing-03});

      &:hover {
        box-shadow: 0px $spacing-02 $spacing-02 rgba(0, 0, 0, 0.05);
      }

      span {
        display: block;
      }

      svg {
        margin-right: $spacing-03;
      }
    }

    &:hover .tooltip {
      opacity: 1;
      transition-delay: 100ms;
    }
  }

  .logo {
    display: none;
    visibility: hidden;

    @include screen('tablet-md') {
      display: block;
      visibility: visible;
      
      &:focus-visible {
        border-radius: $spacing-01;
        outline: $spacing-01 solid $gray-800;
        outline-offset: $spacing-04;
      }
    }
  }

  .mobileLogo {
    display: flex;

    @include screen('tablet-md') {
      display: none;
      visibility: hidden;
    }
  }

  .back {
    border: none;
    background: transparent;
    position: absolute;
    left: 0;
    cursor: pointer;
    padding-left: 0;
    height: $spacing-08;
    width: $spacing-08;
    justify-content: center;
    padding-top: $spacing-02;
    z-index: 9;
    svg path {
      fill: $gray-800;
    }

    &:focus {
      outline: none;
    }

    @include screen('tablet-md') {
      display: none;
      height: auto;
      width: auto;
    }
  }

  .boxShadow {
    @include screen('tablet-md') {
      box-shadow: 0px $spacing-02 $spacing-02 0px #00000008;
    }
  }

  .newMatch {
    background-color: $azure-700;
    color: #FFFFFF;
    width: 42px;
    border: none;
    border-radius: calc(#{$spacing-01} + #{$spacing-10});
    padding: $spacing-04 0;
    @include body-s-regular;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0 $spacing-06;
    position: relative;

    span {
      display: none;
    }

    svg {
      padding-left: $spacing-04;
    }

    @include screen('tablet-md') {
      min-width: calc(#{$spacing-15} - #{$spacing-03});
      margin-left: 15px;

      &:hover {
        box-shadow: 0px $spacing-02 $spacing-02 rgba(0, 0, 0, 0.05);
      }

      span {
        display: block;
      }

      svg {
        margin-right: $spacing-03;
      }
    }

    &:hover {
      background: $azure-900;
      transform: scale(1.02);
    }

    &:focus {
      outline: $spacing-01 solid $gray-800;
      outline-offset: $spacing-02;
      background: $azure-900;
    }
  }

  .headerButtons {
    position: absolute;
    right: 0;
    display: flex;
  }
}

.stickyHeader {
  padding-bottom: $spacing-04;
  background-color: $white;
  height: calc(#{$spacing-14} - #{$spacing-05} - #{$spacing-04});
  @include screen('tablet-sm') {
    height: calc(#{$spacing-13} + #{$spacing-02} + #{$spacing-01});
  }
}

.newMatchContainer {
  position: relative;
}