@import './responsive.scss';

@font-face {
  font-family: 'Damion';
  src: local('Damion'),
    url('../../fonts/Damion/Damion-Regular.ttf') format('truetype');
}

// Font
@mixin font-utopia {
  font-family: 'utopia-std', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@mixin font-styrene {
  font-family: 'STYRENE B WEB';
}

@mixin font-signature {
  font-family: 'Damion';
}

@mixin title-xxxl-regular {
  @include font-utopia;
  font-size: 48px;
  line-height: 54px;
  margin: 0;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

@mixin title-xxl-regular {
  @include font-utopia;
  font-size: 36px;
  line-height: 44px;
  margin: 0;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

@mixin title-xl-regular {
  @include font-utopia;
  font-size: 24px;
  line-height: 40px;
  font-weight: 600;
  margin: 0;
  -webkit-font-smoothing: antialiased;

  @include screen('tablet-sm') {
    font-size: 28px;
    font-weight: 400;
  }
}

@mixin title-l-semibold {
  @include font-utopia;
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
}

@mixin title-m-medium {
  @include font-styrene;
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
}

@mixin title-m-regular {
  @include font-styrene;
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

@mixin title-s-regular {
  @include font-utopia;
  font-size: 22px;
  line-height: 28px;
  margin: 0;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

@mixin title-s-semibold {
  @include font-utopia;
  font-size: 22px;
  line-height: 28px;
  margin: 0;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
}

@mixin body-m-medium {
  font-size: 20px;
  line-height: 28px;
  @include font-styrene;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
}

@mixin body-s-medium {
  font-size: 16px;
  line-height: 24px;
  @include font-styrene;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
}

@mixin body-s-regular {
  font-size: 16px;
  line-height: 24px;
  @include font-styrene;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

@mixin body-regular-bold {
  font-size: 16px;
  line-height: 20px;
  @include font-styrene;
  font-weight: 700;
}

@mixin body-xs-bold {
  font-size: 14px;
  line-height: 20px;
  @include font-styrene;
  font-weight: 700;
}

@mixin body-xs-medium {
  font-size: 14px;
  line-height: 20px;
  @include font-styrene;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
}

@mixin body-xs-regular {
  font-size: 14px;
  line-height: 20px;
  @include font-styrene;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

@mixin body-xxs-regular {
  font-size: 12px;
  line-height: 16px;
  @include font-styrene;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

@mixin body-xxs-medium {
  font-size: 12px;
  line-height: 16px;
  @include font-styrene;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
}

@mixin body-xxs-bold {
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  @include font-styrene;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
}

@mixin body-xs-regular {
  font-size: 14px;
  line-height: 18px;
  @include font-styrene;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

@mixin S-semibold {
  font-size: 22px;
  line-height: 28px;
  @include font-utopia;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
}

@mixin S-medium {
  font-size: 22px;
  line-height: 28px;
  @include font-utopia;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
}

@mixin S-normal {
  font-size: 22px;
  line-height: 28px;
  @include font-utopia;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

@mixin signature-m {
  @include font-signature;
  font-size: 28px;
  line-height: 24px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}
