@import 'intakeOptimization/theme/typography';
@import 'intakeOptimization/theme/spacing';
@import 'intakeOptimization/theme/colors.scss';

.inputWrapper {
	@include body-s-medium;
	width: 100%;
	height: calc(#{$spacing-02} + #{$spacing-10});
	background: $white;
	border: 1px solid $gray-300;
	box-sizing: border-box;
	border-radius: $spacing-03;
	color: $gray-800;
	font-style: normal;
	font-weight: normal;
	font-size: $spacing-05;
	line-height: $spacing-07;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&::placeholder {
	  color: $gray-400;
	}

	&:focus-within {
	  outline: none;
	  border: 1px solid $gray-400;
	}

	&.error {
        border-color: $sienna-600;
	}

	&.caution{
		border-color: $gold-500;
	}

	input {
		padding-left: $spacing-05;
		width: 70%;
		height: 100%;
		border: none;
		outline: none;
		background: transparent;
		color: $gray-800;
		font-style: normal;
		font-weight: normal;
		font-size: $spacing-05;
		line-height: $spacing-07;
	}

	.age {
		color: $gray-500;
		@include body-s-medium;
		padding-right: $spacing-05;
		opacity: 0;
		transition: 100ms opacity ease-in;

		&.display {
			opacity: 1;
		}
	}
}


.errorMessage {
    @include body-xs-regular;
    color: $sienna-600;
    margin-top: $spacing-03;
    display: block;
}

.cautionMessage {
  @include body-xs-regular;
  color: $gold-600;
  margin-top: $spacing-03;
  display: block;
}

.adminInputMask {
	height: calc(#{$spacing-02} + #{$spacing-10});
	background: $white;
	border: 1px solid $gray-300;
	box-sizing: border-box;
	border-radius: $spacing-03;
	width: $spacing-21 !important;

	input {
		@include body-s-medium;
		font-style: normal;
		font-weight: normal;
		font-size: $spacing-05;
		line-height: $spacing-07;
		color: $gray-800;
		
		&::placeholder {
			color: $gray-400;
		}
	}


	&:hover {
	  border: 1px solid $gray-500;
	}

	&:focus {
	  border: 1px solid $gray-500;
	}

	&.tabbed {
	  outline: $spacing-01 solid $gray-800;
	  outline-offset: $spacing-01;
	  border-radius: $spacing-02;
	}

	&.error {
	  border-color: $sienna-600;
	}

  @include screen('tablet-md'){
	  border-radius: $spacing-03;
	  width: 643px !important;
  }

  @include screen('tablet-lg'){
	  width: 540px !important;
  }

  @include screen('desktop-sm'){
	  width: 487px !important;
  }

  @include screen('desktop-lg'){
	  width: 346px !important;
  }
}
