@import 'lifestance-ui/theme/spacing.scss';
@import 'lifestance-ui/theme/responsive.scss';
@import 'lifestance-ui/theme/colors.scss';

.card {
  align-items: center;
  background: $white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: $spacing-10 $spacing-04 $spacing-04 $spacing-04;

  @include screen('tablet-sm') {
    border: 1px solid $gray-300;
    border-radius: $spacing-03;
    padding: $spacing-10;
  }

  @include screen('desktop-sm') {
    margin: $spacing-04;
    padding-bottom: $spacing-10;
    width: $spacing-22;
  }
}
