@import 'lifestance-ui/theme/typography.scss';
@import 'lifestance-ui/theme/spacing.scss';
@import 'lifestance-ui/theme/colors.scss';
@import 'lifestance-ui/theme/responsive.scss';

.placeholder {
  height: $spacing-11;
  width: 100%;
  @include screen('tablet-md') {
    height: $spacing-11;
  }
}

.header {
  @include body-xxs-bold;
  margin-top: $spacing-05;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  @include screen('tablet-md') {
    display: none;
  }
}

.container {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: calc(#{$spacing-05} + #{$spacing-11});

  .indicator {
    width: 100%;
    height: calc(#{$spacing-01} + #{$spacing-02});
    background-color: $gray-200;
    border-radius: calc(#{$spacing-02} + #{$spacing-12});
    display: none;
    div {
      height: 100%;
      background-color: $casal-500;
      border-radius: calc(#{$spacing-02} + #{$spacing-12});
    }
    .progress_1 {
      width: 7%;
    }
    .progress_2 {
      width: 28%;
    }
    .progress_3 {
      width: 49%;
    }
    .progress_4 {
      width: 85%;
    }

    .progress_nocc_noins_1 {
      width: 7%;
    }
    .progress_nocc_noins_2 {
      width: 28%;
    }
    .progress_nocc_noins_3 {
      width: 85%;
    }
    @include screen('tablet-md') {
      display: block;
    }
  }

  .steps_container {
    height: calc(#{$spacing-01} + #{$spacing-09});
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 $spacing-07;
    @include screen('tablet-md') {
      height: $spacing-11;
      background-color: $gray-0;
    }
  }
}
.step_title {
  @include body-xs-regular;
}

.empty_circle {
  @include body-xxs-bold;
  margin: 0 $spacing-02 0 0;
  border-radius: calc(#{$spacing-02} + #{$spacing-12});
  height: $spacing-07;
  width: $spacing-07;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: calc(#{$spacing-02} + #{$spacing-12});
  color: $gray-400;
  border: $spacing-01 solid $gray-400;
}

.step {
  &.oneThird {
    width: 33%;
  }

  &.oneHalf {
    width: 50%;
  }
}

@include screen(tablet-md) {
  .step {
    &.oneThird, &.oneHalf {
      width: auto;
    }
  }
}

.step_current {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
  margin: 0 $spacing-02;
  .step_indicator {
    width: 100%;
    height: calc(#{$spacing-01} + #{$spacing-02});
    background-color: $gray-100;
    border-radius: calc(#{$spacing-02} + #{$spacing-12});
    border-radius: calc(#{$spacing-02} + #{$spacing-12});
  }
  .step_progress {
    height: 100%;
    width: 60%;
    background-color: $casal-400;
    border-radius: calc(#{$spacing-02} + #{$spacing-12});
  }
  .step_number {
    @include body-xxs-bold;
    position: relative;
    margin: 0 $spacing-02 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $casal-400;
    display: none;
  }
  .step_title {
    @include body-xs-medium;
    color: $gray-800;
    display: none;
  }
  .number {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .circle_half_green {
    @include body-xxs-bold;
    margin: 0;
    height: $spacing-07;
    width: $spacing-04;
    border-top-left-radius: 102px;
    border-bottom-left-radius: 102px;
    border: $spacing-01 solid $casal-400;
    border-right: 0;
  }
  .circle_half_gray {
    margin: 0;
    @include body-xxs-bold;
    height: $spacing-07;
    width: $spacing-04;
    border-top-right-radius: 102px;
    border-bottom-right-radius: 102px;
    border: $spacing-01 solid $gray-400;
    border-left: 0;
  }
  @include screen('tablet-md') {
    margin: 0 $spacing-04;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    .step_number,
    .step_title {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .step_number {
      @include body-xxs-bold;
      margin: 0 $spacing-02 0 0;
    }
    .step_indicator {
      display: none;
    }
    .step_progress {
      display: none;
    }
  }
}

.step_completed {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  margin: 0 $spacing-02;
  a {
    text-decoration: none;
    cursor: default;
  }
  .step_progress {
    height: calc(#{$spacing-01} + #{$spacing-02});
    background-color: $casal-400;
    width: 100%;
    border-radius: calc(#{$spacing-02} + #{$spacing-12});
    a {
      display: block;
      height: 100%;
      width: 100%;
    }
  }
  .step_number {
    @include body-xxs-bold;
    margin: 0 $spacing-02;
    border-radius: calc(#{$spacing-02} + #{$spacing-12});
    height: calc(#{$spacing-01} + #{$spacing-07});
    width: calc(#{$spacing-01} + #{$spacing-07});
    display: none;
    justify-content: center;
    align-items: center;
  }
  .step_title {
    display: none;
    color: $black;
  }
  @include screen('tablet-md') {
    margin: 0 $spacing-04;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    .step_number,
    .step_title {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .step_number {
      @include body-xxs-bold;
    }
    .step_progress {
      display: none;
    }
    .filled_circle {
      display: flex;
    }
  }
}

.filled_circle {
  @include body-xxs-bold;
  border-radius: calc(#{$spacing-02} + #{$spacing-12});
  height: calc(#{$spacing-01} + #{$spacing-07});
  width: calc(#{$spacing-01} + #{$spacing-07});
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: calc(#{$spacing-02} + #{$spacing-12});
  background-color: $casal-400;
}

.step_pending {
  margin: 0 $spacing-02;
  .empty_circle,
  .step_number,
  .step_title {
    display: none;
  }
  .step_number {
    @include body-xxs-bold;
    color: $gray-500;
    margin: 0 $spacing-02 0 0;
  }
  .step_title {
    color: $gray-500;
  }

  @include screen('tablet-md') {
    margin: 0 $spacing-04;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    .empty_circle,
    .step_number,
    .step_title {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .step_indicator {
      display: none;
    }
  }

  .step_indicator {
    width: 100%;
    height: calc(#{$spacing-01} + #{$spacing-02});
    background-color: $gray-100;
    border-radius: calc(#{$spacing-02} + #{$spacing-12});
  }
}
