@import 'intakeOptimization/theme/spacing.scss';
@import 'intakeOptimization/theme/typography.scss';
@import 'intakeOptimization/theme/colors.scss';

.buttonDate {
  @include body-xs-regular;
  background-color: $regal-800;
  border: none;
  border-radius: $spacing-02;
  color: $white;
  cursor: pointer;
  padding: $spacing-04;
  text-align: center;
  height: $spacing-09;
  box-sizing: border-box;

  @include screen('phone') {
    width: max-content;
  }

  &:hover {
    background-color: $regal-900;
  }
  .modalityIcon {
    color: $white;
    margin-right: $spacing-03;
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
  }
}


.tooltip {
  position: unset;
  margin-top: $spacing-03;
  display: none;

  div {
    top: 50px;
    min-height: $spacing-06;
  }

  @include screen('tablet-lg') {
    display: unset;
  }
}

.AdminButtonAvailability {
  @include body-xs-regular;
  background-color: $white;
  border: solid 1px $gray-300;
  border-radius: $spacing-02;
  color: $gray-700;
  padding-top: 0.6rem;
  padding-bottom: $spacing-04;
  padding-right: $spacing-04;
  padding-left: $spacing-04;
  text-align: center;
  height: $spacing-09;
  box-sizing: border-box;

  .modalityIcon {
    color: $gray-700;
    filter: brightness(0.35);
    margin-right: $spacing-03;
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
  }

  @include screen('phone') {
    width: max-content;
  }
}
