@import 'intakeOptimization/theme/typography.scss';
@import 'intakeOptimization/theme/spacing.scss';
@import 'intakeOptimization/theme/colors.scss';

.container {
  position: relative;
  z-index: 9;

  .filterLabel {
    white-space: nowrap;
    color: $gray-800;
    max-width: calc((100% - calc(#{$spacing-06} + #{$spacing-01})));
    text-overflow: ellipsis;
  }

  .extraLayer {
    z-index: 8;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
  }

  .noExtraLayer {
    display: none;
  }
}

.select {
  @include body-xs-regular;
  background-color: $white;
  border-radius: calc(#{$spacing-02} + #{$spacing-12});
  border: 1px solid $gray-300;
  color: $gray-700;
  margin-right: $spacing-04;
  appearance: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 0 $spacing-05 0 $spacing-05;
  height: 34px;
  box-sizing: border-box;

  .circle {
    @include body-xxs-regular;
    width: $spacing-06;
    border-radius: $spacing-12;
    background-color: $azure-700;
    box-sizing: border-box;
    padding: $spacing-01;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: $spacing-02;
  }

  .circle {
    @include body-xxs-regular;
    width: $spacing-06;
    border-radius: $spacing-12;
    background-color: $azure-700;
    box-sizing: border-box;
    padding: $spacing-01;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: $spacing-02;
  }

  svg {
    margin-left: $spacing-03;
  }

  &:focus-visible {
    outline: $spacing-01 solid $gray-800;
    outline-offset: $spacing-01;
  }
}

.selectedOptions {
  background-color: $regal-0;
  border: $spacing-01 solid $azure-700;
}

.selectOpen {
  background-color: $regal-0;
  border: $spacing-01 solid $azure-700;

  svg {
    transform: rotate(180deg);
  }
}
.filterContainer {
  z-index: inherit;
  background: $white;
  box-shadow: 0px $spacing-03 $spacing-07 rgb(0 0 0 / 9%);
  border-radius: $spacing-03;
  display: flex;
  position: absolute;
  padding: $spacing-07 0px;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: calc(#{$spacing-09} + #{$spacing-01});
  min-width: $spacing-21;

    .options {
      h3 {
        color: $black;
        @include body-s-medium;
        margin-bottom: $spacing-04;
      }
    }

    .option {
      @include body-s-regular;
      padding: $spacing-03  $spacing-07;
      display: flex;
      align-items: center;
      cursor: pointer;

      .label {
        display: flex;
        user-select: none;
        margin: 0px;
      }

      .checkbox {
        min-width: $spacing-06;
        height: $spacing-06;
        border: 1px solid $gray-300;
        border-radius: $spacing-02;
        margin-right: $spacing-05;
        cursor: pointer;
        position: relative;

        &:after {
          content: '';
          width: $spacing-03;
          height: $spacing-02;
          position: absolute;
          top: $spacing-02;
          left: $spacing-02;
          border: 3px solid $white;
          border-top: none;
          border-right: none;
          background: transparent;
          opacity: 0;
          transform: rotate(-45deg);
        }

        &:hover::after {
          opacity: 0.5;
        }
      }

      &[data-checked='true'] {


        .checkbox {
          background: $azure-700;
        }

        .checkbox:after {
          opacity: 1;
        }
      }

      span {
        min-width: max-content;
        color: $gray-800;
      }

      &:focus-visible, &:hover {
        background-color: $regal-100;
      }
    }

    .seeMore {
      @include body-s-regular;
      color: $gray-400;
      text-decoration: underline;
      margin-bottom: $spacing-04;
      cursor: pointer;
    }
  .footer {
    display: flex;
    padding-top: $spacing-06;
    margin-top: calc(#{$spacing-01} + #{$spacing-03});
    margin-left: $spacing-07;
    margin-right: $spacing-07;
    border-top: 1px solid $gray-300;
    justify-content: flex-end;

    .clearButton {
      padding: 0px $spacing-03;
      border-radius: $spacing-03;
      margin-right: $spacing-03;

      &:focus-visible {
        outline: $spacing-01 solid $gray-800;
        outline-offset: -$spacing-01;
      }
    }
  }
}

.maxHeight {
  // options - footer - internal padding top and bottom = (400px) - (53px + 10 margin) - (24*2)= 289px
  max-height: calc(#{$spacing-17} + #{$spacing-10});
  overflow-y: auto;
}

.filterOptionsContainer {
  padding: 0px;
  max-height: none;

  .footer {
    padding: $spacing-06 0 $spacing-07 0;
    margin-right: $spacing-07;
    margin-left: $spacing-07;
  }

}

.selectModality {
  @include body-xs-regular;
  background-color: $white;
  border-radius: calc(#{$spacing-02} + #{$spacing-12});
  border: 1px solid $gray-300;
  color: $gray-700;
  margin-right: $spacing-03;
  appearance: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: $spacing-03 $spacing-05;
  height: calc(#{$spacing-02} + #{$spacing-08});
  box-sizing: border-box;

  @include screen('tablet-lg') {
    &:nth-child(2) {
      position: relative;
      margin-right: $spacing-05;
      &:after {
        content: '';
        border-right: 1px solid $gray-200;
        height: calc(#{$spacing-02} + #{$spacing-08});
        position: absolute;
        right: -$spacing-03;
      }
    }
  };

  @include screen('desktop-sm') {
    &:nth-child(2) {
      position: relative;
      margin-right: $spacing-08;

      &:after {
        content: '';
        border-right: 1px solid $gray-200;
        height: calc(#{$spacing-02} + #{$spacing-08});
        position: absolute;
        right: -$spacing-05;
      }
    }
  };

  @include screen('desktop-sm') {
    &:nth-child(2) {
      position: relative;
      margin-right: $spacing-08;

      &:after {
        content: '';
        border-right: 1px solid $gray-200;
        height: calc(#{$spacing-02} + #{$spacing-08});
        position: absolute;
        right: -$spacing-05;
      }
    }
  };

  .label {
    display: flex;
    cursor: pointer;
    user-select: none;
    margin: 0px;
    align-items: center;
  }

  .modalityCheckbox {
    width: $spacing-06;
    min-width: $spacing-06;
    height: $spacing-06;
    box-sizing: border-box;
    border: 1px solid $gray-300;
    border-radius: $spacing-02;
    margin-right: $spacing-02;
    cursor: pointer;
    position: relative;
    margin-top: 0px;

    @include screen('tablet-md') {
      margin-right: $spacing-03;
    }

    &:after {
      content: '';
      width: $spacing-03;
      height: $spacing-02;
      position: absolute;
      top: $spacing-02;
      left: $spacing-02;
      border: 3px solid $white;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
    }

    &:hover::after {
      opacity: 0.5;
    }

    &:hover::after {
      opacity: 0.5;
    }
  }

  input[type='checkbox'] {
    visibility: hidden;
    width: 0;
    margin-left: 0px;

    &:checked + label > div {
      background: $azure-700;
      border: 1px solid $azure-700;
    }

    &:checked + label > div:after {
      opacity: 1;
    }
  }
}

.modalitySpan {
  color: $gray-800;
  display: block;
}

.selectedModality {
  background-color: $regal-100;
  border: 2px solid $azure-700;
  padding: $spacing-03 $spacing-05;
  height: calc(#{$spacing-02} + #{$spacing-08});
  box-sizing: border-box;

  input[type='checkbox'] {
    margin-left: 0px;
  }
}

.credentialsSeparator {
  padding-bottom: calc(#{$spacing-03} + #{$spacing-01}) !important;
  &:after {
    content: '';
    min-width: 100%;
    position: relative;
    top: $spacing-08;
    right: calc(#{$spacing-12} + #{$spacing-03});
    border-bottom: solid 1px #D1D1D1;
  }
}

.credentialsFilterContainer {
  min-width: calc(#{$spacing-19} + #{$spacing-06});
  max-width: calc(#{$spacing-19} + #{$spacing-06});

  @include screen('desktop-lg') {
    max-width: unset;
    width: calc(#{$spacing-21} + #{$spacing-03});
  }
}


.separator {
  border-top: solid 1px #D1D1D1;
  margin-bottom: $spacing-04;
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
