@import 'lifestance-ui/theme/colors.scss';
@import 'lifestance-ui/theme/spacing.scss';

.circularProgress {
  animation: color-change 3s infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @keyframes color-change {
    0% {
      color: $azure-700;
    }

    100% {
      color: $casal-500;
    }
  }
}

.component {
  position: static; 
  display: inline-block;
  margin-top: $spacing-11;
  margin-left: 48%;
}