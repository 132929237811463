@import 'intakeOptimization/theme/typography.scss';
@import 'intakeOptimization/theme/spacing.scss';
@import 'intakeOptimization/theme/colors.scss';

.container{
    display: flex;
    margin-top: $spacing-08;
    margin-bottom: $spacing-03;
    justify-content: space-between;

    .label{
        @include body-s-medium;
    }

    .optional{
        @include body-xs-regular;
        color: $gray-400;
    }

    .fieldset{
        margin-top: 24px !important;
        margin-bottom: 8px !important;
    }

}