@import "lifestance-ui/theme/typography.scss";
@import "lifestance-ui/theme/spacing.scss";
@import "lifestance-ui/theme/colors.scss";

.circularButton {
	box-sizing: border-box;
	width: $spacing-09;
	height: $spacing-09;
	background-color: $white;
	border: 1px solid $gray-300;
	// hex color to match design for shadow rgba(0,0,0,0.08)
	box-shadow: 0px $spacing-02 $spacing-02 #00000014;
	border-radius: $spacing-09;

	&:focus-visible {
		outline: $spacing-01 solid $gray-800;
		outline-offset: $spacing-01;
	}
}

.active {
	background-color: $regal-100;
	border-color: $azure-700;
}