@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/responsive.scss';
@import 'patientOnboarding/theme/colors.scss';
@import 'patientOnboarding/theme/spacing.scss';

.appointment {
   position: relative;
}

.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $modal-overlay;
    z-index: 1;
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;

    @include screen('tablet-md') {
      position: initial;
      background-color: unset;
    }
}

.grayIcon {
  background: $gray-100;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.container {
  position: fixed;
  background: #FFFFFF;
  box-shadow: 0px $spacing-02 $spacing-04 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: $spacing-05 $spacing-07 $spacing-06;
  bottom: 0px;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: $spacing-06 $spacing-06 0 0;
  transition: bottom .2s ease;
  z-index: 9;

  touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;

  &.open {
    bottom: 0;
  }

  @include screen('tablet-md') {
    position: absolute;
    width: 358px;
    top: 55px;
    right: 0;
    left: unset;
    bottom: unset;
    padding: $spacing-07;
    padding-top: 0px;
    padding-bottom: $spacing-04;
    border-radius: $spacing-02;
    z-index: 9;
    &.open {
      bottom: unset;
    }
  }
}

.header {
  text-align: center;
  padding-bottom: $spacing-05;
  border-bottom: 1px solid #E3E3E3;
  @include body-s-regular;
  @include screen('tablet-md') {
    display: none;
  }
}

.calendar {
  width: 44px;
  height: 44px;
}

.center {
  margin-left: auto !important;
  margin-right: auto;
}

.closeIcon {
  position: absolute;
  cursor: pointer;
  top: $spacing-07;
  left: $spacing-07;
  @include screen('tablet-md') {
    top: $spacing-06;
    right: $spacing-06;
    left: unset;
  }
}

.row {
  display: flex;
  align-items: flex-start;
  padding: $spacing-05 0;
  border-top: 1px solid $gray-200;

  &.last {
    border-bottom: none;
    align-items: center;
    padding-bottom: $spacing-04;
  }
  
  &.first {
    border-top: none;
    align-items: center;
    padding-bottom: $spacing-04;
  }

  @include screen('tablet-md') {
    padding: $spacing-05 0;
  }

  .resultThumbnail {
    width: $spacing-07;
    height: $spacing-07;
    border-radius: 50%;
  }

  .icon {
    margin-top: $spacing-02;
    margin-right: $spacing-05;
  }

  .info {
    margin-left: $spacing-05;

    h2 {
      @include body-xs-medium;
      color: $black;
      margin-bottom: $spacing-02;
    }
    h1 {
      @include body-s-medium;
      color: $black;
      margin-bottom: $spacing-02;
    }
    p {
      @include body-xs-regular;
    }

    .centering {
      margin-top: $spacing-02;
    }

    .gray {
      color: $gray-400;
      margin-top: 0.40rem;
    }

    .gray_500 {
      color: $gray-500;
      margin-top: 0.40rem;
    }

    .link {
      color: $azure-700;
      @include body-xs-medium;
    }

    .notAvailable {
      color: $sienna-600;
    }

    @include screen('tablet-md') {
      margin-left: 0;
    }
  }
}

.footer {
  display: block;
  margin-top: $spacing-06;

  @include screen('tablet-md') {
    display: none;
  }
}
