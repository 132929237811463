@import "lifestance-ui/theme/spacing.scss";
@import "lifestance-ui/theme/responsive.scss";
@import "lifestance-ui/theme/typography.scss";
@import "lifestance-ui/theme/colors.scss";

.main {
  height: fit-content;
  margin: auto auto auto auto;
  box-sizing: border-box;
  display: flex;
  justify-content: center;

  @include screen('tablet-sm') {
    margin: auto;
  }

  &.withoutHeader {
    padding-top: $spacing-08;
  }

  touch-action: none;
  -webkit-overflow-scrolling: none;
}

.modal {
  background: rgba(51, 51, 51, 0.43);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
  height: 100%;
  overflow-y: hidden;
  touch-action: none;
  -webkit-overflow-scrolling: touch;
  width: 100vw;

  @include screen(tablet-lg) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: $modal-overlay;
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    z-index: 10;
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  }

}

.modalHeader {
  border-bottom: 1px solid $gray-200;
  width: 100%;
  display: block;
  position: relative;
  padding: $spacing-08 0px;

  @include screen("tablet-lg") {
    border: none;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 0px $spacing-03;
  }
}

.scrollableModalContent {
  @media (max-width: 1024px) {
    overflow-y: scroll !important;
  }
}

.modalContentBottom {
  top: auto;
  bottom: 0;
  height: fit-content;
}

.modalContent {
  display: block;
  box-sizing: border-box;
  position: fixed;
  width: 100vw;
  background-color: $white;
  z-index: 10;
  left: 0;
  transition: all 0.3s ease;
  border-radius: $spacing-06 $spacing-06 0 0;
  touch-action: none;
  overflow:auto;

  @include screen("tablet-sm") {

    .main {
      width: 100vw;
      margin: auto;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
    }
    .divider {
      border-bottom: $spacing-02 solid $gray-900;
    }
  }

  @include screen("tablet-md") {
    overflow-y: visible;

    .main {
      width: 500px;
      margin: auto;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
    }
  }

  @include screen("desktop-sm") {
    width: 528px;
    left: 50%;
    top: 50%;
    overflow-y: visible;
    transform: translate(-50%, -50%);
    bottom: initial;
  }

  @include screen("tablet-lg") {
    position: fixed;
    top: $spacing-10;
    bottom: 0;
    left: 0;
    background-color: $white;
    width: 100%;
    border-top-right-radius: $spacing-03;
    border-top-left-radius: $spacing-03;
    height: auto;
    overflow: unset;
    width: 528px;
    left: 50%;
    top: 50%;
    overflow-y: visible;
    transform: translate(-50%, -50%);
    bottom: initial;
    border-radius: $spacing-03;

    .main {
      padding-top: 0;
    }
  }

  @media (max-width: map-get($breakpoints, 'tablet-lg')) {
    &:not(.modalContentBottom) {
      height: calc(100vh - #{$spacing-09});
      top: $spacing-09;
    }
  }
}
.closeButton {
  position: relative;
  cursor: pointer;
  padding: $spacing-07;

  @include screen("tablet-md") {
    top: 4%;
    cursor: pointer;
  }

  @include screen("tablet-lg") {
    position: relative;
    top: 4%;
    cursor: pointer;
  }
}

.defHeight {
  display: block;
  height: calc(#{$spacing-08} * 2);
}

.showModal {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.fullScreen {
  top: 120vh;
  transition: top 0.3s ease;
  opacity: 1;

  &.showModal {
    top: 0;

    .modalContent {
      top: 3em;

      @include screen("tablet-sm") {
        top: 0;
        border-radius: 0;
        overflow-y: auto;
      }
    }

    .closeButton {
      @include screen("tablet-lg") {
        display: none;
      }

      @include screen("tablet-lg") {
        background: #ffffff;
        box-shadow: 0px $spacing-02 $spacing-04 rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        width: calc(#{$spacing-10} - #{$spacing-01});
        height: calc(#{$spacing-10} - #{$spacing-01});
        box-sizing: border-box;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        right: $spacing-08;
      }
      @include screen('desktop-lg') {
        top: $spacing-08;
      }
    }
  }

  .modalHeader {
    position: absolute;
    z-index: 10;
    height: calc(#{$spacing-03} + #{$spacing-11});
    box-sizing: border-box;
  }

  .modalContent {
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    transform: none;
  }
  .main {
    width: 100%;
  }
}

.bottomScreen {
  .containerModal {
    min-height: auto;
  }

  .modalContent {
    height: fit-content;
    bottom: 0;
    top: auto;

    .modalHeader {
      padding: $spacing-08 0;
    }
    .main {
      padding-top: 0;
    }
  }

  @include screen("tablet-lg") {
    .modalContent {
      height: auto;
      top: 50%;
      bottom: auto;

      .modalHeader {
        padding: 0;
      }
    }
  }
}
