@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/colors.scss';


.search {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $white;
  top: 100vh;
  transition: top 0.3s ease;
  z-index: 9;

  &.active {
    top: 0;
  }

  .mobileHeader {
    border-bottom: 1px solid $gray-200;
    padding: $spacing-07;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      @include body-s-regular;
    }

    svg {
      padding: $spacing-04;
      position: absolute;
      left: $spacing-05;
      cursor: pointer;
    }

    @include screen('tablet-md') {
      display: none;
    }
  }

  .close {
    display: none;

    @include screen('tablet-md') {
      width: $spacing-10;
      height: $spacing-10;
      border-radius: calc(#{$spacing-01} + #{$spacing-10});
      box-shadow: 0px $spacing-02 $spacing-04 rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: $spacing-08;
      top: $spacing-08;
      cursor: pointer;
      z-index: 3;
    }
  }

  .adminClose:focus-visible {
    outline: $spacing-01 solid $gray-800;
    outline-offset: $spacing-02;
  }

  .clearSearch {
    position: absolute;
    right: 35px;
    top: 15px;
    @include screen('tablet-md') {
      display: none;
    }
  }

  .searchBar {
    padding-left: $spacing-07;
    margin-top: $spacing-08;
    padding-right: $spacing-07;
    width: 100%;
    box-sizing: border-box;
    position: relative;

    .label {
      display: none;
    }

    .mobileClose {
      display: none;
    }

    @include screen('tablet-sm'){
      padding-left: $spacing-08;
      margin-top: $spacing-08;
    }

    @include screen('tablet-md') {
      margin-left: $spacing-11;
      margin-top: calc(#{$spacing-02} + #{$spacing-10});
      width: 90%;

      input {
        @include title-xxl-regular;
        border: none;
      }

      .label {
        display: block;
        margin-bottom: $spacing-07;
        @include body-xxs-bold;
      }

      .mobileClose {
        display: none;
      }
    }

    @include screen('desktop-sm'){
      margin-left: $spacing-12;
    }
  }

  .searchResults {
    overflow-y: scroll;
    height: 67%;
    background: $white;
    z-index: 9;
    margin: 0px;

    @include screen('tablet-md') {
      margin-top: $spacing-06;
      border-top: 1px solid $gray-200;
    }

    @include screen('desktop-sm'){
      padding: $spacing-10 $spacing-12;
    }

    .briefSearch{
      @include screen('desktop-lg'){
        max-width: none;
      }
    }
  }
}
