@import 'lifestance-ui/theme/responsive';
@import 'lifestance-ui/theme/spacing';
@import 'lifestance-ui/theme/typography.scss';
@import 'lifestance-ui/theme/colors.scss';

.container {
    position: fixed;
    z-index: 10;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);;
    top: 100vh;

    &.open {
        top: 0;

        .footer {
            position: fixed;
        }
    }

    @include screen('tablet-lg') {
        top: 0;
    }
}

.modalHeader {
    width: 100%;
    display: none;

    @include screen("tablet-lg") {
      border: none;
      display: flex;
      justify-content: flex-end;
      box-sizing: border-box;
      padding: 0px $spacing-03;

      span {
          position: absolute;
          cursor: pointer;
          right: $spacing-08;
          top: $spacing-08;
      }
    }
}

.modal {
    position: fixed;
    background-color: #fff;
    bottom: -100vh;
    left: 0;
    transition: 0.3s ease bottom;
    width: 100%;

    &.open {
        bottom: 0;
        max-height: 100vh;
        overflow-y: auto;
        position: absolute;
    }
    
    @include screen('tablet-lg') {
        width: 790px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        border-radius: $spacing-03;
        transition: none;
        bottom: 0;
        max-height: fit-content;

        &.open {
            position: relative;
        }
    }

    .headerMobile {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        padding: calc(#{$spacing-01} + #{$spacing-06});
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #E3E3E3;
        z-index: 1;
        background-color: #fff;
        border-radius: $spacing-06 $spacing-06 0px 0px;

        .goBack {
            cursor: pointer;
            left: $spacing-07;
            position: absolute;
        }

        .title {
            @include body-s-regular;
        }

        .cancel {
            @include body-s-medium;
            color: #0067CE;
            cursor: pointer;
            right: $spacing-07;
            position: absolute;
        }

        @include screen('tablet-lg') {
            display: none;
        }
    }

    .headerMobileMessage {
        padding: $spacing-07;
        position: relative;
        display: block;

        .closeIcon {
            position: unset;
            left: unset;
        }
    }

    .closeIcon {
        position: absolute;
        left: $spacing-07;
        cursor: pointer;

        @include screen('tablet-lg') {
            display: block;
        }
    }

    .footer {
        border-top: 1px solid #D1D1D1;
        padding: $spacing-07;
        position: relative;
        bottom: 0;
        background: #ffffff;
        width: 100%;
        box-sizing: border-box;
        transition: .3s ease position;
        transition-delay: 2s;

        @include screen('tablet-lg') {
            display: none;
        }

        &.newColdLanding {
            display: none;
        }
    }
}

.closeButton {
    &:focus-visible:before{
        content: '';
        display: inline-block;
        position: absolute;
        width: 40px;
        height: 40px;
        left:-15px;
        margin-top:-15px;
        border-radius: 50%;
        border: 2px solid $gray-800;
        top:0;
        outline: 0;
     }
}


.fullScreen {
    .modal {
        @include screen('tablet-lg') {
            width: 100%;
            top: 0;
            left: 0;
            transform: none;
            position: absolute;
            height: 100vh;
        }
    }
}

.emptyTitle {
    height: 24px;
}
