@import 'lifestance-ui/theme/colors.scss';
@import 'lifestance-ui/theme/spacing.scss';

:root {
  --skeleton-padding: 0;
  --skeleton-height: calc((2 * #{$spacing-14}) + #{$spacing-06});
  --animation-height: 100%;
  --animation-width: 100%;
  --animation-position: var(--skeleton-padding) 0;
  --animation-skeleton: linear-gradient(
    $white var(--animation-height),
    transparent 0
  );
  --blur-width: 70%;
  --blur-size: var(--blur-width) calc(var(--skeleton-height));
}

.skeleton {
  height: var(--skeleton-height);

  &:empty::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: calc(#{$spacing-01} + #{$spacing-02});
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1);
    background-color: #ebebeb;

    background-image: linear-gradient(
        90deg,
        rgba(lightgrey, 0) 0,
        rgba(lightgrey, 0.4) 50%,
        rgba(lightgrey, 0) 100%
      ),
      //animation blur
      var(--animation-skeleton),
      //title
;

    background-size: var(--blur-size),
      var(--animation-width) var(--animation-height);

    background-position: -150% 0,
      //animation
      var(--animation-position),
      //title
;

    background-repeat: no-repeat;
    animation: loading 1.5s infinite;
  }
}

@keyframes loading {
  to {
    background-position: 350% 0, var(--animation-position), 0 0;
  }
}
