@import 'intakeOptimization/theme/spacing.scss';
@import 'intakeOptimization/theme/colors.scss';
@import 'intakeOptimization/theme/typography.scss';

.title {
  @include title-xl-regular;
  color: $black;
  text-align: center;
}

.description {
  margin: $spacing-03 0 $spacing-09;
  @include body-s-regular;
  text-align: center;
  color: $gray-700;
}
