@import 'lifestance-ui/theme/typography.scss';
@import 'lifestance-ui/theme/spacing.scss';
@import 'lifestance-ui/theme/colors.scss';
@import 'lifestance-ui/theme/responsive.scss';

.link {
  @include body-s-medium;
  color: $azure-700;
  height: $spacing-07;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0px;

  &:focus {
    color: $gray-600;
  }
}

.dropdownBox {
  position: absolute;
  padding: $spacing-06 0px;
  background-color: #fff;
  box-shadow: 0px $spacing-03 $spacing-07 rgba(0, 0, 0, 0.12);
  border-radius: $spacing-03;

  .dropdownItems {
    position: relative;
    width: 264px;

    a {
      @include body-s-regular;
      display: block;
      padding: calc(#{$spacing-01} + #{$spacing-03}) $spacing-06;
      text-decoration: none;
      color: $gray-800;
    }

    a:hover {
      background-color: $regal-0;
    }
    a:after {
      content: ' Calendar';
    }
  }
}
