@import "patientOnboarding/theme/typography.scss";
@import "patientOnboarding/theme/spacing.scss";
@import "patientOnboarding/theme/colors.scss";

.container {
	margin-bottom: $spacing-09;
    margin-top: $spacing-07;
	
	.message {
		border-radius: $spacing-03;
		box-sizing: border-box;
		padding: $spacing-07 $spacing-06;
		background-color: $regal-50;
		text-align: center;

		.paragrahp {
			@include body-s-regular;
			color: $gray-800;
		}
	}

	.text {
		@include body-xs-regular;
		margin-top: $spacing-04;
		color: $gray-500;

		.phone {
			@include body-xs-medium;
		}
	}
}