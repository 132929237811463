@import 'intakeOptimization/theme/typography.scss';
@import 'intakeOptimization/theme/spacing.scss';
@import 'intakeOptimization/theme/colors.scss';

.addressContainer{
    svg{
        width: $spacing-07;
        height: $spacing-07;
        vertical-align: text-top;
    }
    .address{
        margin-bottom: $spacing-07;
    }
    .heading{
        @include body-s-medium;
        margin-bottom: $spacing-04;
        padding-left: $spacing-07;
        display: inline-block;
    }

    .licenseKey {
        margin-bottom: $spacing-03;
        margin-left: $spacing-10;
        display: flex;
        width: calc(#{$spacing-11} + #{$spacing-05});
        position: relative;

        @include screen('desktop-sm') {
            position: relative;
        }
    }

    .line{
        padding-left: $spacing-10;
        @include body-s-regular;
    }
    .addressContainer{
        margin-bottom: $spacing-04;
    }

    .link{
        color: #0067CE;
        @include body-s-medium;
        text-decoration: none;

        svg{
            width: $spacing-04;
            height: $spacing-04;
            vertical-align: middle;
        }
    }

    .arrow {
        @include body-s-medium;
        color: $azure-700;
        margin-top: $spacing-03;
        width: fit-content;
        svg {
            width: $spacing-05;
            height: $spacing-05;
            margin-left: $spacing-02;
            vertical-align: middle;
            cursor: pointer;
            transform: rotate(135deg);
        }
        span {
            cursor: pointer;
        }

        &:hover {
            span {
                color: $azure-900;
            }
            svg {
                path {
                    fill: $azure-900;
                }
            }
        }
    }
}

.directions {
    margin-left: $spacing-03;
}
