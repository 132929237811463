@import 'lifestance-ui/theme/typography.scss';
@import 'lifestance-ui/theme/spacing.scss';
@import 'lifestance-ui/theme/colors.scss';
@import 'lifestance-ui/theme/responsive.scss';
.back {
  border: none;
  background: transparent;
  position: absolute;
  left: 0;
  cursor: pointer;
  padding-left: 0;

  svg path {
    fill: $gray-800;
  }

  &:focus {
    outline: none;
  }

  @include screen('tablet-md') {
    display: none;
  }
}

.link {
  @include body-s-regular;
  color: $gray-700;
  text-decoration: none;
  display: flex;
  align-items: center;
}
