@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/spacing.scss';

.filters {
  display: flex;
  position: relative;
  z-index: 1;
}

.filter {
  display: none;

  &:nth-child(1), &:nth-child(2) {
    display: flex;
  }

  &:nth-child(2) {
    margin-right: $spacing-07;
  }

  @include screen('tablet-lg') {
    &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
      display: flex;
    }
  }

  @include screen('desktop-sm') {
    display: flex;
  }
}
