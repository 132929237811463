@import 'patientOnboarding/theme/reset.scss';
@import 'patientOnboarding/theme/colors.scss';
@import 'patientOnboarding/theme/responsive.scss';
@import 'patientOnboarding/theme/spacing.scss';

$footerHeight: calc(#{$spacing-02} + #{$spacing-12});

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.appContainer{
    min-height: calc(100vh);
    // min-height: calc(100vh + #{$footerHeight});
    display: grid;
    grid-template-columns: calc(100vw - 110px) 110px;
    grid-template-rows: 80px minmax(calc(100vh - 180px), auto) auto auto;
    grid-template-areas:
    "header header"
    "content content"
    "whiteSpace contactBuuble"
    "footer footer";

    .header{
        grid-area: header;
    }

    .stickyMenu {
        position: sticky;
        top: 0px;
        background-color: $white;
        z-index: 1;
    }

    .sticky {
        height: calc((#{$spacing-14} + #{$spacing-09}));
    }

    .content{
        grid-area: content;
        min-height: 90vh;
    }

    .adminContent {
        background-color: $gray-50;
    }

    .contactBubble{
        grid-area: contactBuuble;
        position: sticky;
        z-index: 7;
    }

    .footer{
        grid-area: footer;
    }

    .adminFooter{
        background-color: $gray-50;
    }
}

*:focus { outline: none; }

@include screen("tablet-lg") {
    *:focus { outline: initial }
}

#heartland-frame-cardNumber {
    width: 100%;
}

.select__single-value {
    line-height: 1.5;
}
