@import 'intakeOptimization/theme/typography.scss';
@import 'intakeOptimization/theme/spacing.scss';
@import 'intakeOptimization/theme/colors.scss';

.otherProviders {
  .heading {
    @include S-semibold;
  }
  .providers {
    margin-top: $spacing-03;
    margin-bottom: $spacing-06;

    .provider {
      width: 100%;
      display: flex;
      padding-bottom: $spacing-06;
      padding-top: $spacing-06;
      border-bottom: 1px solid #E3E3E3;

      .clinicianDP {
        display: inline-block;
        margin-right: $spacing-06;
        img {
          display: inline-block;
          width: 57.44px;
          height: 60px;
        }
      }

      .clinicianInfo {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        .clinicianType {
          display: inline-block;
          @include body-xxs-bold;
          color: $regal-800;
        }
        .clinicianName {
          text-align: left;
          @include body-s-medium;
          @include screen('tablet-sm') {
            @include title-s-semibold;
          }
          margin-top: $spacing-01;
        }

        .expertiseAreas {
          margin: 0;
          @include body-xs-regular;

          @include screen('tablet-lg') {
          @include body-s-regular;
          }
        }
      }
      .rightLine {
        display: flex;
        align-items: center;
        margin-left: auto;
        cursor: pointer;
      }
    }
  }
}
