@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/colors.scss';

.card {
  background: $white;
  border-bottom: 1px solid $gray-300;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: $spacing-09;
  position: relative;

  @include screen('tablet-md') {
    flex-direction: row;
    padding: $spacing-10 0;
  }
}

.resultDetails {
  margin-top: $spacing-08;
  display: flex;
  margin-bottom: $spacing-06;

  @include screen('tablet-md') {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.resultThumbnail {
  border-radius: $spacing-03;
  margin-right: $spacing-07;
  width: calc(#{$spacing-05} + #{$spacing-12});
  height: calc(#{$spacing-05} + #{$spacing-12});
  background-color: $gray-100;
  position: relative;
  overflow: hidden;

  @include screen('tablet-sm') {
    width: calc(#{$spacing-05} + #{$spacing-12});
    height: calc(#{$spacing-05} + #{$spacing-12});
  }

  @include screen('tablet-md') {
    width: 189px;
    height: 189px;
  }
}

.resultInfo {
  @include screen('phone') {
    width: 50%;
  }
  @include screen('tablet-sm') {
    width: initial;
    width: calc(#{$spacing-06} + #{$spacing-19});
  }
}

.resultTitle {
  color: $gray-100;
  background-color: $gray-100;
  width: 100%;
  height: $spacing-07;
  position: relative;
  overflow: hidden;

  @include screen('tablet-md') {
    height: $spacing-08;
    margin-bottom: $spacing-02;
    display: block;
  }
}

.resultDescription {
  height: $spacing-07;
  width: 100%;
  background-color: $gray-100;
  position: relative;
  overflow: hidden;

  @include screen('tablet-sm') {
    margin-bottom: $spacing-06;
  }
}

.resultAvailability {
  display: none;

  @include screen('tablet-lg') {
    display: block;
    height: $spacing-07;
    width: 30%;
    background-color: $gray-100;
    margin-bottom: $spacing-03;
    position: relative;
    overflow: hidden;
  }
}

.resultExtra {
  display: none;

  a {
    text-decoration: none;
  }

  @include screen('tablet-lg') {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.resultAddress {
  display: none;

  @include screen('tablet-md') {
    display: block;
    @include body-s-regular;
    color: $gray-400;
    width: calc(#{$spacing-09} + #{$spacing-14});
    text-align: right;
    position: relative;
    overflow: hidden;
  }
}

.adressLine {
  width: calc(#{$spacing-09} + #{$spacing-14});
  height: calc(#{$spacing-01} + #{$spacing-06});
  margin: $spacing-02 0;
  background-color: $gray-100;
  position: relative;
  overflow: hidden;
}

.resultLink {
  @include body-s-medium;
  background-color: $gray-100;
  height: $spacing-07;
  width: 222px;
  display: none;
  position: relative;
  overflow: hidden;

  @include screen('tablet-lg') {
    display: block;
  }
}

.resultLinkMobile {
  background-color: $gray-100;
  height: $spacing-07;
  width: 70%;
  margin-top: $spacing-06;
  display: none;
  position: relative;
  overflow: hidden;

  @include screen('tablet-md') {
    display: block;
  }

  @include screen('tablet-lg') {
    display: none;
  }
}

.tag {
  margin-bottom: $spacing-04;
  width: calc(#{$spacing-02} + #{$spacing-12});
  height: $spacing-06;
  position: relative;
  overflow: hidden;
  background: $gray-100;
  border-radius: $spacing-02;
  color: $gray-100;
}

.buttonDate {
  height: $spacing-09;
  background-color: $gray-100;
  border: none;
  border-radius: $spacing-02;
  text-align: center;
  position: relative;
  overflow: hidden;

  @include screen('phone') {
    width: calc(#{$spacing-07} + #{$spacing-12});
  }
}

.buttons {
  display: none;

  @include screen('tablet-md') {
    display: flex;
    flex-direction: row;
    gap: $spacing-03;
  }

  @include screen('tablet-lg') {
    .buttonDate:last-child {
      display: none;
    }
  }

  @include screen('desktop-sm') {
    .buttonDate:last-child {
      display: block;
    }
  }
}

.availabilityMobile {
  display: flex;
  grid-gap: $spacing-03;
  gap: $spacing-03;

  .buttonDate:last-child {
    display: none;
  }

  @include screen('tablet-sm') {
    .buttonDate:last-child {
      display: block;
    }
  }

  @include screen('tablet-md') {
    display: none;
  }
}

.animation {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgb(238, 238, 238);
  background: linear-gradient(
    90deg,
    $gray-100 0%,
    $gray-200 50%,
    $gray-100 100%
  );
  animation-name: loading;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
