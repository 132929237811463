@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/colors.scss';

.container {
  @include title-l-semibold;
  width: 84%;
  background: $white;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: fit-content;

  .header {
    margin-bottom: $spacing-07;
    margin-top: $spacing-10;

    @include screen('tablet-lg') {
      margin-left: calc(#{$spacing-11} + #{$spacing-07});
      margin-top: 0;
    }
  }

  .title {
    @include body-s-medium;
    margin-bottom: $spacing-03;
  }

  .questions {
    display: flex;
    justify-content: space-between;
    margin-top: $spacing-08;
  }

  .toast {
    margin-top: $spacing-07;
    position: relative;
    visibility: hidden;
    top: -$spacing-07;
    left: 0px;
    opacity: 0;
    transform: translateY(0);
    height: 0px;
    padding: 0px $spacing-06;
    transition: all 0.3s ease;
  }

  .showToast {
    visibility: visible;
    opacity: 1;
    height: auto;
    padding: $spacing-07 $spacing-06;
    transform: translateY($spacing-07);
    margin-bottom: $spacing-07
  }

  .option {
    @include body-xs-regular;
    color: $gray-400;
  }

  .unsure {
    @include body-s-medium;
    color: $azure-700;
    margin-left: $spacing-02;
  }

  .optional {
    @include body-xxs-bold;
    display: flex;
    color: $gray-300;
    text-align: center;
    margin-top: $spacing-06;
    margin-bottom: $spacing-06;
  }

  .hideButton{
    display: none;
  }

  .button {
    margin-top: $spacing-10;
    margin-bottom: $spacing-10;
  }

  .adjust {
    @include body-s-regular;
    margin-bottom: $spacing-08;
    margin-top: $spacing-06;

    @include screen('tablet-lg') {
      margin-left: $spacing-04;
    }
  }

  .input {
    @include body-s-medium;
    padding: $spacing-05;
    width: 100%;
    height: calc(#{$spacing-02} + #{$spacing-10});
    background: $white;
    border: 1px solid $gray-300;
    box-sizing: border-box;
    border-radius: $spacing-03;
    color: $gray-800;
    font-style: normal;
    font-weight: normal;
    font-size: $spacing-05;
    line-height: $spacing-07;
  
    &::placeholder {
      color: $gray-400;
    }
  
    &:focus {
      outline: none;
      border: 1px solid $gray-400;
    }
  
    &.error {
      border-color: $sienna-500;
    }
  }

  .errorText {
    @include body-xs-regular;
    color: $sienna-600;
    margin-top: $spacing-03;
  }
}

.expandedContainer {
  height: fit-content !important;
}
