@import "lifestance-ui/theme/typography.scss";
@import "lifestance-ui/theme/spacing.scss";
@import "lifestance-ui/theme/colors.scss";

.selectCard {
	display: flex;
	width: 100%;
	padding: $spacing-07;
	justify-content: space-between;
	align-items: flex-start;
	box-sizing: border-box;
	flex-wrap: wrap;
	box-shadow: 0 0 0 1px $gray-200;
	border-radius: $spacing-03;

	@include screen('tablet-lg'){
		box-shadow: none;
		outline: 0;
		padding: $spacing-06;
		cursor: pointer;

		&:first-child {
			padding-bottom: $spacing-06;
			padding-top: $spacing-02;
		}
	}

	&:first-child {
		.headerContainer {
			h1 {
				@include body-s-regular;
			}
		}
	}


	.headerContainer {
		box-sizing: border-box;
		display: flex;
		width: 100%;

		.header {
			@include body-s-medium;
			min-width: calc(100% - #{$spacing-06});
			flex-shrink: 0;

			@include screen('tablet-lg'){
				min-width: 0;
				flex-shrink: unset;
			}

			.desktopHeader {
				@include body-s-regular;
				display: none;
				@include screen('tablet-lg'){
					display: contents;
				}
			}

		}

		h1 {
			@include screen('tablet-lg'){
				order: 2;
				width: fit-content;
				margin-left: $spacing-04;
				margin-top: -2px;
			}
		}

		.checkbox {
			@include screen('tablet-lg'){
				order: 1;
				flex-shrink: 0;
			}

			appearance: none;
			-webkit-appearance: none;
			width: $spacing-06;
			height: $spacing-06;
			border-radius: $spacing-02;
			margin: unset;
			border: 1px solid $gray-300;
			outline: none;
			cursor: pointer;

			&:checked {
				border: 1px solid $azure-700;
				background-color: $azure-700;

				&::before {
						content: " ";
						position: relative;
						width: $spacing-03;
                        height: $spacing-02;
						display: block;
						top: $spacing-02;
						left: $spacing-02;
						border: 3px solid $white;
						border-top: none;
						border-right: none;
						transform: rotate(-45deg);
				}
			}

		}
	}

	.contentContainer {
		width: 100%;
		box-sizing: border-box;


		.content {
			@include body-s-regular;
			color: $gray-800;
			margin-top: $spacing-02;
			width: calc(100% - #{$spacing-06});

			@include screen('tablet-lg'){
				display: none;
				margin-left: $spacing-08;
				max-width: calc(100% - $spacing-08);
			}
		}

		.extraContent {
			@include body-s-regular;
			margin-top: $spacing-02;
			color: $gray-500;
			@include screen('tablet-lg'){
				margin-left: $spacing-08;
				max-width: calc(100% - $spacing-08);
			}
		}

		.pillboxContainer {
			margin-top: $spacing-04;
			display: flex;
			column-gap: $spacing-03;

			@include screen('tablet-lg'){
				margin-left: $spacing-08;
				max-width: calc(100% - $spacing-08);
			}

			.greenPillbox {
				@include body-xs-medium;
				padding: $spacing-01 $spacing-04;
				background-color: $casal-50;
				color: $casal-700;
				border: unset;
			}

			.iconContainer {
				width: $spacing-06;
				height: $spacing-06;
				margin-top: unset;

				svg {
					width: $spacing-06;
					height: $spacing-06;
				}
			}
		}
	}
}

.selected {
	background-color: $regal-0;
	box-shadow: 0 0 0 $spacing-01 $azure-700;;

	@include screen('tablet-lg'){
		box-shadow: none;
		background-color: white;
		outline: 0;
	}
}
