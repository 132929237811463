@import "patientOnboarding/theme/typography.scss";
@import "patientOnboarding/theme/spacing.scss";
@import "patientOnboarding/theme/colors.scss";
@import "patientOnboarding/theme/responsive.scss";

.divider{
    margin: $spacing-08 0;
    height: 1px;
    width: 100%;
    background-color: $gray-200;
}

.header{
    @include title-s-regular;
}

.formLabel{
    @include body-s-medium;
    margin-top: $spacing-08;
    margin-bottom: $spacing-03;
    display: block;
}

.labelContainer{
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  .optional{
    @include body-xs-regular;
    color: $gray-400;
  }
}

.input {
    @include body-s-medium;
    padding: $spacing-05;
    width: 100%;
    height: calc(#{$spacing-02} + #{$spacing-10});
    background: $white;
    border: 1px solid $gray-300;
    box-sizing: border-box;
    border-radius: $spacing-03;
    color: $gray-800;
    font-style: normal;
    font-weight: normal;
    font-size: $spacing-05;
    line-height: $spacing-07;
  
    &::placeholder {
      color: $gray-400;
    }
  
    &:focus {
      outline: none;
      border: 1px solid $gray-400;
    }
  
    &.inputError {
      border-color: $sienna-600;
    }

  }
  .checkbox{
    margin-top: $spacing-08;
  }