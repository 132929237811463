@import 'lifestance-ui/theme/typography';
@import 'lifestance-ui/theme/colors.scss';
@import 'lifestance-ui/theme/spacing.scss';

.info {
  @include body-s-medium;
  font-weight: normal;
  font-size: calc(#{$spacing-01} + #{$spacing-04});
  line-height: $spacing-06;
  color: $regal-800;
  margin-bottom: $spacing-09;

  @include screen('tablet-sm') {
    color: $regal-800;
  }

  &.fullWidth {
    width: 100%;
  }
}
