@import 'intakeOptimization/theme/typography.scss';
@import 'intakeOptimization/theme/spacing.scss';

.educationContainer{
    svg{
        width: $spacing-06;
        height: $spacing-06;
        vertical-align: text-top;
    }
    .heading{
        // 26px
        padding-left: calc(#{$spacing-01} + #{$spacing-07});
        display: inline-block;
        @include body-s-medium;
        padding-bottom: $spacing-04;
    }
    .education{
        padding-left: 28px;
        @include body-s-regular;
        padding-top: $spacing-04;
        line-height: 2;
    }
    .line{
        // 46px
        padding-left: calc(#{$spacing-10} - #{$spacing-01});
        @include body-s-regular;
        padding-bottom: $spacing-04;
    }
} 