@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/colors.scss';
@import 'patientOnboarding/theme/spacing.scss';

.container {
  margin: $spacing-11 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    @include body-s-medium;
    padding: $spacing-06 $spacing-08;
    height: $spacing-11;
    background: $white;
    border: 1px solid $gray-400;
    border-radius: $spacing-03;
    cursor: pointer;
    @include screen('desktop-sm') {
      display: flex;
    }
    span {
      width: calc(#{$spacing-04} + #{$spacing-14});
      color: $black;
    }
  }
}
