@import "patientOnboarding/theme/spacing.scss";
@import "patientOnboarding/theme/colors.scss";
@import "patientOnboarding/theme/typography.scss";
@import "patientOnboarding/theme/responsive.scss";

.overlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(38, 38, 38, 0.43);
  align-items: flex-end;
  z-index: 10;
  @include screen("desktop-sm") {
    justify-content: center;
    align-items: center;
  }

  .content {
    width: 100%;
    height: 95.7%;
    border-radius: 20px 20px 0px 0px;
    position: relative;
    background-color: $white;
    @include screen("desktop-sm") {
      height: unset;
      max-width: $spacing-22;
      padding: $spacing-11;
      border-radius: $spacing-03;
    }

    .header {
      border-radius: 20px 20px 0px 0px;
      padding: $spacing-07;
      padding-bottom: 0;
      background-color: $white;
      display: flex;
      position: relative;
      flex-direction: column;

      @include screen("desktop-sm") {
        position: static;
        flex-direction: row;
        padding: 0;
        .icon {
          margin-bottom: $spacing-07;
          position: absolute;
          top: 30px;
          right: 30px;
        }
      }

      .icon {
        height: $spacing-07;
        width: $spacing-07;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: $spacing-07;
        cursor: pointer;
      }

      .divider {
        height: 1px;
        width: 100vw;
        margin-left: calc(0rem - #{$spacing-07});
        background-color: $gray-200;
        @include screen("desktop-sm") {
          display: none;
        }
      }

      .title {
        padding-top: $spacing-10;
        @include title-s-semibold;
        @include screen("desktop-sm") {
          @include title-xl-regular;
          margin: 0 0 $spacing-02 0;
          padding-top: 0;
        }
      }
    }
  }
}
