@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/colors.scss';

.container {
  width: 100%;
  background: $white;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 280px;

  @include screen('tablet-lg'){
    height: 375px;
  }

  .headerContainer {
    height: 46px;
    margin-top: 30px;
    @include screen('tablet-lg'){
      margin-top: 70px;
    }
  }

  .header {
    @include title-s-semibold;
    font-size: $spacing-07;
    color: $gray-800;
    text-align: center;
    opacity: 0;
    @include screen('tablet-lg'){
      @include title-xl-regular;
    }
  }

  .hide {
    opacity: 0;
    transition: ease-out 1s;
    height: 0px;
  } 

  .show {
    opacity: 1;
    transition: ease-in 1s;
  }

  .puzzle {
    display:flex;
    justify-content: center;
    box-sizing: border-box;
    height: auto;
    transform: scale(0.85);
    transform-origin: top;
    margin-top: 70px;
    margin-left: 12px;
    @include screen('tablet-lg') {
      margin-top: 120px;
      transform: scale(1);
      margin-left: 8px;
      margin-left: 0px;
    }
  }

}

