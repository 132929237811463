@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/colors.scss';
@import 'patientOnboarding/theme/responsive';


.coldLanding {
  padding-bottom: $spacing-06;

    .coldTitle {
      @include body-xxs-bold;
      margin-bottom: $spacing-04;
    }
  
    .coldDescription {
      @include title-l-semibold;
      margin-bottom: $spacing-05;
    }
  }

  .content {
    display: flex;
    margin-top: $spacing-05;
    flex-direction: column;
  
    @include screen('tablet-lg') {
        flex-direction: row;
    }
  
    .windowVisible {
        display: block !important;
    }
  }

  .calendarContainer {
    text-align: center;
  }

  .calendar {
    background-color: #fff;
    display: none;
  
     @include screen('tablet-lg') {
       margin-right: $spacing-08;
       min-width: calc(#{$spacing-05} + #{$spacing-20});
       position: relative;
    }
  
  }
  
  .appointmentsContainer {
      display: block;
      min-width: calc(#{$spacing-06} + #{$spacing-19});
      padding-bottom: $spacing-10;
  }