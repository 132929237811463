@import "lifestance-ui/theme/typography.scss";
@import "lifestance-ui/theme/spacing.scss";
@import "lifestance-ui/theme/colors.scss";
@import "lifestance-ui/theme/responsive.scss";

.container{
    width: 100%;
    display: flex;

    .check{
        min-width: $spacing-06;
        height: $spacing-06;
        border-radius: $spacing-02;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $gray-400;
        box-sizing: border-box;
    }
    
    .checked{
        background-color: $azure-700;
        border: $azure-700;
    }

    .caption{
        @include body-xs-regular;
        margin-left: $spacing-04 ;
    }

    .test {
        margin: 0;
    }

    .customStyles {
        color: $gray-500;
    }
}