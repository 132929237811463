@import "patientOnboarding/theme/typography.scss";
@import "patientOnboarding/theme/spacing.scss";
@import "patientOnboarding/theme/colors.scss";

.container {
	text-align: center;

	.text {
		@include body-s-regular;
		color: $gray-700;
		display: block
		;
	}
}