@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/spacing.scss';

.supervisorContainer {
	margin-top: $spacing-07;

	.heading{
		// 26px
		padding-left: calc(#{$spacing-01} + #{$spacing-06});
		display: inline-block;
		@include body-s-medium;
		padding-bottom: $spacing-04;
	}
	
	svg{
		width: $spacing-07;
		height: calc(#{$spacing-06} + #{$spacing-01});
		vertical-align: text-top;
	}
	
	.supervisorDataContainer {
		.line{
			// 46px
			padding-left: calc(#{$spacing-10} - #{$spacing-01});
			@include body-s-regular;
			padding-bottom: $spacing-04;
		}
	} 
}

.supervisorContainerAdmin {
	margin-bottom: $spacing-05;
}