@import 'lifestance-ui/theme/spacing.scss';
@import 'lifestance-ui/theme/typography.scss';
@import 'lifestance-ui/theme/colors.scss';

.appointmentCalendar {
  :global {
    .react-datepicker {
      border: none;
      .react-datepicker__day,
      .react-datepicker__day-name {
        margin: 0 $spacing-04 0 0 !important;
        padding: calc(#{$spacing-01} + #{$spacing-02});
        height: calc(#{$spacing-02} + #{$spacing-08});
        width: calc(#{$spacing-02} + #{$spacing-08});
        box-sizing: border-box;
        text-align: center;
        @include body-xs-bold;

        &:focus-visible {
          outline: 2px solid $gray-800;
        }
      }

      .react-datepicker__day,
      .react-datepicker__day:hover {
        background-color: $regal-50;
        color: $azure-700;
        cursor: pointer;
      }

      .react-datepicker__day--disabled {
        @include body-xs-regular;
        background: transparent;
        color: $gray-300;
      }

      .react-datepicker__day--disabled:hover {
        background-color: transparent;
        cursor: not-allowed;
        color: $gray-300;
      }

      .react-datepicker__week {
        margin-bottom: 1px;
        margin-top: 2px;
      }

      .react-datepicker__month {
        margin: 0;
      }

      .react-datepicker--custom-header {
        display: flex;
        justify-content: space-between;
        margin: 0;

      }

      .react-datepicker__current-month {
        color: #333 !important;
        @include body-s-medium;
      }

      .react-datepicker--custom-buttons {
        button {
          background: transparent;
          border: none;
          cursor: pointer;
          position: relative;
          
          &:disabled {
            cursor: not-allowed;
          }
          
          &:focus-visible:before{
            content: '';
            display: inline-block;
            position: absolute;
            width: 40px;
            height: 40px;
            left:-13px;
            margin-top:-13px;
            border-radius: 50%;
            border: 2px solid $gray-800;
            top:0;
            outline: 0;
         }

         &:focus-visible {
          outline: 0;
         }

          svg path {
            stroke: $azure-700;
          }

          &:first-child {
            margin-right: $spacing-05;
          }

          &:disabled svg path {
            stroke: $gray-300;
          }
        }
      }

      .react-datepicker__header {
        background-color: $white !important;
        border: none !important;
        text-align: left;
        @include body-s-medium;
      }

      .react-datepicker__current-month {
        padding-bottom: $spacing-07;
      }

      .react-datepicker__day {
        border-radius: 50% !important;
      }

      .react-datepicker__day--today {
        background-color: transparent;
      }

      .react-datepicker__day-name {
        @include body-xxs-bold;
      }

      .react-datepicker__day--selected,
      .react-datepicker__day--selected:hover {
        background-color: $azure-700;
        color: $white;
      }
    }
  }
}