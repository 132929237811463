@import "patientOnboarding/theme/typography.scss";
@import "patientOnboarding/theme/spacing.scss";
@import "patientOnboarding/theme/colors.scss";

.header {
	margin-bottom: $spacing-07;

	.location {
		@include body-xxs-bold;
		margin-bottom: $spacing-04;
		display: block;
	}

	.head {
		@include title-l-semibold;
		color: $gray-800;
	}
}

.optionsContainer {
	

	.videoPillbox {
		@include body-xs-medium;
		padding: $spacing-01 $spacing-04;
		background-color: $regal-100;
		color: $regal-800;
		border: unset;
	}

	.divider {
		@include body-xxs-bold;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: $gray-300;
		margin: $spacing-06 0px;
		
		svg {
			width: calc(50% - #{$spacing-06} - #{$spacing-03});
		}
	}

	.selectCard {
		margin-bottom: $spacing-06;
	}

	.lineNoPhysical {
		margin-top: $spacing-06;
	}

	.noPhysicalLocations {
		@include body-s-regular;
		display: flex;
		padding: $spacing-05 $spacing-06;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		gap: $spacing-03;
		border-radius: $spacing-02;
		background-color: $gray-50;
		color: $gray-500;
		margin-top: $spacing-06;
		height: $spacing-13;
	}
}
