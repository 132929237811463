@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/colors.scss';

.content {
	background: $regal-50;
	margin-top: $spacing-07;
	padding-right: $spacing-06;
	padding-top: $spacing-06;
	padding-bottom: $spacing-07;
	padding-left: $spacing-06;
	border-radius: $spacing-03;

	@include screen('tablet-md'){
		justify-content: flex-start;
	}

	.icon {
		width: 24px;
		height: 24px;
		float: left;
	}

	.contentTitle {
		@include body-xs-medium;
		font-size: 16px;
		margin-left: calc($spacing-04 + $spacing-06);
		margin-top: 4px;
	}

	.contentText{
		margin-left: $spacing-08;
		@include body-s-regular;
		font-weight: 400;
		font-size: 16px;
		margin-top: $spacing-02;
	}

	.phone {
		@include body-xs-medium;
		font-weight: 500;
		color: $regal-800;
		cursor: pointer;

		@include screen('desktop-sm'){
			color: $gray-800;
		}
	}
}