@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/colors.scss';

.trigger {
  border: none;
  border-radius: calc(#{$spacing-01} + #{$spacing-10});
  padding: $spacing-03 0;
  @include body-s-regular;
  background: $white;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  position: absolute;
  right: 0;
  top: 0px;
  height: $spacing-08;
  width: $spacing-08;
  justify-content: center;

  svg {
    display: block;
  }

  .loadingIcon {
    margin-right: $spacing-03;
    animation: spinner 3s;
    @keyframes spinner {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  @include screen('tablet-md') {
    position: relative;
    right: 0;
    height: auto;
    width: auto;

    .calendarIcon {
      margin-right: $spacing-03;
    }
  }

  span {
    display: none;
  }

  @include screen('tablet-md') {
    border: 1px solid $gray-200;
    padding: $spacing-03 $spacing-05;

    &:hover {
      box-shadow: 0px $spacing-02 $spacing-02 rgba(0, 0, 0, 0.05);
    }

    span {
      display: block;
    }
  }
}
