$breakpoints: (
  'phone': 350px,
  'tablet-sm': 481px,
  'tablet-md': 768px,
  'tablet-lg': 1024px,
  'desktop-sm': 1280px,
  'desktop-lg': 1440px,
) !default;

@mixin screen($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

/*
  This mixin applies whenever the dropdown menu is shown in a mobile iOS device.
  Also when the iOS screen is fully covered by a wrapper and it has a "Save & Close"
  button at the bottom.
*/
@mixin save-and-close-sticky-btn {
  @media (max-width: map-get($breakpoints, "tablet-md")){
    body, html {
      height: 100%;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }
}