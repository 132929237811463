@import "intakeOptimization/theme/spacing.scss";
@import "intakeOptimization/theme/responsive.scss";
@import "intakeOptimization/theme/typography.scss";
@import "intakeOptimization/theme/colors.scss";

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	background: $white;
	border: 1px solid $gray-200;
	padding-top: $spacing-11;
	padding-bottom: $spacing-09;

	@include screen('tablet-sm') {
		border-radius: $spacing-03;
		margin-top: $spacing-10;
	}

	.icon {
		margin-bottom: $spacing-07;
	}

	.title {
		@include body-m-medium;
	}

	.description {
		@include body-s-regular;
		margin-top: $spacing-02;
		margin-bottom: $spacing-07;
		max-width: 439px;
		text-align: center;
		padding-left: $spacing-06;
		padding-right: $spacing-06;
		color: $gray-500;
	}

	.button {
		margin-bottom: $spacing-07;
		color: $gray-800;
		cursor: pointer;
	}
}

