@import "lifestance-ui/theme/spacing.scss";
@import "lifestance-ui/theme/responsive.scss";
@import "lifestance-ui/theme/typography.scss";
@import "lifestance-ui/theme/colors.scss";

.dropdown {
  border: 1px solid $gray-300;
  border-radius: $spacing-03;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $gray-400;
  user-select: none;
  justify-content: space-between;
  box-sizing: border-box;
  padding: calc(#{$spacing-01} + #{$spacing-04}) $spacing-06;
  height: calc(#{$spacing-02} + #{$spacing-10});
  box-sizing: border-box;
  @include body-s-regular;

  &.disabled {
    background-color: $gray-100;
    cursor: not-allowed;
  }

  &::placeholder {
    color: $gray-400;
  }
  &.selected {
    color: $gray-800;
  }
  .value {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 95%;
    max-width: 70vw;
  }
}

.select {
  @include body-s-regular;
  background-color: $white;
  border-radius: calc(#{$spacing-02} + #{$spacing-12});
  border: 1px solid $gray-300;
  color: $gray-700;
  padding: $spacing-03 $spacing-06;
  margin-right: $spacing-04;
  appearance: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  svg {
    margin-left: $spacing-03;
    width: 100%;
  }
}

.selectedOptions {
  color: #131313;
}

.selectOpen {
  svg {
    transform: rotate(180deg);
  }
}
.container {
  width: 100%;
  position: relative;

  &:focus, &:active {
    outline: none !important;

    & > .dropdown {
      border-color: $gray-500;
    }
  }
}
.optionsContainer {
  display: none;
  position: relative;

  @include screen("tablet-md") {
    background: $white;
    box-shadow: 0px $spacing-03 $spacing-07 rgb(0 0 0 / 12%);
    border-radius: $spacing-03;
    display: inline-block;
    position: absolute;
    padding: 0;
    flex-direction: column;
    margin-top: $spacing-04;
    width: 100%;
    box-sizing: border-box;
    z-index: 10;
    max-height: calc(#{$spacing-06} + #{$spacing-22});
    overflow-y: scroll;
    visibility: visible;
    transform: translateY(0);
    border: 1px solid #e3e3e3;

    li {
      @include body-s-regular;
      padding-right: $spacing-07;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $gray-800;
      padding: $spacing-03 $spacing-06;



      &:hover,
      &:active,
      &.focused,
      &:focus {
        background-color: $regal-0;
        outline: none;
      }
    }
  }

  @include screen("tablet-lg") {
    background: $white;
    box-shadow: 0px $spacing-03 $spacing-07 rgb(0 0 0 / 9%);
    border-radius: $spacing-03;
    display: inline-block;
    position: absolute;
    padding: 0;
    flex-direction: column;
    margin-top: $spacing-04;
    width: 100%;
    box-sizing: border-box;
    z-index: 10;
    max-height: calc(#{$spacing-06} + #{$spacing-22});
    visibility: visible;
    transform: translateY(0);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: $spacing-06;

    li {
      @include body-s-regular;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $gray-800;
      padding: $spacing-03 $spacing-06;

      &:hover,
      &:active,
      &:focus {
        background-color: $lilac;
      }
    }
  }
  
}

.options {
  h3 {
    color: $black;
    @include body-s-medium;
    margin-bottom: $spacing-02;
    padding-left: $spacing-07;
    margin-top: $spacing-05;
  }
}

.footer {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  display: flex;
  padding: $spacing-06 $spacing-05;
  margin-top: calc(#{$spacing-01} + #{$spacing-03});
  border-top: 1px solid $gray-300;
  justify-content: flex-end;
}

.option {
  @include body-s-regular;
  padding: $spacing-03 0 $spacing-03 $spacing-07;
  display: flex;
  align-items: flex-start;

  &:first-child{
    padding: $spacing-05 $spacing-05 $spacing-03;
  }

  &:hover {
    cursor: pointer;
  }

  span {
    overflow-wrap: break-word;
  }

  .label {
    display: flex;
    cursor: pointer;
    user-select: none;
    padding-left: 16px;
  }

  .checkbox {
    width: $spacing-06 !important;
    height: $spacing-06 !important;
    border: 1px solid $gray-300;
    border-radius: $spacing-02;
    margin-top: 2px;
    cursor: pointer;
    position: relative;

    &:after {
      content: "";
      width: $spacing-03;
      height: $spacing-02;
      position: absolute;
      top: $spacing-02;
      left: $spacing-02;
      border: 3px solid $white;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
    }
    &:hover::after {
      opacity: 0.5;
    }
  }

  &.focused,
  &:focus {
    background-color: $regal-0;
    outline: none;
  }

  .checked {
    background: $azure-700;

    &:after {
      opacity: 1;
    }
  }


  span {
    min-width: max-content;
    color: $gray-800;
  }
}
