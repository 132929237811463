@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/colors.scss';

.container {
  position: relative;
  border-bottom: 1px solid $gray-300;
  margin: 0 $spacing-07;

  @include screen('tablet-lg') {
    &:nth-child(4) {
      display: none;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

.dropDownIcon {
  @include screen('tablet-sm') {
    display: none;
  }
}

.select {
  @include title-m-regular;
  background-color: $white;
  color: $gray-700;
  padding: $spacing-08 0;
  appearance: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  justify-content: space-between;

  @include screen('tablet-sm') {
    padding-bottom: 0;
    padding-top: $spacing-08;
  }

  svg {
    margin-left: $spacing-03;
  }
}

.selectOpen {
  border-bottom: none;
  svg {
    transform: rotate(180deg);
  }
}

.optionsContainer {
  background: $white;
  display: flex;
  padding-bottom: $spacing-08;
  flex-direction: column;
  margin-top: 0px;

  @include screen('tablet-sm') {
    margin-top: $spacing-07;
    display: inherit;
  }

  &.expanded {
    .option:nth-child(n + 7) {
      display: inherit;
    }
  }

  .options {
    h3 {
      color: $black;
      @include body-s-medium;
      margin-bottom: $spacing-04;
    }
    &.expandedSub {
      .option:nth-child(n + 7) {
        display: inherit;
      }
    }
  }

  .option {
    @include body-s-regular;
    margin-bottom: $spacing-05;
    padding-right: $spacing-07;
    align-items: center;

    &:nth-child(n + 7) {
      display: none;
    }

    label {
      display: flex;
      cursor: pointer;
      user-select: none;
    }

    span {
      color: $black;
    }

    .checkbox {
      box-sizing: border-box;
      width: $spacing-06;
      height: $spacing-06;
      border: 1px solid $gray-300;
      border-radius: $spacing-02;
      margin-right: $spacing-05;
      cursor: pointer;
      position: relative;
      background-color: $white;
      &:after {
        content: '';
        width: $spacing-03;
        height: 5px;
        position: absolute;
        top: $spacing-02;
        left: $spacing-02;
        border: 3px solid $white;
        border-top: none;
        border-right: none;
        background: transparent;
        opacity: 0;
        transform: rotate(-45deg);
      }
      &:hover::after {
        opacity: 0.5;
      }
    }

    input[type='checkbox'] {
      visibility: hidden;
      margin-left: -13px;
      transform: scale(0);
      position: absolute;

      &:checked + label > div {
        background: $azure-700;
      }
      &:checked + label > div:after {
        opacity: 1;
      }
    }

    span {
      max-width: calc(calc(100%) - (#{$spacing-08} + #{$spacing-02}));
      overflow-wrap: break-word;
    }
  }

  .seeMore {
    @include body-s-regular;
    color: $gray-400;
    text-decoration: underline;
    margin-bottom: $spacing-04;
    display: block;
    cursor: pointer;
  }
}
