@import "intakeOptimization/theme/spacing.scss";
@import "intakeOptimization/theme/responsive.scss";
@import "intakeOptimization/theme/typography.scss";
@import "intakeOptimization/theme/colors.scss";

.dropdown {
  border: 1px solid $gray-300;
  border-radius: $spacing-03;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $gray-400;
  user-select: none;
  justify-content: space-between;
  box-sizing: border-box;
  padding: calc(#{$spacing-01} + #{$spacing-04}) $spacing-06;
  height: calc(#{$spacing-02} + #{$spacing-10});
  box-sizing: border-box;
  @include body-s-regular;

  &.disabled {
    background-color: $gray-100;
    cursor: not-allowed;
  }

  &.placeholder {
    color: $gray-400;
  }
  &.selected {
    color: $gray-800;
    .value {
      color: $gray-800;
    }
  }

  &.error {
    border-color: $sienna-700;
  }

  &.tabbed {
	  outline: $spacing-01 solid $gray-800;
	  outline-offset: $spacing-01;
	  border-radius: $spacing-02;
	}
  
  .value {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 95%;
    max-width: 70vw;
  }
}
