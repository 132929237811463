@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/colors.scss';

.container {
  position: relative;
  z-index: 9;

  .filterLabel {
    white-space: nowrap;
    color: $gray-800;
    max-width: 139px;
    text-overflow: ellipsis;
    overflow: hidden;

    @media (min-width: 1024px) and (max-width: 1141px) {
      &:nth-child(1) {
        max-width: 80px;
      }
    }
    @media (min-width: 1280px) and (max-width: 1439px) {
      max-width: 100px;
    }
  }

  .closeIcon {
    display: block;
    vertical-align: middle;
    height: 16px;
  }

  .extraLayer {
    z-index: 8;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
  }

  .noExtraLayer {
    display: none;
  }
}

.select {
  @include body-xs-regular;
  background-color: $white;
  border-radius: calc(#{$spacing-02} + #{$spacing-12});
  outline: 1px solid $gray-300;
  color: $gray-700;
  margin-right: $spacing-04;
  appearance: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 0 $spacing-05 0 $spacing-05;
  height: 34px;

  .circle {
    @include body-xxs-regular;
    width: $spacing-06;
    border-radius: $spacing-12;
    background-color: $azure-700;
    box-sizing: border-box;
    padding: $spacing-01;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: $spacing-02;
  }

  svg {
    margin-left: $spacing-03;
    filter: brightness(0.7);
  }

  &:hover {
    outline: 1px solid $gray-400;
  }
}

.selectedOptions {
  background-color: $regal-0;
  outline: 2px solid $azure-700;
  border: 0px;

  &:hover {
    background-color: $regal-100;
    border: 0px !important;
    outline: 2px solid $azure-700;
  }
}

.selectOpen {
  background-color: $regal-0;
  outline: 2px solid $azure-700;
  border: 0px;

  svg {
    transform: rotate(180deg);
  }
}

.optionsContainer {
  z-index: inherit;
  background: $white;
  box-shadow: 0px $spacing-03 $spacing-07 rgb(0 0 0 / 9%);
  border-radius: $spacing-03;
  display: flex;
  position: absolute;
  padding: $spacing-07;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: $spacing-09;
  min-width: $spacing-21;

  .content {
    overflow-y: auto;
  }

  .options {
    h3 {
      color: $black;
      @include body-s-medium;
      margin-bottom: $spacing-04;
    }
  }

  .option {
    @include body-s-regular;
    padding-right: $spacing-07;
    padding-bottom: $spacing-04;
    display: flex;
    align-items: center;

    .label {
      display: flex;
      cursor: pointer;
      user-select: none;
      margin: 0px;
    }

    .checkbox {
      min-width: $spacing-06;
      height: $spacing-06;
      border: 1px solid $gray-300;
      border-radius: $spacing-02;
      margin-right: $spacing-05;
      cursor: pointer;
      position: relative;

      &:after {
        content: '';
        width: $spacing-03;
        height: $spacing-02;
        position: absolute;
        top: $spacing-02;
        left: $spacing-02;
        border: 3px solid $white;
        border-top: none;
        border-right: none;
        background: transparent;
        opacity: 0;
        transform: rotate(-45deg);
      }

      &:hover::after {
        opacity: 0.5;
      }
    }

    input[type='checkbox'] {
      visibility: hidden;
      width: 0;

      &:checked + label > div {
        background: $azure-700;
      }

      &:checked + label > div:after {
        opacity: 1;
      }
    }

    span {
      min-width: max-content;
      color: $gray-800;
    }
  }

  .seeMore {
    @include body-s-regular;
    color: $gray-400;
    text-decoration: underline;
    margin-bottom: $spacing-04;
    cursor: pointer;
  }

  .footer {
    display: flex;
    padding-top: $spacing-06;
    margin-top: calc(#{$spacing-01} + #{$spacing-03});
    border-top: 1px solid $gray-300;
    justify-content: flex-end;
  }
}

.maxHeight {
  // 400px
  max-height: $spacing-22;
}

.selectModality {
  @include body-xs-regular;
  background-color: $white;
  border-radius: calc(#{$spacing-02} + #{$spacing-12});
  border: 1px solid $gray-300;
  color: $gray-700;
  margin-right: $spacing-03;
  appearance: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: $spacing-03 $spacing-05;
  height: calc(#{$spacing-02} + #{$spacing-08});
  box-sizing: border-box;

  &:nth-child(2) {
    @media (max-width: 500px) and (min-width: 480px) { //Edge case for viewport size (In-office) not wrapping
      width: calc($spacing-13 - $spacing-02);
      margin-right: $spacing-06 !important;
    }
  }

  .visits {
    @media (max-width: 580px) {
      display: none;
    }
  }

  @include screen('tablet-lg') {
    &:nth-child(2) {
      position: relative;
      margin-right: $spacing-05;
      &:after {
        content: '';
        border-right: 1px solid $gray-200;
        height: calc(#{$spacing-02} + #{$spacing-08});
        position: absolute;
        right: -$spacing-03;
      }
    }
  };

  @include screen('desktop-sm') {
    &:nth-child(2) {
      position: relative;
      margin-right: $spacing-08;

      &:after {
        content: '';
        border-right: 1px solid $gray-200;
        height: calc(#{$spacing-02} + #{$spacing-08});
        position: absolute;
        right: -$spacing-05;
      }
    }
  };

  @include screen('desktop-sm') {
    &:nth-child(2) {
      position: relative;
      margin-right: $spacing-08;

      &:after {
        content: '';
        border-right: 1px solid $gray-200;
        height: calc(#{$spacing-02} + #{$spacing-08});
        position: absolute;
        right: -$spacing-05;
      }
    }
  };

  .label {
    display: flex;
    cursor: pointer;
    user-select: none;
    margin: 0px;
    align-items: center;
  }

  .modalityCheckbox {
    width: $spacing-06;
    min-width: $spacing-06;
    height: $spacing-06;
    box-sizing: border-box;
    border: 1px solid $gray-300;
    border-radius: $spacing-02;
    margin-right: $spacing-02;
    cursor: pointer;
    position: relative;
    margin-top: 0px;

    @include screen('tablet-md') {
      margin-right: $spacing-03;
    }

    &:after {
      content: '';
      width: $spacing-03;
      height: $spacing-02;
      position: absolute;
      top: $spacing-02;
      left: $spacing-02;
      border: 3px solid $white;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
    }

    &:hover::after {
      opacity: 0.5;
    }

    &:hover::after {
      opacity: 0.5;
    }
  }

  input[type='checkbox'] {
    visibility: hidden;
    width: 0;
    margin-left: 0px;

    &:checked + label > div {
      background: $azure-700;
      border: 1px solid $azure-700;
    }

    &:checked + label > div:after {
      opacity: 1;
    }
  }
}

.modalitySpan {
  color: $gray-800;
  display: block;
  @include screen('tablet-md') {
    min-width: max-content;
  }
}

.selectedModality {
  background-color: $regal-0;
  border: 2px solid $azure-700;
  padding: $spacing-03 $spacing-05;
  height: calc(#{$spacing-02} + #{$spacing-08});
  box-sizing: border-box;

  input[type='checkbox'] {
    margin-left: 0px;
  }
}
