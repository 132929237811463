@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/colors.scss';

.container {
	width: 100%;
	background: $white;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	height: 75vh;
	display: flow-root;

	@include screen('tablet-lg'){
		width: 80%;
		padding: 0;
		height: fit-content;
		overflow: inherit;
	}


	.header {
		@include title-l-semibold;
		text-align: center;
		margin-bottom: $spacing-03;


		@include screen('tablet-lg'){
		text-align: left;
		}
	}

	.subHeader {
		@include body-s-regular;
		color: $gray-700;
		margin-bottom: $spacing-06;
		display: block;
		text-align: center;

		@include screen('tablet-lg'){
		text-align: left;
		}

	}

	.firstQuestion {
		display: flex;
		justify-content: space-between;
	}

	.questions {
		display: flex;
		margin-top: $spacing-06;

		.tooltip {
			cursor: pointer;

			@include screen('desktop-sm') {
				right: calc(#{$spacing-10} + #{$spacing-03});
			}
		}
	}

	.birthQuestion {
		margin-right: $spacing-03;
	}

	.button {
		position: fixed;
		padding: 1.5rem;
		bottom: 0;
		background: #ffffff;
		width: 100%;
		box-sizing: border-box;
		transition: 0.3s ease position;
		transition-delay: 2s;
		left: 0;
		right: 0;

		@include screen('tablet-md') {
			position: relative;
			padding: 0;
			margin-top: $spacing-10;
			margin-bottom: $spacing-11;
		}
	}
}

.scrollable {
	overflow-y: scroll;
	overflow-x: hidden;

	@include screen('tablet-md') {
		overflow-y: visible;
		overflow-x: visible;
	}
}
