@import "lifestance-ui/theme/spacing.scss";
@import "lifestance-ui/theme/colors.scss";

.dotsLoader {
  display: flex;
  justify-content: center;

  & > div {
    width: $spacing-03;
    height: $spacing-03;
    margin: 3px 6px;
    border-radius: 50%;
    background-color: $white;
    opacity: 1;
    animation: dots-loader 0.6s infinite alternate;
  }

  &.secondary {
    & > div {
      background-color: $gray-400;
    }
  }

  & > div:nth-child(2) {
    animation-delay: 0.2s;
  }

  & > div:nth-child(3) {
    animation-delay: 0.4s;
  }
}

@keyframes dots-loader {
  to {
    opacity: 0.5;
  }
}

