@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/colors.scss';

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: $modal-overlay;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;

    .modalContainer{
        position: fixed;
        background: #FFFFFF;
        box-shadow: 0px 0.25rem 0.75rem rgb(0 0 0 / 10%);
        box-sizing: border-box;
        padding-bottom: $spacing-07;
        bottom: 0px;
        left: 0;
        width: 100%;
        border-radius: 1.25rem 1.25rem 0 0;
        transition: bottom 0.2s ease;
        z-index: 9;
        touch-action: none;
        -webkit-overflow-scrolling: none;
        overflow: hidden;

        @include screen('tablet-sm'){
            position: relative;
            width: 538px;
            background-color: $white;
            border-radius: $spacing-03;
        }

        .topContainer{
            display: flex;
            box-sizing: border-box;
            align-items: center;
            padding-left: $spacing-07;
            border-bottom: 1px solid #e3e3e3;

            @include screen('tablet-sm'){
                padding: $spacing-07;
                padding-bottom: $spacing-05;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                box-sizing: border-box;
            }
            .header{
                @include body-s-regular;
                padding-top: $spacing-07;
                padding-bottom: $spacing-06;
                text-align: center;
                width: 85%;
            }
            .cursorPointer {
                cursor: pointer;
            }
        }
    }

    .coldLanding {
        max-height: unset;
        position: fixed;
        width: 100%;

        .buttonsContainer{
            margin-bottom: $spacing-07
        }

        @include screen('tablet-lg'){
            position: relative;
            width: 538px;
            background-color: $white;
            border-radius: $spacing-03;
        }
        
        .modalContent {
            @include screen('tablet-sm') {
                margin: auto;
                margin-top: $spacing-07;
            }

            @include screen('tablet-lg') {
                padding: 0 $spacing-11;
                box-sizing: border-box;
            }

            .dataContainer {
                padding-right: calc(20vw - $spacing-11);

                @include screen('tablet-lg') {
                    padding: unset;
                }
            }
            
            .divider {
                width: 88vw;
                margin: $spacing-06 auto;
                
                @include screen('tablet-lg') {
                    width: 100%;
                    height: 1px;
                    background-color: $gray-300;
                    margin: $spacing-06 $spacing-07 $spacing-06 0;
                }
            }
        }
    }

    .modalContent{
        margin-top: $spacing-07;
        padding-left: $spacing-07;
        padding-right: $spacing-07;

        @include screen('tablet-sm'){
            margin-top: 0;
            padding: 0 $spacing-11;
        }
        .close {
            margin-bottom: 30px;
            margin-right: -32px;
            display: flex;
            justify-content: flex-end;
            box-sizing: border-box;
            
            svg {
                cursor: pointer;
            }
        }

        @include screen('tablet-md') {
        padding: 0 $spacing-11;
        box-sizing: border-box;
        margin-top: $spacing-07;
        }

        .header{
            @include body-s-regular;
            text-align: center;

            @include screen('tablet-sm'){
                @include title-l-semibold;
                text-align: center;
                margin-bottom: $spacing-09;
            }
        }

        .dataContainer{
            display: grid;
            grid-template-columns: $spacing-07 1fr;
            column-gap: 15px;
            grid-template-areas: 'icon content';

            .leftContainer{
                grid-area: icon;
            }
            .rightContainer{
                grid-area: content;

                .line{
                    @include body-xs-regular;
                    color: $gray-700;
                }

                .link{
                    display: inline-block;
                    margin-top: $spacing-03;
                    color: #0067CE;
                    @include body-xs-medium;
                    cursor: pointer;
                }

                @include screen('tablet-sm') {
                    .line{
                        @include body-s-regular;
                    }

                    .link{
                        @include body-s-medium;
                    }
                }
            }

            .boldText{
                @include body-xs-medium;
                padding-bottom: $spacing-02;
                
                @include screen('tablet-sm'){
                    @include body-s-medium;
                }
            }


            .grayText {
                color: $gray-700;
            }

            .appointmentType {
                margin-bottom: 0;
            }

            .date{
                @include body-xs-regular; 
                color: $gray-700;
                
                @include screen('tablet-sm'){
                    @include body-s-regular;
                }
            }

        }

        .divider{
            width: 100%;
            height: 1px;
            background-color: $gray-300;
            margin: $spacing-06 $spacing-07 $spacing-06 0;
        }

        .buttonsContainer{
            margin-top: $spacing-07;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 0;

            @include screen('tablet-sm'){
                margin-bottom: $spacing-09;
                margin-top: $spacing-08;
            }
        }

        .buttonColdLanding {
            margin-bottom: 0px;

            @include screen('tablet-lg'){
            margin-bottom: $spacing-09
        }
        }

    }
}

.image{
    height: $spacing-07;
    width: $spacing-07;
    border-radius: calc(#{$spacing-02} + #{$spacing-12});
    object-fit: cover;
}
