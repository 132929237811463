@import 'intakeOptimization/theme/spacing.scss';
@import 'intakeOptimization/theme/typography.scss';
@import 'intakeOptimization/theme/colors.scss';

.buttonAvailability {
    @include body-xs-regular;
    box-sizing: border-box;
    display: flex;
    height: $spacing-09;
    align-items: center;
    border-radius: $spacing-02;
    justify-content: center;
    background-color: $regal-800;
    color:$white;
    margin-left: $spacing-03;
    padding: $spacing-03 calc(#{$spacing-03} + #{$spacing-01});
    flex-shrink: 0;

    &:hover, &:focus-visible {
        background-color: $regal-900;
        transform: scale(1.02);
	}

    &:focus-visible {
        outline: $spacing-01 solid $gray-800;
        outline-offset: $spacing-01;
    }

    .eyeIcon {
        filter: brightness(0) invert(1);
        margin-right: $spacing-04;
    }
}
