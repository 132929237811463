@import 'lifestance-ui/theme/colors.scss';
@import 'lifestance-ui/theme/spacing.scss';
@import 'lifestance-ui/theme/typography.scss';

.Alert {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: $spacing-07 $spacing-06;
  border-radius: $spacing-03;
  gap: $spacing-04;
  @include body-s-regular;

  &.critical {
    background-color: $sienna-0;
    flex-wrap: nowrap;
  }

  &.warning {
    background: $gold-0;
  }

  &.info {
    background-color: $regal-50;
    flex-wrap: nowrap;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.success {
    background-color: $casal-0;
  }
}