@import 'lifestance-ui/theme/typography';
@import 'lifestance-ui/theme/spacing';
@import 'lifestance-ui/theme/colors.scss';

.labelButton {
  @include body-s-medium;
  font-style: normal;
  color: $azure-700;

  &:hover {
    color: $azure-900 !important;
    svg {
      path {
        fill: $azure-900 !important;
      }
    }
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    margin-left: $spacing-06;
  }

  &.secondary {
    border: 1px solid $gray-400;
    padding: $spacing-02 $spacing-06 ;
    border-radius: 6px;
  }
}

.mouseOver {
  color: $azure-900 !important;
}

.pointer {
  cursor: pointer;
}

.underline {
  padding-bottom: 2px;
  text-decoration: none !important;
  border-bottom: 1.5px solid $azure-700;
  &:hover {
    border-bottom: 1.5px solid $azure-900;
  }
}

a {
  text-decoration: none !important;
}
