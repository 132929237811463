@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/colors.scss';

.container {
  width: 79%;
  background: $white;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-left: 10%;

  .header {
    @include title-xl-regular;
    color: $gray-800;
    text-align: center;
  }

  .step1 {
    display: flex;
    justify-content: center;
    height: 217px;
    margin-bottom: 20px;
  }

  .step2 {
    display: inline-block;
    justify-content: center;
    align-items: start;
    margin-top: 120px;
    height: 220px;
  }

  .hide {
    opacity: 0;
    max-height: 0px;
    transition: ease-out 1s;
  }

  .show {
    opacity: 1;
    transition: ease-out 1s;
  }

  .steps {
    display:flex;
    justify-content: center;
    box-sizing: border-box;
    height: auto;
    transform: scaleY(1);
    transform-origin: top;
    margin-left: 16px;
  }

  .subHeader {
    @include body-s-regular;
    color: $gray-700;
    margin-bottom: $spacing-06;
    margin-top: $spacing-06;
    display: block;
    text-align: center;
  }

  .firstQuestion {
    display: flex;
    justify-content: space-between;
  }

  .questions {
    display: flex;
    margin-top: $spacing-06;
  }

  .birthQuestion {
    margin-right: $spacing-03;
  }

  .existing {
    @include body-s-regular;
    background-color: $beige;
    border-radius: $spacing-03;
    padding: $spacing-07;
    width: 88%;
    margin-top: $spacing-05;
    display: flex;
    justify-content: center;
    margin-bottom: calc(#{$spacing-02} + #{$spacing-09});
  }

  .unsure {
    @include body-s-medium;
    color: $azure-700;
    margin-left: $spacing-02;
  }

  .optional {
    @include body-xxs-bold;
    display: flex;
    color: $gray-300;
    text-align: center;
    margin-top: $spacing-06;
    margin-bottom: $spacing-06;
  }

  .button {
    margin-top: $spacing-10;
    margin-bottom: $spacing-11;
  }

  .divider {
    margin-right: 0.25rem;
  }

  .or {
    margin-right: $spacing-06;
    padding-left: $spacing-06;
  }
  .previous {
    @include body-s-medium;
    color: $azure-700;
    margin-left: $spacing-02;
    text-decoration: none;
  }

  .link {
    text-decoration: none;
  }
}

