@import "lifestance-ui/theme/spacing.scss";
@import "lifestance-ui/theme/responsive.scss";
@import "lifestance-ui/theme/typography.scss";
@import "lifestance-ui/theme/colors.scss";

.pillbox {
    @include body-xs-regular;
    box-sizing: border-box;
    border-radius: calc(#{$spacing-02} + #{$spacing-12});
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: $spacing-02 $spacing-03;
    color: $gray-800;
    width: fit-content;

    .iconContainer {
        width: $spacing-04;
        height: $spacing-03;
        margin-right: calc(#{$spacing-01} + #{$spacing-02});
        margin-top: -2px;
        display: block;
        svg {
            width: $spacing-04;
            height: $spacing-03;
        }
    }



}

.main {
    background-color: $white;
    border: 1px solid $gray-200;
}

.secondary {
    background-color: $gray-50;
    border: 1px solid $gray-300;
}

.beige {
    background-color: $beige;
    padding-left: $spacing-04;
    padding-right: $spacing-04;

    .pillboxText {
        margin-left: $spacing-02;
        margin-right: $spacing-02;
        color: $gray-700;
    }

    svg {
        width: $spacing-06 !important;
        height: 14.5px !important;
        margin-top: $spacing-02;
        margin-right: $spacing-02;
    }    
}