@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/colors.scss';
@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/responsive.scss';

.overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(38, 38, 38, 0.43);
  animation: fadeIn 0.2s ease-in 0s 1 normal forwards;

  @include screen('tablet-lg') {
    padding: 0 $spacing-07;
    background-color: transparent;
    position: relative;
    flex-direction: row;
    justify-items: flex-end;
  }

  .container {
    width: 100%;
    animation: slideIn 0.4s ease-out 0s 1 normal forwards;

    @include screen('tablet-lg') {
      max-width: 326px;
      border-radius: $spacing-03;
      box-shadow: 0px $spacing-02 $spacing-04 rgba(0, 0, 0, 0.1);
      position: absolute;
      bottom: 124px;
      width: max-content;
      animation: slideInRight 0.4s ease-out 0s 1 normal forwards;
    }

    .header {
      padding: $spacing-07;
      background-color: $black;
      border-radius: $spacing-06 $spacing-06 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      @include screen('tablet-lg') {
        border-radius: $spacing-03 $spacing-03 0 0;
        justify-content: flex-start;
      }

      .icon {
        height: $spacing-07;
        width: $spacing-07;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: $spacing-07;
        left: $spacing-07;
        @include screen('tablet-lg') {
          display: none;
        }
      }

      .title {
        @include body-s-regular;
        color: $white;
      }
    }
  }

  .content {
    background-color: $white;
    padding: $spacing-07;

    @include screen('tablet-lg') {
      border-radius: $spacing-03;
    }

    .availability {
      @include body-s-regular;
      margin-bottom: $spacing-04;
    }
    .phoneContainer {
      display: flex;
      align-items: center;
      margin-bottom: $spacing-06;

      .photeCTA {
        @include body-s-regular;
        margin-left: $spacing-05;
        a {
          @include body-s-medium;
          text-decoration: none;
          color: $azure-700;
        }
      }
    }

    .divider {
      height: 1px;
      background-color: $gray-200;
      margin-bottom: $spacing-06;
    }

    .emailContainer {
      margin-bottom: $spacing-07;

      @include screen('tablet-lg') {
        margin-bottom: $spacing-03;
      }

      .mailCTA {
        @include body-s-regular;

        a {
          @include body-s-medium;
          text-decoration: none;
          color: $azure-700;
        }
      }
    }
  }
}

.overlayOut {
  animation: fadeOut 0.2s ease-in 0s 1 normal forwards;
  @include screen('tablet-lg') {
    animation: none;
  }

  .containerOut {
    animation: slideOut 0.4s ease-out 0s 1 normal forwards;

    @include screen('tablet-lg') {
      animation: slideOutRight 0.4s ease-out 0s 1 normal forwards;
    }
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes slideOut {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(100vh);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100vh);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes slideOutRight {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(100vh);
  }
}

@keyframes fadeOut {
  0% {
    background-color: 1;
  }
  100% {
    opacity: 0;
  }
}

.bold {
  @include body-s-medium;
  color: $gray-800;
}

.boldPhone {
  @include body-s-medium;
  white-space: nowrap;
  color: $azure-700;
  &:active {
    color: $azure-700;
  }
  @include screen('tablet-lg') {
    color: $gray-800;
  }
}

.suicidalIdeationText {
  @include body-s-regular;
  margin-top: $spacing-02;
}
