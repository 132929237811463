@import 'intakeOptimization/theme/typography';
@import 'intakeOptimization/theme/spacing';
@import 'intakeOptimization/theme/colors.scss';

.input {
  @include signature-m;
  padding: $spacing-06;
  width: 100%;
  height: 74px;
  background: $white;
  border: 1px solid $gray-200;
  box-sizing: border-box;
  border-radius: 4px;
  color: $gray-800;
  font-style: italic;
  text-align: center;

  &::placeholder {
    color: $gray-400;
  }

  &:focus {
    outline: none;
    border: 1px solid $gray-400;
  }

  &.error {
    border-color: $sienna-500;
  }
}

span.error {
  @include body-xs-regular;
  color: $sienna-600;
  margin-top: $spacing-03;
  display: block;
}
