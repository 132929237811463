@import 'intakeOptimization/theme/typography';
@import 'intakeOptimization/theme/spacing';
@import 'intakeOptimization/theme/colors.scss';

.container {
  position: relative;
  // Relates to DropdownTypeAhead/OptionsList
  z-index: 4;
}

.input {
  @include body-s-medium;
  padding: $spacing-05;
  width: 100%;
  height: calc(#{$spacing-02} + #{$spacing-10});
  background: $white;
  border: 1px solid $gray-300;
  box-sizing: border-box;
  border-radius: $spacing-03;
  color: $gray-800;
  font-style: normal;
  font-weight: normal;
  font-size: $spacing-05;
  line-height: $spacing-07;

  &::placeholder {
    color: $gray-400;
  }

  &:hover {
    border: 1px solid $gray-500;
  }

  &:focus-visible {
    border: 1px solid $gray-400;
  }

  &.tabbed:focus-visible {
    outline: $spacing-01 solid $gray-800;
    outline-offset: $spacing-01;
  }

  &.error {
    border-color: $sienna-500;
  }
}

.error {
  @include body-xs-regular;
  color: $sienna-600;
  margin-top: $spacing-03;
  display: block;
}

.clearButton {
  border: none;
  padding: 0;
  background: none;
  position: absolute;
  right: $spacing-05;
  top: $spacing-05;
  cursor: pointer;
}
