@import 'lifestance-ui/theme/typography';
@import 'lifestance-ui/theme/spacing';
@import 'lifestance-ui/theme/colors';

.button {
  @include body-s-medium;
  align-items: center;
  background: $azure-700;
  border-radius: $spacing-03;
  color: $white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  border: 0;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  transition: border-color .3s ease, background-color .3s ease, transform .3s ease, border-size .3s ease;

  &.secondary {
    background-color: $white;
    border: 1px solid $gray-400 !important;
    color: $gray-800;

    &:hover {
      background-color: $gray-50;
    }

    &:focus {
      background-color: $gray-50;
      transform: scale(0.98);
    }

    &:focus-visible {
      outline-offset: 2px;
      outline: 1px solid $gray-400;
    }

    &.loading {
      background-color: $gray-50;
    }
    &:disabled {
      background-color: $gray-200;
      color: $gray-500;
    }
  }

  &:not(.secondary).loading {
    background: $azure-900;
  }

  &:hover {
    background: $azure-900;
    transform: scale(1.02);
  }

  @media (hover: none) {
    &:hover { background: $azure-700; }
  }

  &:focus {
    background: $azure-900;
  }

  &:focus-visible {
    outline-offset: 2px;
    outline: 1px solid $gray-800;
  }

  &:active {
    transform: scale(0.98);
  }

  &.tertiary {
    background: transparent;
    padding: 0;
    color: $azure-700;

    &:focus {
      outline: 0;
    }
  }

  &.sm {
    padding: $spacing-02 $spacing-06;
  }

  &.md {
    padding: calc(#{$spacing-01} + #{$spacing-04}) $spacing-08;
    text-align: center;
    border: 0;
    cursor: pointer;
    box-sizing: border-box;
    height: calc(#{$spacing-02} + #{$spacing-10});

    @include screen('tablet-sm') {
      height: calc(#{$spacing-02} + #{$spacing-10});
    }
  }

  &.lg {
    padding: $spacing-04 $spacing-08;
  }

  &.xl {
    padding: $spacing-06 $spacing-08;
  }

  &.fullWidth {
    width: 100%;
  }

  &.narrow{
    height: $spacing-08;
    width: 100%
  }
}

.button:disabled {
  background-color: $gray-200;
  color: $gray-400;

  &:hover {
    transform: none;

  }
}

.bottomSection {
  margin-top: $spacing-11;
}
