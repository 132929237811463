@import "intakeOptimization/theme/spacing.scss";
@import "intakeOptimization/theme/responsive.scss";
@import "intakeOptimization/theme/typography.scss";
@import "intakeOptimization/theme/colors.scss";

.container {
  width: 100%;
  position: relative;

  &:focus, &:active {
    outline: none !important;

    & > .dropdown {
      border-color: $gray-500;
    }
  }
}


.highlighted {
  @include body-regular-bold;
  color: $azure-700;
}

.errorMessage {
  @include body-xs-regular;
  color: $sienna-600;
  margin-top: $spacing-03;
  display: block;
}