@import "lifestance-ui/theme/spacing.scss";
@import "lifestance-ui/theme/responsive.scss";
@import "lifestance-ui/theme/typography.scss";
@import "lifestance-ui/theme/colors.scss";

.dropdown {
  border: 1px solid $gray-300;
  border-radius: $spacing-03;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $gray-400;
  user-select: none;
  justify-content: space-between;
  box-sizing: border-box;
  padding: calc(#{$spacing-01} + #{$spacing-04}) $spacing-06;
  height: calc(#{$spacing-02} + #{$spacing-10});
  box-sizing: border-box;
  @include body-s-regular;

  &:focus {
    border: $spacing-01 solid $gray-500;
  }

  &.error {
    border-color: $sienna-600;
  }

  &::placeholder {
    color: $gray-400;
  }

  svg {
    margin-left: $spacing-03;
  }

  .options {
    border-radius: $spacing-03;
    color: $gray-800;
  }

  &.disabled {
    background-color: $gray-100;
    cursor: not-allowed;
  }

  &.selected {
    color: $gray-800;
  }

  .value {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(#{$spacing-06} + #{$spacing-19})
  }
}

.tabbed {
  outline: $spacing-01 solid $gray-800;
  outline-offset: $spacing-01;
  border-radius: $spacing-02;
}

.placeholder {
  color: #acacac;
  font-weight: 400;
}

.defaultOption {
  color: $gray-400;
}

.container {
  width: 100%;
  position: relative;

  &:focus, &:active {
    outline: none;

    & > .dropdown {
      border-color: $gray-500;
    }
  }

  &.disabled {
    pointer-events: none;
  }
}

.optionsContainer {
  display: none;
  margin-top: $spacing-03 !important;

  @include screen("tablet-md") {
    background: $white;
    box-shadow: 0px $spacing-03 $spacing-07 rgb(0 0 0 / 12%);
    border-radius: $spacing-03;
    display: inline-block;
    position: absolute;
    padding: 0;
    flex-direction: column;
    margin-top: $spacing-04;
    width: 100%;
    box-sizing: border-box;
    z-index: 10;
    max-height: calc(#{$spacing-06} + #{$spacing-19});
    overflow-y: auto;
    visibility: visible;
    transform: translateY(0);
    border: 1px solid #e3e3e3;

    &.small {
      max-height: $spacing-17;
    }

    &.errorContainer {
      top: 50px;
    }

    li {
      @include body-s-regular;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      cursor: pointer;
      color: $gray-800;
      padding: $spacing-03 $spacing-06;

      .title {
        @include body-s-medium;
        margin-bottom: $spacing-02;
      }

      .description {
        padding-bottom: $spacing-05;
        position: relative;

        b {
          font-weight: 500;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          width: 100%;
          left: 0;
          border-bottom: 1px solid $gray-200;
        }
      }

      &:hover,
      &:active,
      &.focused,
      &:focus {
        background-color: $regal-100;
        outline: none;
      }
    }

    .option {
      &.selfPay {
        padding: $spacing-05 $spacing-06;
        margin-bottom: $spacing-03;
        position: relative;
        margin-top: -$spacing-03;

        &:after {
          content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
            border-bottom: 1px solid $gray-200;
        }
      }
      &.disabled {
        color: $gray-400;
        cursor: not-allowed;
        &:hover {
            background-color: $white;
        }
      }
    }
  }

  @include screen("tablet-lg") {
    background: $white;
    box-shadow: 0px $spacing-03 $spacing-07 rgb(0 0 0 / 9%);
    border-radius: $spacing-03;
    display: inline-block;
    position: absolute !important;
    flex-direction: column;
    margin-top: $spacing-04;
    width: 100%;
    box-sizing: border-box;
    z-index: 10;
    max-height: calc(#{$spacing-04} + #{$spacing-22});
    visibility: visible;
    transform: translateY(0);
    overflow-y: auto;
    // Padding for WEB-3184
    padding-top: $spacing-06;
    padding-bottom: $spacing-08;

    li {
      @include body-s-regular;
      display: flex;
      cursor: pointer;
      color: $gray-800;
      // Padding for WEB-3184
      padding: $spacing-04 $spacing-06;

      &:first-child {
        padding: 0 $spacing-06 $spacing-03 $spacing-06;
      }

      &:hover,
      &:active,
      &:focus {
        background-color: $regal-100;
      }

      &.withDescription {
        padding: $spacing-05 $spacing-06 0;

        &:first-child {
          padding: 0 $spacing-06;
        }
      }
    }

    .separator {
      background: $gray-200;
      height: 1px;
      margin-right: 20px;
      margin-left: 20px;
    }
  }

  & li:last-of-type .description::after {
    display: none;
  }
}

.errorMessage {
  @include body-xs-regular;
  color: $sienna-600;
  margin-top: $spacing-03;
  display: block;
}
