@import 'intakeOptimization/theme/typography.scss';
@import 'intakeOptimization/theme/spacing.scss';
@import 'intakeOptimization/theme/colors.scss';

.existContainer {
  margin: 0px;
  margin-bottom: $spacing-08;

  @include screen('tablet-lg') {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .existing {
    box-sizing: border-box;
    border: 1px solid $gray-200;
    border-radius: $spacing-03;
    cursor: pointer;

    @include screen('tablet-lg') {
      cursor: initial;
      width: $spacing-22;
      height: $spacing-18;

      &.expanded {
        height: 208px;
      }

      &.mobile {
        display: none;
      }
    }
  }

  .title {
    @include body-xs-medium; 

    @include screen('tablet-lg'){
      @include title-l-semibold;
    }
  }

  .options {
    display: flex;
    justify-content: space-between;
    margin-left: $spacing-07;
    margin-right: $spacing-07;
    padding: $spacing-06 0px;

    &:nth-child(1) {
      border-bottom: 1px solid #d1d1d1;
    }
  }

  .visit {
    @include body-xs-regular;

    @include screen('tablet-sm'){
    @include body-s-regular;
    }
  }

  .visitText {
    @include body-xs-regular;
    display: block;
    text-align: left;
    max-width: 284px;

    @include screen('tablet-sm'){
      @include body-s-regular;
      max-width: none;
      }
  }

  .phone {
    @include body-s-medium;
    color: $gray-800;
    text-decoration: none;
    cursor: pointer;
  }

  .missingLink {
    display: flex;
    flex-wrap: wrap;
    margin-left: $spacing-07;
    
    @include screen('tablet-sm'){
      display: block;
      flex-wrap: none;
      margin-right: $spacing-07;
    }
  }

  .missingVideoLink {
    @include body-xs-medium;
    margin-top:  $spacing-06;
    margin-bottom: $spacing-02;
    text-align: left;

    @include screen('tablet-sm'){
      @include body-s-medium;
    }
  }

  .join {
    @include body-s-medium;
    color: $azure-700;
    text-decoration: none;
  }

  .portal {
    @include body-s-regular;

    a {
      color: $azure-700;
      text-decoration: none;
    }

  }

  span {
    @include body-xs-medium;

    @include screen('tablet-sm'){
      @include body-s-medium;
    }
  }

  .questions {
    @include body-s-regular;
  }

  .concerns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: $spacing-06 $spacing-07;
    background: $gray-50;
    box-sizing: border-box;
    margin-top: $spacing-06;

    @include screen('tablet-lg') {
      width: 400px;
    }
  }

  .check {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: $spacing-07;

    @include screen('tablet-lg') {
      display: inline-block;
      max-width: 281px;
      margin: $spacing-07 $spacing-11;
      text-align: center;
    }
  }

  .scheduled {
    width: $spacing-22;
    margin: 0px -15px;

    .calendarcheckline {
      padding-left: $spacing-07;
    }
  }
}

.titleBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-06 $spacing-07;


  @include screen('tablet-lg') {
    display: block;
    padding: $spacing-08 $spacing-07 0;

    svg {
      display: none;
    }
  }
}

.optionsBox {
  height: 0;
  overflow: hidden;
  transition: all .23s ease;

  &.visible {
    height: calc(#{$spacing-13} + #{$spacing-05});
  }

  @include screen('tablet-lg') {
    height: $spacing-14;
  }

  &.expandedVisible {
    height: calc(#{$spacing-14} + #{$spacing-06});
  }
}
