@import "patientOnboarding/theme/typography.scss";
@import "patientOnboarding/theme/spacing.scss";
@import "patientOnboarding/theme/colors.scss";
@import "patientOnboarding/theme/responsive.scss";

.container {
  flex-wrap: wrap;
  align-content: center;
  max-width: $spacing-22;
  margin: auto;
  margin-top: 5%;

  svg{
    display: block;
    margin: auto;
    padding-bottom: $spacing-08;

  }
  .content {
    align-content: center;
  }

  @include screen("tablet-lg") {
    align-content: center;
  }

  .title {
    @include title-xl-regular;
    text-align: center;
    padding-bottom: $spacing-03;
  }
  .heading {
    @include body-s-regular;
    padding: 0 0px $spacing-10 0px;

    text-align: center;
    width: 87%;
    margin: auto;
    @include screen("tablet-sm") {
      width: 100%;
    }
  }

  .infoContainer{
      box-sizing: border-box;
      margin: 0 $spacing-07;
      border-radius: $spacing-03;
      background-color: $gray-50;
      padding: $spacing-09 $spacing-07;
      @include screen("tablet-sm") {
          margin: 0;
    }
  }

  .bullet{
      margin-bottom: $spacing-06;
      width: 100%;
      display: grid;
      grid-template-rows: auto auto;
      grid-template-columns: $spacing-07 auto;
      column-gap: $spacing-04;
      @include screen("tablet-sm") {
          column-gap: $spacing-06;
      }

      svg{
          align-self: center;
      }
  }


  .header{
      @include body-s-medium;
  }

  .text{
      @include body-s-regular;
      margin-top: $spacing-02;
      grid-column-start: 2;
      grid-column-end: 3;

      a{
          font-weight: unset;
          text-decoration: unset;
          color: $azure-700;
          @include screen("tablet-sm") {
            color: $black;
      }
      }

      .bold{
          @include body-s-medium;
          cursor: pointer;
      }
  }

  .button{
      margin: 0 $spacing-07;
      width: -webkit-fill-available;
      @include screen("tablet-sm") {
          margin: 0;
    }
  }

  .bookingNumberContainer{
      margin: $spacing-07 $spacing-07 0 $spacing-07;
      padding: $spacing-07 $spacing-06;
      background-color: $regal-50;
      border-radius: $spacing-03;
      display: grid;
      grid-template-columns: $spacing-07 auto;
      column-gap: $spacing-04;
      @include screen("tablet-sm") {
          margin: 0;
          margin-top: $spacing-07;
    }

    svg{
        margin-top: 6px;
        width: $spacing-07;
        height: $spacing-07;
    }
  }
}