@import 'patientOnboarding/theme/colors.scss';
@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/typography.scss';

.tooltip {
    position: absolute;
    top: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
    background-color: $gray-800;
    color: $white;
    padding: $spacing-02;
    border-radius: $spacing-01;
    @include body-xxs-medium;
    text-transform: uppercase;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    transition-delay: 600ms;
    z-index: 1;
    width: 75px;
    height: 20px;
    border-radius: 4px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-width: 4px;
      border-style: solid;
      border-color: transparent transparent $gray-800 transparent;
    }
  
    &.show {
      opacity: 1;
      visibility: visible;
    }
  }