@import "lifestance-ui/theme/spacing.scss";
@import "lifestance-ui/theme/responsive.scss";
@import "lifestance-ui/theme/colors.scss";

.ABIEcard {
    background: $white;
    border-bottom: 1px solid $gray-200;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $spacing-08 0;
    cursor: pointer;

    @include screen("tablet-md") {
      flex-direction: row;
      padding: $spacing-10 0;
    }

}

.card {
  background: $white;
  border-bottom: 1px solid $gray-200;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $spacing-08 0;
  cursor: pointer;

  // last element in container with class .card
  &:not(:has(~ .card)) {
    border-bottom: none;
  }

  @include screen("tablet-md") {
    flex-direction: row;
    padding: $spacing-10 0;
  }
}

.cardAdmin {
  background: $white;
  border: 1px solid $gray-200;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $spacing-07;
  padding-bottom: $spacing-05;
  cursor: pointer;
  border-radius: $spacing-03;
  margin-bottom: $spacing-07;
  height: calc(#{$spacing-15} + #{$spacing-02});
  transition: all .25s ease-in-out;

  @include screen("tablet-md") {
    flex-direction: row;
    padding: $spacing-07;
  }

  &:focus-within, &:hover {
    box-shadow: 0 $spacing-02 $spacing-04 0 rgba(0, 0, 0, 0.18);
    transform: scale(1.02);
  }
}

.topSearch {
  background: $white;
  border-bottom: 1px solid $gray-200;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: $spacing-08;
  padding-bottom: $spacing-06;
  cursor: pointer;

  @include screen("tablet-md") {
    flex-direction: row;
  }
}
