@import 'patientOnboarding/theme/spacing.scss';
@import 'patientOnboarding/theme/typography.scss';
@import 'patientOnboarding/theme/colors.scss';

.container {
  width: 100%;
  background: $white;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  height: 75vh;

  display: flow-root;

  @include screen('tablet-lg'){
    width: 80%;
    padding: 0;
    height: fit-content;
    overflow: inherit;
  }


  .header {
    @include title-l-semibold;
    text-align: center;
    margin-bottom: $spacing-03;


    @include screen('tablet-lg'){
      text-align: left;
    }
  }

  .subHeader {
    @include body-s-regular;
    color: $gray-700;
    margin-bottom: $spacing-06;
    display: block;
    text-align: center;

    @include screen('tablet-lg'){
      text-align: left;
    }

  }

  .firstQuestion {
    display: flex;
    justify-content: space-between;
  }

  .questions {
    display: flex;
    margin-top: $spacing-06;

    .tooltip {
      cursor: pointer;

      @include screen('desktop-sm') {
        right: calc(#{$spacing-10} + #{$spacing-03});
      }
    }
  }

  .dropdown {
    max-height: calc(#{$spacing-06} + #{$spacing-15});
  }

  .birthQuestion {
    margin-right: $spacing-03;
  }

  .existing {
    @include body-s-regular;
    background-color: $beige;
    border-radius: $spacing-03;
    padding: $spacing-07;
    width: 88%;
    margin-top: $spacing-05;
    display: flex;
    justify-content: center;
    margin-bottom: calc(#{$spacing-02} + #{$spacing-09});
  }

  .unsure {
    @include body-s-medium;
    color: $azure-700;
    margin-left: $spacing-02;
  }

  .optional {
    @include body-xxs-bold;
    display: flex;
    color: $gray-300;
    text-align: center;
    margin-top: $spacing-06;
    margin-bottom: $spacing-06;
  }

  .button {
    position: fixed;
    padding: 1.5rem;
    bottom: 0;
    background: #ffffff;
    width: 100%;
    box-sizing: border-box;
    transition: 0.3s ease position;
    transition-delay: 2s;
    left: 0;
    right: 0;

    @include screen('tablet-md') {
      position: relative;
      padding: 0;
      margin-top: $spacing-10;
      margin-bottom: $spacing-11;
    }
  }

  .divider {
    margin-right: 0.25rem;
  }

  .or {
    margin-right: $spacing-06;
    padding-left: $spacing-06;
  }
  .previous {
    @include body-s-medium;
    color: $azure-700;
    margin-left: $spacing-02;
    text-decoration: none;
  }

  .link {
    text-decoration: none;
  }
}

.scrollable {
  overflow-y: scroll;
  overflow-x: hidden;

  @include screen('tablet-md') {
    overflow-y: visible;
    overflow-x: visible;
  }
}
